import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import withSideNav from "../../HOC/withSideNav";
import { PlanSettingsViewProps } from "../../models/page-props";
import {
    cancelSubscription,
    clearSubscriptionData,
    endFreeTrialSubscription,
    fetchSubscriptionData,
    handleUpgradeSubscription,
} from "../../store/actions/subscription-settings-actions";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { actionType, cartSelectBasin, USDollar } from "../../../utils/helper";
import moment from "moment";
import { toast } from "react-toastify";
import DeleteConfirmationModal from "../../common/Modal/DeleteConfirmationModal";
import NoDataFound from "../../common/NoDataFound";
import Scrollbars from "react-custom-scrollbars";
import EditSubModal from "./EditSubModal";
import { handleMonthlyToYearlyUpgradeMsgModal, hideCheckOutModal, showCheckOutModal, toggleEditSubscriptionModal } from "../../store/actions/modal-actions";
import { calculateTax } from "../../store/actions/cart-select-basin-county-actions";
import { MapComponent, MapType } from "../../common/MapComponent";
import { basinsUpdated, countiesUpdated, setSelectedType } from "../../map/redux/locations";
import { BASINS, COUNTIES } from "../../map/redux/types";
import MonthlyToYearlyUpgradeMsgModal from "../../common/Modal/MonthlyToYearlyUpgradeMsgModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentModal from "../../common/Modal/PaymentModal";
import { logUserAction } from "../../store/actions/auth-actions";
import { Tooltip } from "react-tooltip";

const stripePromise = loadStripe(
    `${import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY}`
);
const PlanSettingsView = (props: PlanSettingsViewProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [state, setState] = useState({
        cancelSubscriptionModal: false,
    });
    const { cancelSubscriptionModal } = state;

    const selectedType = useAppSelector(state => state.locations.selectedType);
    const invoiceLineItems = useAppSelector(state => state.cartSelectBasinCounty.invoiceLineItems);
    const yearly = useAppSelector(state => state.cartSelectBasinCounty.yearly)
    const go_nation = useAppSelector(state => state.subscriptionSettings.go_nation)
    const loadSubscriptionData = useAppSelector(state => state.subscriptionSettings.loadSubscriptionData)
    const subscriptionData = useAppSelector(state => state.subscriptionSettings.subscriptionData)
    const editSubscriptionModal = useAppSelector(state => state.modal.editSubscriptionModal)
    const checkOutModal = useAppSelector(state => state.modal.checkOutModal)
    const company_id = useAppSelector(state => state.auth.user.company_data.company_id)
    const trial_remaining_days = useAppSelector(state => state.auth.user.trial_remaining_days)
    const company_configs = useAppSelector(state => state.auth.user.company_configs)
    const user_id = useAppSelector(state => state.auth.user.user_id)
    const sync_with_stripe = useAppSelector(state => state.auth.user.company_configs.sync_with_stripe)
    const go_national_plan = subscriptionData && (subscriptionData['details'] || []).find((item) => item.line_item_type === 3) ? true : false

    useEffect(() => {
        if (!sync_with_stripe) {
            toast.info("This feature is not available for your account. Please contact support for assistance.");
            navigate("/")
            return
        }
        subscriptionData !== null && dispatch(clearSubscriptionData());
        return () => {
            dispatch(clearSubscriptionData());
        };
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (loadSubscriptionData) {
            dispatch(fetchSubscriptionData());
        }
        // eslint-disable-next-line
    }, [loadSubscriptionData]);

    useEffect(() => {
        if (selectedType === BASINS) {
            dispatch(basinsUpdated([]))
        } else {
            dispatch(countiesUpdated([]))
        }
        // eslint-disable-next-line
    }, [selectedType]);

    const currentDate = moment().toISOString();
    const isDateCrossed = subscriptionData?.subscription?.renewal_date ? moment(subscriptionData?.subscription?.renewal_date).isBefore(currentDate) : false

    return (
        <div className="settingsWrapper">
            <Scrollbars
                className="settingsWrapper-scroll"
                autoHeightMin={0}
                renderThumbVertical={(props) => (
                    <div {...props} className="thumb-vertical" />
                )}
                renderTrackVertical={(props) => (
                    <div {...props} className="track-vertical" />
                )}
            >
                <div className="settingWrapperInner">
                    {subscriptionData === null ? (
                        <NoDataFound
                            ImageSrc="images/no-subscription.svg"
                            headingLabel="No Subscriptions Found"
                            description=""
                            onBtnClick={() => navigate("/cart-select-basin")}
                            btnLabel="Subscribe Now"
                        />
                    ) : (
                        <>
                            <div className="subscription-list">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="box">
                                            <div className="icon">
                                                <i className="fa-solid fa-user-group"></i>
                                            </div>
                                            <div className="detail">
                                                <span>Seats</span>
                                                {subscriptionData
                                                    ? `${subscriptionData.subscription.total_user}/${subscriptionData.subscription.allowed_user}`
                                                    : `0/0`}
                                            </div>
                                            <button
                                                className={`${subscriptionData.subscription.is_canceled || (company_configs?.free_trial_period_enabled && !company_configs?.trial_expired) || company_configs.is_trial_never_end ? "btn btn-disabled" : "btn btn-primary"}`}
                                                data-tooltip-id="alert-add-seats"
                                                data-tooltip-html={subscriptionData?.subscription?.is_canceled ? `<div><span>Please reach out to datasales@energydomain.com so we can customize these changes to your subscription. </span></div>` : ''}
                                                data-tooltip-place="bottom"
                                                data-tooltip-variant="dark"
                                                onClick={() => {
                                                    if (subscriptionData.subscription.is_canceled) {
                                                        return;
                                                    }
                                                    if ((company_configs?.free_trial_period_enabled && !company_configs?.trial_expired) || company_configs.is_trial_never_end) {
                                                        toast.info('Seats cannot be added in trial mode. Please upgrade your subscription to add seats.', { toastId: "trial_period" })
                                                        return;
                                                    }
                                                    if (company_id === null) {
                                                        toast.info(
                                                            `Please add Company details before request more seats.`
                                                        );
                                                        return;
                                                    }
                                                    dispatch(
                                                        logUserAction({
                                                            action_type: actionType["click_on_add_seats"],
                                                            action_log_detail: `user_id: ${user_id}`,
                                                        })
                                                    );
                                                    dispatch(showCheckOutModal());
                                                }}>
                                                Add Seats
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="box">
                                            <div className="icon">
                                                <img src="images/counties.svg" alt="" />
                                            </div>
                                            <div className="detail">
                                                <span>Counties/Basins</span>
                                                {go_national_plan
                                                    ? "All"
                                                    : subscriptionData
                                                        ? `${subscriptionData.details.filter(
                                                            (item) =>
                                                                item.line_item_type ===
                                                                2
                                                        ).length
                                                        }/${subscriptionData.details.filter(
                                                            (item) =>
                                                                item.line_item_type ===
                                                                1
                                                        ).length
                                                        }`
                                                        : 0}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="box">
                                            <div className="icon">
                                                <img src="images/cost.svg" alt="" />
                                            </div>
                                            <div className="detail">
                                                <span>Subscription Cost</span>
                                                {/* {subscriptionData */}
                                                {company_configs[
                                                    "free_trial_period_enabled"
                                                ] &&
                                                    !company_configs["trial_expired"]
                                                    ? `${company_configs["no_of_free_days_allowed"]} Day Trial`
                                                    : `${USDollar.format(
                                                        Number(subscriptionData?.subscription?.subscription_amount)
                                                    )} ${subscriptionData[
                                                        "subscription"
                                                    ][
                                                        "billing_frequency_display"
                                                    ] === "Monthly" ? '/ mo' : '/ yr'}`}
                                                {/* : USDollar.format(0)} */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-md-3 ${'subscription' in subscriptionData && subscriptionData['subscription']['is_canceled'] ? "cancelled" : ""}`}>
                                        <div className="box">
                                            <div className="icon">
                                                <img src="images/date.svg" alt="" />
                                            </div>
                                            <div className="detail">
                                                <span className={subscriptionData?.subscription
                                                    ?.is_canceled && !isDateCrossed ? "redClr" : ''}>
                                                    {subscriptionData.subscription
                                                        .is_canceled
                                                        ? isDateCrossed ? "Expiry Date" : "Cancelled, Active Until"
                                                        : "Renewal Date"}
                                                </span>
                                                {company_configs[
                                                    "free_trial_period_enabled"
                                                ] &&
                                                    !company_configs["trial_expired"] && `${trial_remaining_days}` !== "Never"
                                                    ? `${trial_remaining_days} ${Number(trial_remaining_days) === 1 ? "Day" : "Days"} Left`
                                                    : `${trial_remaining_days}` ===
                                                        "Never"
                                                        ? "Continuous"
                                                        : subscriptionData
                                                            ? moment(
                                                                subscriptionData
                                                                    .subscription.is_canceled ? subscriptionData
                                                                        .subscription
                                                                    .date_end : subscriptionData
                                                                        .subscription
                                                                    .renewal_date
                                                            ).format("MMM-DD-YYYY")
                                                            : "NA"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="plan-map-con">
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="map-con">
                                            <MapComponent
                                                mapType={MapType.Cart}
                                                zoomType="hidden"
                                                disableNavigation
                                                disablePopup
                                                defaultSelectedLayerType={
                                                    COUNTIES
                                                }
                                                customExtent={
                                                    {
                                                        xmax: -7700832.284788379,
                                                        xmin: -14189672.050290292,
                                                        ymax: 6369760.687508591,
                                                        ymin: 2774977.6391927814,
                                                        spatialReference: {
                                                            wkid: 102100,
                                                        },
                                                    } as __esri.Extent
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="basin-sidebar">
                                            <div className="alert-msg"
                                                data-tooltip-id="alert-canceled-subscription"
                                                data-tooltip-html={subscriptionData.subscription.is_canceled ? `<div><span>Please reach out to datasales@energydomain.com so we can customize these changes to your subscription. </span></div>` : ''}
                                                data-tooltip-place={"bottom"}
                                                data-tooltip-variant="dark">

                                                <button
                                                    type="button"
                                                    // className={`btn  width100 mb-3 ${
                                                    //     subscriptionData
                                                    //         ?.subscription
                                                    //         ?.is_canceled
                                                    //         ? "btn-disabled"
                                                    //         : "btn-primary"
                                                    // }`}
                                                    disabled={subscriptionData.subscription.is_canceled}
                                                    className={`btn  width100 mb-3 btn-primary ${(go_national_plan || (!subscriptionData['subscription']['is_upgrade_subscription'] && subscriptionData['subscription']['is_trial_never_end']))
                                                        ? "d-none"
                                                        : ""
                                                        }`}
                                                    onClick={() => {
                                                        if (subscriptionData.subscription.is_canceled) {
                                                            return;
                                                        }
                                                        if (
                                                            company_configs[
                                                            "free_trial_period_enabled"
                                                            ] &&
                                                            !company_configs[
                                                            "trial_expired"
                                                            ]
                                                        ) {
                                                            dispatch(
                                                                endFreeTrialSubscription()
                                                            ).then((res) => {
                                                                const { status } =
                                                                    res;
                                                                if (
                                                                    status === 200
                                                                ) {
                                                                    dispatch(
                                                                        handleUpgradeSubscription(
                                                                            true
                                                                        )
                                                                    );
                                                                    navigate(
                                                                        cartSelectBasin
                                                                    );
                                                                }
                                                            });
                                                            return;
                                                        }
                                                        dispatch(
                                                            logUserAction({
                                                                action_type: actionType["click_on_upgrade_subscription"],
                                                                action_log_detail: `user_id: ${user_id}`,
                                                            })
                                                        );
                                                        // !subscriptionData
                                                        //     ?.subscription
                                                        //     ?.is_canceled &&
                                                        navigate(
                                                            "/cart-select-basin"
                                                            // {
                                                            //     state: {
                                                            //         sub_id: subscriptionData
                                                            //             ?.subscription
                                                            //             .id,
                                                            //     },
                                                            // }
                                                        );
                                                        // navigate(
                                                        //     "/cart-select-basin"
                                                        // );
                                                    }}
                                                >
                                                    <img
                                                        src="images/cart-icon.svg"
                                                        alt=""
                                                    />{" "}
                                                    Upgrade Subscription

                                                </button>
                                            </div>
                                            <div className={`monthly-plan ${subscriptionData?.subscription?.is_canceled ? 'disabled-link' : ''}`}>
                                                <h2>
                                                    {company_configs?.free_trial_period_enabled &&
                                                        !company_configs?.trial_expired
                                                        ? "Free Trial"
                                                        : subscriptionData[
                                                            "subscription"
                                                        ][
                                                            "billing_frequency_display"
                                                        ] === "Monthly"
                                                            ? "Monthly"
                                                            : "Annual"}{" "}
                                                    Subscription
                                                    {subscriptionData &&
                                                        subscriptionData[
                                                        "subscription"
                                                        ][
                                                        "billing_frequency_display"
                                                        ] === "Monthly" ? (
                                                        <>
                                                            <br />
                                                            <a
                                                                href="void:(0)"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    if (subscriptionData.subscription.is_canceled) {
                                                                        return;
                                                                    }
                                                                    dispatch(
                                                                        handleMonthlyToYearlyUpgradeMsgModal(
                                                                            true
                                                                        )
                                                                    );
                                                                }}
                                                                data-tooltip-id="alert-canceled-subscription-link"
                                                                data-tooltip-html={subscriptionData.subscription.is_canceled ? `<div><span>Please reach out to datasales@energydomain.com so we can customize these changes to your subscription. </span></div>` : ''}
                                                                data-tooltip-place={"bottom"}
                                                                data-tooltip-variant="dark"
                                                            >
                                                                Upgrade to
                                                                Yearly
                                                                Subscription
                                                            </a>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </h2>
                                                <ul className={'planCart scrollSection'}>
                                                    {subscriptionData &&
                                                        [
                                                            ...subscriptionData.details.filter(
                                                                (item) =>
                                                                    !item.unsubscribe_status
                                                            ),
                                                            ...subscriptionData.details.filter(
                                                                (item) =>
                                                                    item.unsubscribe_status
                                                            )
                                                        ].map((item, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <div className="small-map">
                                                                        <img
                                                                            src={
                                                                                item.line_item_type === 3 ? `${import.meta.env.VITE_ED_DATA_CDN_API}/company_logo/${go_nation?.file_name}` : item.image_data
                                                                            }
                                                                            alt=""
                                                                            className="make-svg-white"
                                                                        />
                                                                    </div>
                                                                    <div className="description">
                                                                        <h4>
                                                                            {
                                                                                item.line_item_name
                                                                            }{" "}
                                                                            {item.line_item_type ===
                                                                                2
                                                                                ? "County"
                                                                                : ""}
                                                                        </h4>
                                                                        <p
                                                                            style={{
                                                                                color: item.unsubscribe_status
                                                                                    ? "#D94141"
                                                                                    : "#48637B"
                                                                            }}
                                                                        >
                                                                            {item.unsubscribe_status
                                                                                ? `Cancelled Active Untill: ${moment(
                                                                                    item.end_period
                                                                                )
                                                                                    // .subtract(
                                                                                    //     1,
                                                                                    //     "days"
                                                                                    // )
                                                                                    .format(
                                                                                        "MMM-DD-YYYY"
                                                                                    )}`
                                                                                : `Current ${subscriptionData[
                                                                                    "subscription"
                                                                                ][
                                                                                    "billing_frequency_display"
                                                                                ] ===
                                                                                    "Monthly"
                                                                                    ? "monthly"
                                                                                    : "annual"
                                                                                } subscription`}
                                                                        </p>
                                                                        <p>
                                                                            <span
                                                                                className={
                                                                                    item.line_item_type ===
                                                                                        1
                                                                                        ? ""
                                                                                        : "d-none"
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.total_counties
                                                                                }{" "}
                                                                                Counties
                                                                            </span>
                                                                            {USDollar.format(
                                                                                Number(
                                                                                    item.total_cost
                                                                                )
                                                                            )}{" "}
                                                                            /{" "}
                                                                            {`${subscriptionData[
                                                                                "subscription"
                                                                            ][
                                                                                "billing_frequency_display"
                                                                            ] ===
                                                                                "Monthly"
                                                                                ? "month"
                                                                                : "year"
                                                                                }`}
                                                                            -
                                                                            Billed{" "}
                                                                            {`${subscriptionData[
                                                                                "subscription"
                                                                            ][
                                                                                "billing_frequency_display"
                                                                            ] ===
                                                                                "Monthly"
                                                                                ? "monthly"
                                                                                : "yearly"
                                                                                }`}
                                                                        </p>
                                                                        {/* {item.unsubscribe_status && !subscriptionData?.subscription?.is_canceled ? <span className="status">
                                                                            Reactivate
                                                                        </span> : <></>} */}
                                                                    </div>
                                                                    <div className="price">
                                                                        {USDollar.format(
                                                                            Number(
                                                                                item.total_cost
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </li>
                                                            );
                                                        })}
                                                </ul>
                                                <div className="buttonblock">
                                                    {subscriptionData?.subscription
                                                        .is_canceled ? <></>
                                                        :
                                                        <div
                                                            className="editsubs"
                                                            onClick={() => {
                                                                if (
                                                                    subscriptionData &&
                                                                    subscriptionData?.details.filter(
                                                                        (item) =>
                                                                            !item.unsubscribe_status
                                                                    ).length > 0
                                                                ) {
                                                                    dispatch(
                                                                        calculateTax(
                                                                            {
                                                                                line_items: invoiceLineItems?.map(({ amount, quantity, tax_code }) => ({ amount, quantity, tax_code })) ?? [],
                                                                                billing_interval: yearly ? 'Y' : 'M'
                                                                            }
                                                                        )
                                                                    );
                                                                    dispatch(
                                                                        toggleEditSubscriptionModal()
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <button
                                                                className={`btn btn-outline-white ${subscriptionData &&
                                                                    subscriptionData?.details.filter(
                                                                        (item) =>
                                                                            !item.unsubscribe_status
                                                                    ).length > 0
                                                                    ? ""
                                                                    : "btn-disabled"
                                                                    } ${company_configs[
                                                                        "free_trial_period_enabled"
                                                                    ] &&
                                                                        !company_configs[
                                                                        "trial_expired"
                                                                        ]
                                                                        ? "d-none"
                                                                        : ""
                                                                    }`}
                                                            >
                                                                Edit <span> Subscription</span>
                                                            </button>
                                                        </div>}
                                                    {!subscriptionData?.subscription
                                                        ?.is_canceled && (
                                                            <div
                                                                className={`cancelSubscription btn btn-outline-white cursor ${company_configs[
                                                                    "free_trial_period_enabled"
                                                                ] &&
                                                                    !company_configs[
                                                                    "trial_expired"
                                                                    ]
                                                                    ? "d-none"
                                                                    : ""
                                                                    }`}
                                                                onClick={() => {
                                                                    setState((prev) => ({
                                                                        ...prev,
                                                                        cancelSubscriptionModal:
                                                                            true
                                                                    }));
                                                                }}
                                                            >
                                                                Cancel <span>Subscription</span>
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="bottom-total-fixed">
                                                    Total{" "}
                                                    <span>
                                                        {USDollar.format(
                                                            Number(subscriptionData?.subscription?.subscription_amount)
                                                        )}

                                                        {/* {subscriptionData
                                                            ? USDollar.format(
                                                                  Number(
                                                                      subscriptionData.details
                                                                          .reduce(
                                                                              (
                                                                                  accumulator,
                                                                                  currentValue
                                                                              ) =>
                                                                                  accumulator +
                                                                                  Number(
                                                                                      currentValue.total_cost
                                                                                  ),
                                                                              0
                                                                          )
                                                                          .toFixed(
                                                                              2
                                                                          )
                                                                  )
                                                              )
                                                            : USDollar.format(
                                                                  0
                                                              )} */}
                                                    </span>
                                                </div>
                                            </div>
                                            {/* {!subscriptionData?.subscription
                                                ?.is_canceled && (
                                                    <div
                                                        className={`cancelSubscription cursor ${company_configs[
                                                            "free_trial_period_enabled"
                                                        ] &&
                                                            !company_configs[
                                                            "trial_expired"
                                                            ]
                                                            ? "d-none"
                                                            : ""
                                                            }`}
                                                        onClick={() => {
                                                            setState((prev) => ({
                                                                ...prev,
                                                                cancelSubscriptionModal:
                                                                    true
                                                            }));
                                                        }}
                                                    >
                                                        Cancel Subscription
                                                    </div>
                                                )} */}
                                            {cancelSubscriptionModal && (
                                                <DeleteConfirmationModal
                                                    show={
                                                        cancelSubscriptionModal
                                                    }
                                                    handleClose={() =>
                                                        setState((prev) => ({
                                                            ...prev,
                                                            cancelSubscriptionModal:
                                                                false
                                                        }))
                                                    }
                                                    confirmBtnClick={() => {
                                                        dispatch(
                                                            cancelSubscription()
                                                        ).then((result) => {
                                                            if (result) {
                                                                const {
                                                                    status,
                                                                    msg
                                                                } = result;
                                                                if (
                                                                    status ===
                                                                    200
                                                                ) {
                                                                    editSubscriptionModal &&
                                                                        dispatch(
                                                                            toggleEditSubscriptionModal()
                                                                        );
                                                                    dispatch(
                                                                        clearSubscriptionData()
                                                                    );
                                                                    toast.success(
                                                                        msg
                                                                    );
                                                                    navigate(
                                                                        "/search"
                                                                    );
                                                                } else {
                                                                    toast.error(
                                                                        msg
                                                                    );
                                                                }
                                                            }
                                                        });
                                                    }}
                                                    content={
                                                        <p>
                                                            Are you sure you
                                                            want to cancel
                                                            subscription ?
                                                        </p>
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Scrollbars >
            {editSubscriptionModal && (
                <EditSubModal
                    handleSubModal={() => {
                        setState((prev) => ({
                            ...prev,
                            cancelSubscriptionModal: true
                        }));
                    }}
                />
            )}
            <MonthlyToYearlyUpgradeMsgModal />
            {
                checkOutModal && (
                    <Elements stripe={stripePromise}>
                        <PaymentModal
                            onCancelBtnClick={() => {
                                dispatch(hideCheckOutModal());
                            }}
                            removeBasinOrCounty={() => { }}
                            seatModal={true}
                        />
                    </Elements>
                )
            }
            <Tooltip id="alert-canceled-subscription" />
            <Tooltip id="alert-canceled-subscription-link" />
            <Tooltip id="alert-add-seats" style={{ zIndex: 99 }} />
        </div >
    );
};

export default withSideNav(PlanSettingsView);

import React, { useEffect, useLayoutEffect, useState } from "react";
import GlobalModal from "../common/GlobalModal";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import {
    hideSiteLoader,
    showSiteLoader,
    toggleCreateAoiModal
} from "../store/actions/modal-actions";
import InputComponent from "../common/InputComponent";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { aoiModalInputValidation } from "../../Helper/validation";
import { CreateAoiSubmitForm } from "../models/submit-form";
import { checkDuplicateAOIName, clearAoiList, getDefaultAOIName, uploadAoiFile, uploadAoiUsingMapFile } from "../store/actions/aoi-actions";
import { toast } from "react-toastify";
import { File } from "../models/redux-models";
import { useNavigate } from "react-router-dom";


function CreateAoiModal({
    file,
    handleState,
    file_name,
    handleAoiName,
}: {
    file?: File | null;
    handleState?: (value: number, resetFile: boolean, fileName: string) => void;
    file_name?: string;
    handleAoiName?: (value: string) => void;
}) {
    const {
        esri: { lastAddedGeom,
            lastAddedGeomCRS },
        aoi: { usingMapCreateAoi },
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm<CreateAoiSubmitForm>({
        resolver: yupResolver(aoiModalInputValidation),
    });
    const navigate = useNavigate();
    const onSubmit = (data: (CreateAoiSubmitForm)) => {
        const { aoi_name } = data;
        dispatch(checkDuplicateAOIName({ aoi_name }, true)).then((res) => {
            const { status } = res;
            if (status === 200) {
                if (usingMapCreateAoi) {
                    dispatch(uploadAoiUsingMapFile({
                        aoi_name: aoi_name,
                        buffer_distance: Number(data.bufferDistance),
                        crs: Number(lastAddedGeomCRS as string),
                        geometry: lastAddedGeom ? (lastAddedGeom as any).coordinates.map((item: any, index: number) => {
                            return {
                                type: "Feature",
                                properties: { id: index + 1 },
                                geometry: {
                                    type: (lastAddedGeom as any).type,
                                    coordinates: [item],
                                }
                            }
                        }) : []
                    })).then((res) => {
                        
                        if (res.status === 200) {
                            toast.success("AOI created successfully")
                            dispatch(clearAoiList());
                            dispatch(toggleCreateAoiModal());
                            if (alertPreference === "custom") {
                                navigate("/aoi", {
                                    state: { aoi_id: res['data']['aoi_id'] },
                                });
                            }
                        }
                    })

                } else {
                    handleAoiName && handleAoiName(aoi_name);
                    dispatch(
                        uploadAoiFile({
                            file: file as File,
                            aoi_name,
                            buffer_distance: data.bufferDistance,
                            file_name: (file_name as string),
                        })
                    ).then((res) => {
                        const { status, msg } = res || {};
                        if (status === 200) {
                            dispatch(clearAoiList());
                            dispatch(toggleCreateAoiModal());
                        }
                        if (status === 422) {
                            toast.error("An error occurred while creating AOI: " + msg);
                            dispatch(showSiteLoader());
                            dispatch(toggleCreateAoiModal());
                            dispatch(hideSiteLoader());

                        }
                    });
                }
            }
        })


    };
    const [aoiDefaultName, setAoiDefaultName] = useState("");
    const [alertPreference, setAlertPreferences] = useState("default");


    useLayoutEffect(() => {
        dispatch(getDefaultAOIName()).then((res) => {
            if (res.status === 200) {
                setValue('aoi_name', res.aoi_name)
                setAoiDefaultName(res.aoi_name)
            }
        })
    }, [])

    useEffect(() => {
        reset();
        setValue('bufferDistance', "0")
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <GlobalModal
                contentClass="notice-continue-modal"
                center={true}
                // show={createAoiModal}
                show={true}
                onHide={() => dispatch(toggleCreateAoiModal())}
            >
                <form
                    className="form-block"
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="off"
                    autoCapitalize="off"
                >
                    <div className="content-block">
                        <div className="contentHeader">
                            <h2 className="text-left">Create AOI</h2>
                            <button type="button" onClick={() => dispatch(toggleCreateAoiModal())} className="btn-close" aria-label="Close"></button>
                        </div>
                        <div className="text-block">
                            <p className="aoilist">
                                Give your AOI a name you'll recognize so you can
                                spot it easily in your AOI list later.
                            </p>
                        </div>
                        <h2 className="text-left mb-4">AOI Name</h2>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <InputComponent
                                        name="aoi_name"
                                        placeholder="Enter AOI name"
                                        register={register}
                                        defaultValue={aoiDefaultName}
                                        errorMsg={errors.aoi_name?.message}
                                    />
                                    <p className="maxtext">Max 50 characters</p>
                                </div>
                            </div>
                        </div>
                        <><h2 className="text-left mb-4">Buffer Distance (miles)</h2>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <InputComponent
                                            name="bufferDistance"
                                            type={"number"}
                                            placeholder="Enter miles"
                                            register={register}
                                            defaultValue={0}
                                            errorMsg={"bufferDistance" in errors ? errors.bufferDistance?.message : ''}
                                        />
                                        <p className="maxtext">Add a buffer around your AOI</p>
                                    </div>
                                    <div className="form-group">
                                        <h2 className="text-left mb-4">Alerts</h2>
                                        <div className="defaultRadio">
                                            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                <input type="radio" className="btn-check" name="alertPreference" id="alertPreference1" autoComplete="off" value={"default"} checked={alertPreference === "default" ? true : false} onChange={(e) => {
                                                    e.target.checked && setAlertPreferences(e.target.value);
                                                }} />
                                                <label className="btn btn-primary" htmlFor="alertPreference1">Default</label>

                                                <input type="radio" className="btn-check" name="alertPreference" id="alertPreference2" autoComplete="off" value={"custom"}
                                                    checked={alertPreference === "custom" ? true : false}
                                                    onChange={(e) => {
                                                        e.target.checked && setAlertPreferences(e.target.value);
                                                    }}
                                                />
                                                <label className="btn btn-primary" htmlFor="alertPreference2">Custom</label>
                                            </div>
                                        </div>
                                        <p className="maxtext">Set your alert prefences</p>
                                    </div>
                                </div>
                            </div>
                        </>
                        <br />
                        <div className="actions">
                            {!usingMapCreateAoi ? <button
                                type="submit"
                                className="btn btn-outline-white"
                                onClick={() => {
                                    const temp = (file as File).name
                                        .split(".")
                                        .filter((item) => item !== ".");
                                    setValue(
                                        "aoi_name",
                                        temp
                                            .filter(
                                                (_item, index) =>
                                                    index !== temp.length - 1
                                            )
                                            .join("")
                                    );
                                }}
                            >
                                Use file name
                            </button> : <></>}
                            <button
                                type="submit"
                                className="btn btn-primary green-bg"
                            >
                                Save &amp; Name AOI
                            </button>
                        </div>
                    </div>
                </form>
            </GlobalModal>
        </>
    );
}

export default CreateAoiModal;

import React from "react";
// import GoNational from "./GoNational";

function BasinTabContent({ freeTrial = false }: { freeTrial?: boolean }) {
    return (
        <>
            <div className="basin-block">
                <div className="basin-circle">
                    {/* <img src="images/basin-icon.svg" alt="" /> */}
                    <img src="images/free-trial.svg" alt="" />
                </div>
                {/* <h3>10 Day Free Trial</h3> */}
                <div className="block-text-title">Select Your Basin</div>
            </div>
        </>
    );
}

export default BasinTabContent;

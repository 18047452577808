import React, { useEffect, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { renderPreviewHTML } from "@arcgis/core/symbols/support/symbolUtils";
import Renderers from "./config/carto/Renderers";
import { SET_FILTER, toggleAoi, toggleRigs, toggleWells, togglePlssTlss, toggleHeatmap } from "./redux/filters";
import LayerIds from "./config/layers/LayerIds";
import FieldNames from "./config/layers/FieldNames";

import "./css/wellTypeFilterPanel.scss";
import Switch from "react-switch";
import ColorByDropdown from "./ColorByDropdown";


// I set these so we can change them quickly in the code below. They are for the layer and field we're working with here.
const offsetWellsLayerId = LayerIds.WellData;
const filteredField = FieldNames.wellDataPointsTypeId;

// In the case of the Well type filter, if all items are deselected we want all
// items filtered from the layer.
const filterAllIfEmpty: boolean = false;

interface IWellTypeFilterPanelProps {
    setFilter: any
}


function WellTypeFilterPanel(props: IWellTypeFilterPanelProps) {

    const dispatch = useAppDispatch();

    const [currentFilterLayers, setCurrentFilterLayers] = useState<[Number]>();

    const {
        layers, aoiEnabled, wellsEnabled, rigsEnabled, plssTlssEnabled, heatmapEnabled
    } = useAppSelector((state) => state.filters);

    useEffect(() => {
        //TODO: Add in reseting all filters when sync between wells, panel and legend is coupled.
        //const renderers = Renderers.wellsByTypeRendererPlatform.uniqueValueInfos.map(v => v.value)
        //dispatch(SET_FILTER({layerId: offsetWellsLayerId, filterAllIfEmpty, value:renderers, field: filteredField}))
    }, [])

    useEffect(() => {
        if (offsetWellsLayerId in layers) {
            //@ts-ignore
            const layerFilters = layers[offsetWellsLayerId];
            setCurrentFilterLayers(layerFilters[filteredField]);
        }
    }, [layers])

    const selectAll = () => {
        const allIds = Renderers.wellsByTypeRendererPlatform.uniqueValueInfos.map(v => v.value);
        dispatch(SET_FILTER([{ layerId: offsetWellsLayerId, filterAllIfEmpty, value: allIds, field: filteredField }]));
    }

    const delselectAll = () => {
        dispatch(SET_FILTER([{ layerId: offsetWellsLayerId, filterAllIfEmpty, value: [], field: filteredField }]));
    }

    const wellItemClicked = (id: any) => {
        return;
        //Disable map ability to toggle legends, filters will be toggled based on left panel searches until complete
        //sync is available.
        //TODO: Re-enable once legend, well list and panel are all in sync.
        //dispatch(TOGGLE_FILTER_VALUE({layerId: offsetWellsLayerId, filterAllIfEmpty, value:id, field: filteredField}));
    }

    // Move symbol creation to useMemo
    const { renderedSymbols, rigSymbol, aoiSymbol, heatmapSymbol } = useMemo(() => {
        const createSymbolNode = (symbol: any, iconClass: string, label: string) => {
            const node = document.createElement("div");
            node.setAttribute("class", "wellTypeFilterListItem");
            const imgSpan = document.createElement("span");
            imgSpan.setAttribute("class", iconClass);

            // Only call renderPreviewHTML if we have a symbol object
            if (symbol && typeof symbol === 'object') {
                renderPreviewHTML(symbol, {
                    node: imgSpan,
                    size: 8
                });
            }

            node.appendChild(imgSpan);

            const labelSpan = document.createElement("span");
            labelSpan.setAttribute("class", "wellTypeLabel");
            labelSpan.textContent = label;
            node.appendChild(labelSpan);

            return node;
        };

        // Rig Symbol
        const rigValueInfo = Renderers.wellsByTypeRendererPlatform.uniqueValueInfos.find(
            (valueInfo) => valueInfo.value === -100
        );
        const rigSymbolObj: any = Renderers.rigLegendSymbol;
        const rigSymbol = {
            value: rigValueInfo?.value || -100,
            label: rigValueInfo?.label || "Rig",
            createNode: () => createSymbolNode(rigSymbolObj, "wellTypeRigIcon", rigValueInfo?.label || "Rig")
        };

        // AOI Symbol
        const aoiSymbol = {
            value: -200,
            label: "AOI",
            createNode: () => createSymbolNode(null, "wellTypeIconAoi", "AOI")
        };

        // Heatmap Symbol
        const heatmapSymbol = {
            value: -300,
            label: "Heatmap",
            createNode: () => createSymbolNode(null, "wellTypeIconCounty", "Heatmap")
        };

        // Well Symbols
        const renderedSymbols = Renderers.wellsByTypeRendererPlatform.uniqueValueInfos
            .filter(valueInfo => valueInfo.value !== -100)
            .map(valueInfo => {
                const { value, label, symbol } = valueInfo;
                return {
                    value,
                    label,
                    createNode: () => createSymbolNode(symbol, "wellTypeIcon", label)
                };
            });

        return { renderedSymbols, rigSymbol, aoiSymbol, heatmapSymbol };
    }, []);

    const handleWellToggled = (checked: boolean) => {
        dispatch(toggleWells(checked));
    }

    const handleRigsToggled = (checked: boolean) => {
        dispatch(toggleRigs(checked));
    }

    const handleAoiToggled = (checked: boolean) => {
        dispatch(toggleAoi(checked));
    }

    const handleHeatmapToggled = (checked: boolean) => {
        dispatch(toggleHeatmap(checked));
    }

    return (
        <div>
            <hr></hr>
            <ul className="wellTypeFilterList">
                <li className="filterHeader">
                    <span className="filterHeaderLabel">WELL STATUS</span>
                    <Switch className="filterToggle" height={17} width={34} checkedIcon={false} uncheckedIcon={false} checked={wellsEnabled} onChange={handleWellToggled}></Switch>
                </li>
                {renderedSymbols.map(renderedSymbol => {
                    if (renderedSymbol != null) {
                        return (
                            <li
                                key={renderedSymbol.value}
                                className="wellTypeFilterListItem"
                                onClick={e => wellItemClicked(renderedSymbol.value)}
                                ref={node => {
                                    if (node && !node.hasChildNodes()) {
                                        node.appendChild(renderedSymbol.createNode());
                                    }
                                }}
                            />
                        );
                    }
                })}
            </ul>
            <ColorByDropdown />
            <hr></hr>
            <ul className="wellTypeFilterList">
                <li className="filterHeader">
                    <span className="wellTypeFilterListItem" ref={node => {
                        if (node && !node.hasChildNodes()) {
                            node.appendChild(rigSymbol.createNode());
                        }
                    }} />
                    <Switch className="filterToggle" height={17} width={34} checkedIcon={false} uncheckedIcon={false} checked={rigsEnabled} onChange={handleRigsToggled} />
                </li>
            </ul>
            <hr></hr>
            <ul className="wellTypeFilterList">
                <li className="filterHeader">
                    <span className="wellTypeFilterListItem" ref={node => {
                        if (node && !node.hasChildNodes()) {
                            node.appendChild(aoiSymbol.createNode());
                        }
                    }} />
                    <Switch className="filterToggle" height={17} width={34} checkedIcon={false} uncheckedIcon={false} checked={aoiEnabled} onChange={handleAoiToggled} />
                </li>
            </ul>
            <hr></hr>
            <ul className="wellTypeFilterList">
                <li className="filterHeader">
                    <span className="wellTypeFilterListItem" ref={node => {
                        if (node && !node.hasChildNodes()) {
                            node.appendChild(heatmapSymbol.createNode());
                        }
                    }} />
                    <Switch className="filterToggle" height={17} width={34} checkedIcon={false} uncheckedIcon={false} checked={heatmapEnabled} onChange={handleHeatmapToggled} />
                </li>
            </ul>
            <hr></hr>
        </div>
    );
}

export default React.memo(WellTypeFilterPanel);

import React from "react";
// import GoNational from "./GoNational";

function CountyTabContent({ freeTrial = false }: { freeTrial?: boolean }) {
    return (
        <>
            <div className="basin-block">
                <div className="basin-circle">
                    <img src="images/free-trial.svg" alt="" />
                </div>
                <div className="block-text-title">Select Your County</div>
            </div>
        </>

    );
}

export default CountyTabContent;

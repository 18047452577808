import React from "react";
import { Controller } from "react-hook-form";
import { AsyncSelectProps } from "../models/page-props";
import { AsyncPaginate } from "react-select-async-paginate";
// import { Tooltip } from 'react-tooltip'
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function AsyncSelect({
    control,
    name,
    errorMsg,
    loadOptions,
    labelClassName,
    label,
    className,
    value,
    ...rest
}: AsyncSelectProps) {
    return (
        <>
            {label && <label className={labelClassName}>{label}</label>}
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, ref, ...field } }) => {
                    if (name.includes("wellMatching") && value && value.label) {
                        return (
                            <>

                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="button-tooltip" className="custom-tooltip">
                                        {value.label}
                                    </Tooltip>}
                                ><span>
                                        <AsyncPaginate
                                            value={value}
                                            loadOptions={loadOptions}
                                            menuShouldScrollIntoView={false}
                                            onChange={onChange}
                                            className={className}
                                            selectRef={ref}
                                            {...rest}
                                        />
                                    </span>
                                </OverlayTrigger>
                                {/* <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="button-tooltip" className="custom-tooltip">
                                        {value.label}
                                    </Tooltip>}
                                >
                                    <AsyncPaginate
                                        value={value}
                                        loadOptions={loadOptions}
                                        menuShouldScrollIntoView={false}
                                        onChange={onChange}
                                        className={className}
                                        selectRef={ref}
                                        {...rest}
                                    />
                                </OverlayTrigger> */}
                            </>
                        )
                    }
                    return (<AsyncPaginate
                        value={value}
                        loadOptions={loadOptions}
                        menuShouldScrollIntoView={false}
                        onChange={onChange}
                        className={className}
                        selectRef={ref}
                        {...rest}
                    />
                    )
                }}
            />
            {errorMsg && <span className={`error`}>{errorMsg}</span>}
            {/* <Tooltip id="Async-Select-tooltip" /> */}
        </>
    );
}

export default AsyncSelect;

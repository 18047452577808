import React, { useState } from 'react';
import { DatePickerComponent } from "../common/DatePicker";
import { cartBasinDates as dateList } from "./CartBasinConstant";
import { cartBasinDateProps } from "../models/page-props";
import { getDateRange } from '../../utils/helper';
import moment from 'moment';

const CartBasinDate = (props: cartBasinDateProps) => {
    let { watch, control, onChangeEndDate, onChangeStartDate, getValues } =
        props;
    const [monthFilterID, setMonthFilterID] = useState<string[]>([]);
    return (
        <>
            {dateList.map(({ name, unique_name, fields, monthFilter, filterName }, index) => (
                <div className="form-group" key={index}>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="">{name}</label>
                        </div>
                        <div className="col-md-6">
                            <div className="sort-date">
                                {
                                    monthFilter.map((item) => {
                                        return <span onClick={() => {
                                            setMonthFilterID((prev) => ([...prev.filter(obj => obj.split("_")[0] !== filterName), ...(!monthFilterID.includes(item.id) ? [item.id] : [])]));
                                            const { startDate, endDate } = getDateRange(Number(item.id.split("_")[1]));
                                            onChangeStartDate(moment(startDate).toDate(), unique_name)
                                            onChangeEndDate(moment(endDate).toDate(), unique_name)

                                        }}>
                                            <input type="radio" className='css-radio' id='' />
                                            <label htmlFor="" className='css-labell'>
                                                {item.label}
                                            </label>
                                        </span>
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-md-6" onClick={() => {
                            JSON.stringify(monthFilterID).includes(filterName) && setMonthFilterID((prev) => ([...prev.filter(obj => obj.split("_")[0] !== filterName)]));
                        }}>
                            <div className="calInput">
                                <DatePickerComponent
                                    control={control}
                                    name={fields[0].f_fieldname}
                                    defaultValue={null}
                                    minDate={null}
                                    placeholderText="MM-DD-YYYY"
                                    className="form-control cursor"
                                    onChangeHandle={(date) =>
                                        onChangeStartDate(date, unique_name)
                                    }
                                    showerror={false}
                                />
                            </div>
                        </div>
                        <div className="col-md-6" onClick={() => {
                            JSON.stringify(monthFilterID).includes(filterName) && setMonthFilterID((prev) => ([...prev.filter(obj => obj.split("_")[0] !== filterName)]));
                        }}>
                            <div className="calInput">
                                <DatePickerComponent
                                    control={control}
                                    name={fields[0].l_fieldname}
                                    defaultValue={null}
                                    placeholderText="MM-DD-YYYY"
                                    className="form-control cursor"
                                    minDate={
                                        watch([fields[0].f_fieldname]) &&
                                        new Date(
                                            new Date(
                                                getValues()[
                                                fields[0].f_fieldname
                                                ]
                                            ).setDate(
                                                new Date(
                                                    getValues()[
                                                    fields[0].f_fieldname
                                                    ]
                                                ).getDate() + 1
                                            )
                                        )
                                    }
                                    onChangeHandle={(date) => {
                                        onChangeEndDate(date, unique_name);
                                    }}
                                    showerror={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default CartBasinDate;

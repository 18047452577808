import { GroupBase, OptionsOrGroups } from "react-select";
import { reactSelectProps } from "../models/page-props";
import { fetchOptionsList } from "../store/actions/cart-basin-to-county-actions";
import store from "../store";
import { toast } from "react-toastify";
import { extractOption, processString } from "../../utils/helper";
import { wellStatusOption, wellTypeOption, menuOption, drillTypeOption, productType } from "./CartBasinConstant";

const { dispatch, getState } = store;
let abortController = new AbortController();

export const handleOption = async (
    search: string,
    prevOptions: OptionsOrGroups<reactSelectProps, GroupBase<reactSelectProps>>,
    { page, name }: any,
    extraParam?: any
) => {
    let optionList: reactSelectProps[] = [];
    const { cartBasinToCounty: { state_list } } = getState();
    // Reset the AbortController for each new request
    if (abortController) {
        abortController.abort();
    }
    abortController = new AbortController();
    const signal = abortController.signal;
    if (name === "drill_type") {
        return {
            options: search
                ? [
                    ...drillTypeOption.filter((item) =>
                        item.label.toLowerCase().includes(search.trim().toLowerCase())
                    ),
                ]
                : [...drillTypeOption, {
                    label: 'Select All',
                    value: 'Select All'
                }],
            hasMore: false,
            additional: {
                page: page + 1,
            },
        };
    }

    if (name === "state_abbr" && state_list.length) {
        return {
            options: search
                ? [
                    ...state_list.filter((item) =>
                        (item.label || "").toLowerCase().includes(search.trim().toLowerCase())
                    ),
                ]
                : state_list,
            hasMore: false,
            additional: {
                page: page + 1,
            },
        };
    }

    if (name === "production_type") {
        return {
            options: search
                ? [
                    ...productType.filter((item) =>
                        item.label.toLowerCase().includes(search.trim().toLowerCase())
                    ),
                ]
                : [...productType, {
                    label: 'Select All',
                    value: 'select_all'
                }],
            hasMore: false,
            additional: {
                page: page + 1,
            },
        };
    }

    if (name === "category") {
        let option = menuOption.map((item) => ({ label: item.label, value: item.value }))
        return {
            options: search
                ? [
                    ...option.filter((item) =>
                        item.label.toLowerCase().includes(search.trim().toLowerCase())
                    ),
                ]
                : option,
            hasMore: false,
            additional: {
                page: page + 1,
            },
        };
    }

    if (name === "sub_category") {
        const { category } = extraParam;
        let option: {
            label: string;
            value: string;
        }[] = []
        menuOption.filter(_item => category.includes(_item.value)).forEach(item => {
            option = [...option, ...item.subMenu]
        });
        return {
            options: search
                ? [
                    ...(option).filter((item) =>
                        item.label.toLowerCase().includes(search.trim().toLowerCase())
                    ),
                ]
                : [...option, {
                    label: 'Select All',
                    value: 'select_all'
                }],
            hasMore: false,
            additional: {
                page: page + 1,
            },
        };
    }

    if (name === "well_status") {
        return {
            options: search
                ? [
                    ...wellStatusOption.filter((item) =>
                        item.label.toLowerCase().includes(search.trim().toLowerCase())
                    ),
                ]
                : [...wellStatusOption, {
                    label: 'Select All',
                    value: 'select_all'
                }],
            hasMore: false,
            additional: {
                page: page + 1,
            },
        };
    }

    if (name === "well_type") {
        return {
            options: search
                ? [
                    ...wellTypeOption.filter((item) =>
                        item.label.toLowerCase().includes(search.trim().toLowerCase())
                    ),
                ]
                : [...wellTypeOption, {
                    label: 'Select All',
                    value: 'select_all'
                }],
            hasMore: false,
            additional: {
                page: page + 1,
            },
        };
    }
    const fieldListForShortenedSearch = ["state_abbr", "legaldesc_abstract", "quarter_section", "legaldesc_block", "legaldesc_section", "legaldesc_township", "legaldesc_range", "rapid_api"]
    if ((name !== "api_file" && name !== "county" && name !== "basin_name" && (!fieldListForShortenedSearch.includes(name) && (!search || search.length < 3)) || (fieldListForShortenedSearch.includes(name) && (!search || search.length === 0)))) {
        return {
            options: [],
            hasMore: false,
            additional: {
                page,
            },
        };
    }
    //validation of max length
    if ((name === "reservoir" || name === "operator_name") && "filter_param" in extraParam) {
        name === "operator_name" ? delete extraParam['filter_param']["operator"] : delete extraParam['filter_param'][name]
    }
    const max_character = (name === "rapid_api" || name === "well_api") ? 17 : name === "name" ? 100 : name === "operator_name" ? 120 : 0;
    if (search &&
        (name === "rapid_api" && search.trim().length > max_character)
        || ((name === "name" || name === "operator_name") && search.trim().length > max_character)
        || ((name === "operator_name") && search.trim().length > max_character)
        || (name === "well_api" && search.trim().split(",").map((val) => val.trim()).some((val) => val.length > max_character))
    ) {
        toast.error(name === "well_api" && search.trim().split(",").length > 1 ? `Each value must not exceed ${max_character} characters.` : `Limit alert! Max allowed: ${max_character} characters.`, {
            toastId: "max_character"
        })
        return {
            options: [],
            hasMore: false,
            additional: {
                page,
            },
        };
    }

    const res = await dispatch(
        fetchOptionsList({
            search_field: name === "rapid_api" ? "well_api" : name,
            ...(name === "rapid_api" && {
                rapid: true
            }),
            // page,
            ...(search && { like: name === "well_api" ? processString(search.trim()) : search.trim() }),
            ...((name === "county" || name === "basin_name") && {
                state_abbr: extraParam["state"].split(","),
                ...(name === "basin_name" && { county: extraParam["county"].split(",") }),
            }),
            ...((name === "reservoir" || name === "operator_name") && {
                filter_param: extraParam["filter_param"],
            }),
            ...(name === "county" && {
                page
            }),
            signal: signal, // Pass the abort signal to the fetch action
        })
    );
    const { status, data, msg, total_count, page_size } = res || {};

    if (status === 200 && "data" in res) {
        switch (name) {
            case "name":
                optionList = extractOption(data, name);
                break;
            case "well_status":
                optionList = extractOption(data, name);
                break;
            case "well_api":
                optionList = extractOption(data, name);
                if (optionList.length > 1) {
                    optionList = [...optionList, {
                        label: 'Select All',
                        value: 'select_all'
                    }];
                }
                break;
            case "rapid_api":
                optionList = extractOption(data, "well_api");
                break;
            case "operator_name":
                optionList = extractOption(data, name, true);
                break;
            case "state_abbr":
                optionList = data.map((item) => ({
                    label: item.state_name,
                    value: item.state_abbr,
                }));
                break;
            case "county":
                optionList = extractOption(data, name);
                break;
            case "basin_name":
                optionList = extractOption(data, name);
                break;
            case "legaldesc_survey":
                optionList = extractOption(data, name);
                break;
            case "api_file":
                optionList = data.map((item) => ({
                    label: item.file_name,
                    value: item.id,
                }));
                break;
            case "legaldesc_abstract":
                optionList = data.map((item) => ({
                    label: item.legaldesc_abstract,
                    value: item.legaldesc_abstract,
                }));
                break;
            case "quarter_section":
                optionList = data.map((item) => ({
                    label: item.quarter_section,
                    value: item.quarter_section,
                }));
                break;
            case "legaldesc_block":
                optionList = data.map((item) => ({
                    label: item.legaldesc_block,
                    value: item.legaldesc_block,
                }));
                break;
            case "legaldesc_section":
                optionList = data.map((item) => ({
                    label: item.legaldesc_section,
                    value: item.legaldesc_section,
                }));
                break;
            case "legaldesc_township":
                optionList = data.map((item) => ({
                    label: item.legaldesc_township,
                    value: item.legaldesc_township,
                }));
                break;
            case "legaldesc_range":
                optionList = data.map((item) => ({
                    label: item.legaldesc_range,
                    value: item.legaldesc_range,
                }));
                break;
            case "reservoir":
                optionList = extractOption(data, name, true);
                break;
            case "field":
                optionList = extractOption(data, name);
                break;
            default:
                break;
        }
    } else {
        status !== 200 && toast.error(msg);
    }
    let totalPage = 1;
    if (name === "county") {
        totalPage = Math.floor(total_count / page_size) +
            (total_count % page_size > 0 ? 1 : 0);
    }
    return {
        options: optionList,
        hasMore: name === "county" && page < totalPage ? true : false,
        additional: {
            page: page + 1,
        },
    };
};

import { AnyAction } from "redux";
import { RootState } from "../index";
import { ThunkAction } from "redux-thunk";
import axiosAuth from "../../../utils/axios/axios";
import { errorHandler } from "../../../Helper/axiosError";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import {
    BasinCountySearchListFormData,
    FetchOptionQueryData,
    FetchOptionReturnType,
    OptionType,
    ReturnMsgAndStatus,
    UploadShapeFileReturnType,
} from "../../models/submit-form";
import cartBasinToCountySlice from "../reducers/cart-basin-to-county-slice";
import {
    LATERAL_LENGTH_MAX,
    MEASURED_DEPTH_MAX,
    TRUE_VERTICAL_DEPTH_MAX,
    actionType,
    config,
    errToast,
} from "../../../utils/helper";
import { hideSiteLoader, showSiteLoader } from "./modal-actions";
import {
    AddAllBasinOrCountyToCartFormData,
    SaveApiListFormData,
    cartBasinState,
} from "../../models/redux-models";
import { getCartDetails } from "./cart-select-basin-county-actions";
import { logUserAction } from "./auth-actions";
import { File } from "../../models/redux-models";
import { toggleViewAnalytics } from "./wells-rigs-action";

let { clearSearchList, fetchBasinSearchList } = cartBasinToCountySlice.actions;

export const cartBasinSearchList = (
    formData: BasinCountySearchListFormData
): ThunkAction<void, RootState, unknown, AnyAction> => {
    const { search, category } = formData;
    return async (dispatch) => {
        try {
            let response = await axiosAuth({
                method: "get",
                url: `/api-subscriptions/get-suggetion?cat=${category}&search=${search}`,
                headers: config.headers,
            });
            const { status, msg, data } = response.data;
            if (status === 200) {
                if (data) {
                    if (category === "basin") {
                        dispatch(fetchBasinSearchList(data));
                    }
                }
            } else {
                toast.error(msg);
            }
        } catch (err) {
            let { message } = errorHandler(err as AxiosError);
            toast.error(message);
        }
    };
};

export const clearCartBasinSearchList = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch) => {
        dispatch(clearSearchList());
    };
};

//to fetchOptions of DropDown
export const fetchOptionsList =
    (
        data: FetchOptionQueryData,
        loader = false
    ): ThunkAction<
        Promise<FetchOptionReturnType>,
        RootState,
        unknown,
        AnyAction
    > =>
        async (dispatch, getState) => {
            loader && dispatch(showSiteLoader());
            const signal = "signal" in data ? data["signal"] : null;
            delete data["signal"];
            try {
                const response = await axiosAuth.post(
                    `api-search/search-params`,
                    data,
                    { ...config, ...(signal && { signal }) }
                );
                const { status, msg } = response.data;
                if (status !== 200) toast.error(msg);
                loader && dispatch(hideSiteLoader());
                return response.data;
            } catch (err) {
                loader && dispatch(hideSiteLoader());
                (err as AxiosError)["code"] !== "ERR_CANCELED" &&
                    errToast(err as AxiosError);
            }
        };

        export const uploadShapeFileOrCsv = (
            formData: { file: File; aoi_name?: string; buffer_distance?: string },
            fileType: "upload_shapefile" | "upload_api_list"
        ): ThunkAction<
            Promise<UploadShapeFileReturnType>,
            RootState,
            unknown,
            AnyAction
        > => {
            return async (dispatch, getState) => {
                const { aoi_name, buffer_distance } = formData;
                buffer_distance && dispatch(showSiteLoader());
                const { file } = formData;
                const filename = file.name;
        
                try {
                    // Set Content-Disposition for download handling
                    axiosAuth.defaults.headers.common[
                        "Content-Disposition"
                    ] = `attachment; filename=${filename}`;
        
                    let res;
        
                    if (fileType === "upload_shapefile") {
                        // Send file as binary data
                        const binaryData = await file.arrayBuffer(); // Convert File to binary ArrayBuffer
                        res = await axiosAuth.put(
                            `/api-search/upload-file${aoi_name
                                ? `?aoi_name=${aoi_name}&save_as_aoi=${true}${buffer_distance
                                    ? `&buffer_distance=${buffer_distance}`
                                    : ""
                                }`
                                : ""
                            }`,
                            binaryData,
                            {
                                headers: {
                                    "Content-Type": file.type || "application/octet-stream",
                                },
                            }
                        );
                    } else {
                        // Send as FormData for CSV or API List uploads
                        const payloadData = new FormData();
                        payloadData.append("file", file, filename);
        
                        res = await axiosAuth.put(
                            `/api-search/upload-file${aoi_name
                                ? `?aoi_name=${aoi_name}&save_as_aoi=${true}${buffer_distance
                                    ? `&buffer_distance=${buffer_distance}`
                                    : ""
                                }`
                                : ""
                            }`,
                            payloadData
                        );
                    }
        
                    const { status, msg, data, file_name } = res.data || {};
                    buffer_distance && dispatch(hideSiteLoader());
        
                    return aoi_name
                        ? {
                            status,
                            msg,
                            data: {
                                filter_data:
                                    status === 200 ? data?.data?.multi_polygon : "",
                                id: data?.data?.aoi_id,
                            },
                            ...(file_name && { file_name }),
                            ...(data?.epsg && status === 200 && { epsg: data?.epsg }),
                        }
                        : {
                            ...res.data,
                            ...(data?.epsg && status === 200 && { epsg: data?.epsg }),
                        };
                } catch (err) {
                    errToast(err as AxiosError);
                    dispatch(hideSiteLoader());
                }
            };
        };
        
//Add all basin or county to cart
export const addAllBasinOrCountyToCart = (
    formData: AddAllBasinOrCountyToCartFormData
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        try {
            const res = await axiosAuth.post(
                "/api-subscriptions/get-cart-item",
                formData,
                config
            );
            const { status, msg } = res.data;

            if (status === 200) {
                dispatch(getCartDetails());
                toast.success(msg);
            } else {
                toast.error(msg);
                dispatch(hideSiteLoader());
            }

            return res.data;
        } catch (err) {
            dispatch(hideSiteLoader());
            errToast(err as AxiosError);
        }
    };
};

//remove an item from apiList upgrade from cart
export const removeItemFromCartInApiListUpgradeModal = (
    formData: AddAllBasinOrCountyToCartFormData,
    doNotCallFetchCartListApi?: boolean
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        const config = {
            data: {
                ...formData,
            },
            headers: { "Content-Type": "application/json" },
        };
        try {
            const res = await axiosAuth.delete(
                "/api-subscriptions/deselect-all",
                config
            );
            const { status, msg } = res.data;

            if (status === 200) {
                !doNotCallFetchCartListApi && dispatch(getCartDetails());
                toast.success(msg);
            } else {
                toast.error(msg);
                dispatch(hideSiteLoader());
            }

            return res.data;
        } catch (err) {
            dispatch(hideSiteLoader());
            errToast(err as AxiosError);
        }
    };
};

//continue without saving api list
export const contWithoutSavingApiList = (): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        try {
            const res = await axiosAuth.delete(`/api-search/csv-api`, config);
            const { status } = res.data;
            if (status === 200) {
                //log user actions
                dispatch(
                    logUserAction({
                        action_type: actionType["upload_api_list"],
                        action_log_detail: JSON.stringify({
                            message: `Continue without saving`,
                        }),
                    })
                );
            }
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//save api list
export const saveApiList = (
    formData: SaveApiListFormData
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        try {
            const res = await axiosAuth.post(
                "/api-search/csv-api",
                formData,
                config
            );
            const { status, file_uploaded } = res.data;
            if (status === 200) {
                //log user actions
                dispatch(
                    logUserAction({
                        action_type: actionType["upload_api_list"],
                        action_log_detail: JSON.stringify({
                            file_name: file_uploaded,
                        }),
                    })
                );
            }
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            dispatch(hideSiteLoader());
            errToast(err as AxiosError);
        }
    };
};

export const handleWellApiListAfterCsvUpload = (
    data: OptionType[]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(
            cartBasinToCountySlice.actions.handleWellApiListAfterCsvUpload(data)
        );
    };
};
//handle slider value
export const handleSliderValue = (
    data: cartBasinState["sliderMinMaxValue"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(cartBasinToCountySlice.actions.handleSliderValue(data));
    };
};

//get slider Max Value
export const fetchSliderMaxValue = (): ThunkAction<
    Promise<
        ReturnMsgAndStatus & {
            lateral_length: number;
            true_vertical_depth: number;
            measured_depth: number;
        }
    >,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        // dispatch(showSiteLoader());
        try {
            const res = await axiosAuth.get(`/api-search/get-slider-value`, config);
            const { status, data } = res.data;
            if (status === 200 && data) {
                dispatch(
                    handleSliderValue({
                        maxLateralLength:
                            data[0].lateral_length < LATERAL_LENGTH_MAX
                                ? data[0].lateral_length
                                : LATERAL_LENGTH_MAX,
                        maxTrueVerticalDepth:
                            data[0].true_vertical_depth <
                                TRUE_VERTICAL_DEPTH_MAX
                                ? data[0].true_vertical_depth
                                : TRUE_VERTICAL_DEPTH_MAX,
                        maxMeasuredDepth:
                            data[0].measured_depth < MEASURED_DEPTH_MAX
                                ? data[0].measured_depth
                                : MEASURED_DEPTH_MAX,
                        dataLoading: false,
                        ...data[0],
                    })
                );
            }
            // dispatch(hideSiteLoader());
            return {
                ...res.data,
                lateral_length: data
                    ? data[0].lateral_length < LATERAL_LENGTH_MAX
                        ? data[0].lateral_length
                        : LATERAL_LENGTH_MAX
                    : 0,
                true_vertical_depth: data
                    ? data[0].true_vertical_depth < TRUE_VERTICAL_DEPTH_MAX
                        ? data[0].true_vertical_depth
                        : TRUE_VERTICAL_DEPTH_MAX
                    : 0,
                measured_depth: data
                    ? data[0].measured_depth < MEASURED_DEPTH_MAX
                        ? data[0].measured_depth
                        : MEASURED_DEPTH_MAX
                    : 0,
            };
        } catch (err) {
            // dispatch(hideSiteLoader());
            errToast(err as AxiosError);
        }
    };
};

export const toggleLeftTab = (
    val: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        if (val) {
            dispatch(toggleViewAnalytics(false));
        }
        dispatch(cartBasinToCountySlice.actions.toggleLeftTab(val));
    };
};

export const handleClearAllFilter = (
    val: cartBasinState["clearAllFilter"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToCountySlice.actions.handleClearAllFilter(val));
    };
};

export const handleAdvancedFilter = (
    val: cartBasinState["showAdvancedFilter"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(cartBasinToCountySlice.actions.handleAdvancedFilter(val));
    };
};

//handleSavedFilter
export const handleSavedFilter = (val: {
    openSavedFilter?: cartBasinState["savedFilter"]["openSavedFilter"];
    saveFiltersDataLoading?: cartBasinState["savedFilter"]["saveFiltersDataLoading"];
    saveFiltersList?: cartBasinState["savedFilter"]["saveFiltersList"];
    filterID?: cartBasinState["savedFilter"]["filterID"];
    reset?: boolean;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(cartBasinToCountySlice.actions.handleSavedFilter(val));
    };
};

export const handleSavedFilterColumnProperties = (val: {
    openSavedFilter?: cartBasinState["savedFilterColumnProperties"]["openSavedFilterColumnProperties"];
    saveFiltersDataLoading?: cartBasinState["savedFilterColumnProperties"]["saveFiltersDataLoadingColumnProperties"];
    filterID?: cartBasinState["savedFilterColumnProperties"]["filterIDColumnProperties"];
    reset?: boolean;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(cartBasinToCountySlice.actions.handleSavedFilterColumnProperties(val));
    };
};

//save search filters
export const saveSearchFilters = (formData: {
    page?: number;
    search_type?: string;
    search_param?: string;
    aoi_id?: number;
    sort_order?: string;
    sort_by?: string;
    uid?: string[];
    [x: string]: any;
}): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        try {
            const res = await axiosAuth.post(
                "/api-search/save-search",
                { search_param: formData },
                config
            );
            const { status, msg } = res.data;
            if (status === 200) {
                toast.success("Filter saved successfully");
                dispatch(fetchSavedSearchFilters());
            } else {
                toast.error(msg);
                dispatch(
                    handleSavedFilter({
                        saveFiltersDataLoading: false,
                    })
                );
            }
            return res.data;
        } catch (err) {
            dispatch(hideSiteLoader());
            errToast(err as AxiosError);
            dispatch(
                handleSavedFilter({
                    saveFiltersDataLoading: false,
                })
            );
        }
    };
};

//fetch  saved search filters
export const fetchSavedSearchFilters = (): ThunkAction<
    Promise<ReturnMsgAndStatus>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        try {
            const res = await axiosAuth.get("/api-search/save-search", config);
            const { status, msg, data } = res.data;
            if (status === 200) {
                dispatch(
                    handleSavedFilter({
                        saveFiltersDataLoading: false,
                        saveFiltersList: data ? data : [],
                        filterID: 0,
                    })
                );
            } else {
                toast.error(msg);
            }
            return res.data;
        } catch (err) {
            dispatch(hideSiteLoader());
            errToast(err as AxiosError);
        }
    };
};

//delete  saved search filters
export const deleteSavedSearchFilters = (formData: {
    id: number;
}): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(
            handleSavedFilter({
                saveFiltersDataLoading: true,
            })
        );
        const {
            cartBasinToCounty: {
                savedFilter: { saveFiltersList },
            },
        } = getState();
        const config = {
            data: {
                ...formData,
            },
            headers: { "Content-Type": "application/json" },
        };
        try {
            const res = await axiosAuth.delete("/api-search/save-search", config);
            const { status, msg, data } = res.data;
            if (status === 200) {
                dispatch(
                    handleSavedFilter({
                        saveFiltersDataLoading: false,
                        saveFiltersList: (saveFiltersList || []).filter(
                            (item: { id: number; search_name: string }) =>
                                item.id !== formData["id"]
                        ),
                        filterID: 0,
                    })
                );
            } else {
                toast.error(msg);
                dispatch(
                    handleSavedFilter({
                        saveFiltersDataLoading: false,
                    })
                );
            }
            return res.data;
        } catch (err) {
            dispatch(hideSiteLoader());
            errToast(err as AxiosError);
            dispatch(
                handleSavedFilter({
                    saveFiltersDataLoading: false,
                })
            );
        }
    };
};



//update the filter name
export const updateFilterName = (formData: {
    search_name: string;
    id: number;
}): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(
            handleSavedFilter({
                saveFiltersDataLoading: true,
            })
        );
        const {
            cartBasinToCounty: {
                savedFilter: { saveFiltersList },
            },
        } = getState();
        try {
            const res = await axiosAuth.put(
                "/api-search/save-search",
                formData,
                config
            );
            const { status, msg } = res.data;
            if (status === 200) {
                dispatch(
                    handleSavedFilter({
                        saveFiltersDataLoading: false,
                        saveFiltersList: (saveFiltersList || [])?.map((item) =>
                            Number(item.id) === Number(formData["id"])
                                ? {
                                    ...item,
                                    search_name: formData["search_name"],
                                }
                                : item
                        ),
                        filterID: 0,
                    })
                );
            } else {
                toast.error(msg);
                dispatch(
                    handleSavedFilter({
                        saveFiltersDataLoading: false,
                    })
                );
            }
            return res.data;
        } catch (err) {
            dispatch(hideSiteLoader());
            errToast(err as AxiosError);
            dispatch(
                handleSavedFilter({
                    saveFiltersDataLoading: false,
                })
            );
        }
    };
};



//fetch saved search filters by id
export const fetchSavedSearchFiltersByID = (
    id: number
): ThunkAction<
    Promise<ReturnMsgAndStatus & { data: { [x: string]: any } }>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        try {
            const res = await axiosAuth.get(
                `/api-search/get-search-param?id=${id}`,
                config
            );
            const { status, msg, data } = res.data;
            if (status !== 200) {
                toast.error(msg);
            }
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            dispatch(hideSiteLoader());
            errToast(err as AxiosError);
        }
    };
};

import axiosAuth from "./axios";

const setAuthToken = (token: string | null) => {
    if (token) {
        let device_session_id = localStorage.getItem("device_session_id");
        // Adding header type for HTTP Request
        axiosAuth.defaults.headers.common["Authorization"] = "Bearer " + token;
        if (device_session_id)
            axiosAuth.defaults.headers.common["X-DEVICE-SESSION-ID"] =
                Number(device_session_id);
    } else {
        delete axiosAuth.defaults.headers.common["Authorization"];
    }
};

export default setAuthToken;

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import GlobalModal from '../GlobalModal'
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { handleTermsAndConditionModal, hideSiteLoader, showSiteLoader } from '../../store/actions/modal-actions';
import { Button } from 'react-bootstrap';
import { fetchTermsAndCondition, handleTermsAccepted, handleTermsAndCondition } from '../../store/actions/auth-actions';
import parse from "html-react-parser";
import { Link } from 'react-router-dom';


function TermsAndConditionModal({ continueBtnClick }: { continueBtnClick: () => void }) {

    const termsAndConditionModal = useAppSelector(state => state.modal.termsAndConditionModal);
    const termsAccepted = useAppSelector(state => state.auth.termsAccepted);
    const termsAndCondition = useAppSelector(state => state.auth.termsAndCondition);

    const dispatch = useAppDispatch();

    const divRef = useRef<HTMLDivElement | null>(null);
    const [initialDisabled, setInitialDisabled] = useState(true)

    const handleScroll = () => {
        if (divRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = divRef.current;

            // Check if the user has scrolled enough to read the last line
            if (scrollTop + clientHeight >= scrollHeight - 1) { // 1px buffer to ensure the bottom is reached
                setInitialDisabled(false);
            }
        }
    };

    const handleClose = () => {
        dispatch(handleTermsAccepted(false));
        dispatch(handleTermsAndConditionModal(false));
    }

    useLayoutEffect(() => {
        if (termsAndConditionModal && termsAndCondition === null) {
            dispatch(showSiteLoader())
            dispatch(fetchTermsAndCondition()).then((res) => {
                const { status, data } = res;
                status === 200 && dispatch(handleTermsAndCondition(data))
            }).finally(() => {
                dispatch(hideSiteLoader())
            })
        }
    }, [termsAndConditionModal])

    useEffect(() => {
        const currentDiv = divRef.current;

        if (currentDiv) {
            currentDiv.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (currentDiv) {
                currentDiv.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);




    return (
        <GlobalModal
            show={termsAndConditionModal}
            enableFooter={true}
            center={true}
            onHide={handleClose}
            titleClass="modal-title"
            headerClass="modal-header"
            footerClass='modal-footer'
            contentClass="commonModal creditcardModal termsModal"
            title={
                <>
                    Confirm Agreement
                </>
            }
            footRender={
                <>
                    <div className={`form-group custom-checkbox privacy position-relative ${initialDisabled ? "a-custom" : ""}`}>
                        <input name="accepted" disabled={initialDisabled} className="form-control" type="checkbox" id="accepted" checked={termsAccepted} onChange={(e) => {
                            dispatch(handleTermsAccepted(e.target.checked));
                        }} />

                        <label
                            htmlFor="accepted"
                            className="custom-label"
                        >
                            I confirm that I have read, understand, and agree to be bound by the Energy Domain Data Subscription Services Agreement, <Link to={"/privacy-policy"} target={"_blank"}>Privacy Policy</Link>, and any other applicable agreements.
                        </label>
                    </div>
                    <Button variant={`btn ${termsAccepted ? "btn-primary" : "btn-disabled"}`} disabled={!termsAccepted} onClick={continueBtnClick}>
                        Agree
                    </Button>
                </>
            }
        >
            <form className="form-block">
                <div ref={divRef} className="formScroll scrollSection">
                    {termsAndCondition !== null && termsAndCondition?.description ? parse(termsAndCondition?.description) : <></>}
                    <br></br>
                </div>
            </form>
        </GlobalModal>
    )
}

export default TermsAndConditionModal
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    LoadInviteMembersAction,
    MembersSettingsInitialValueType,
} from "../../models/redux-models";

const initialState: MembersSettingsInitialValueType = {
    inviteMembersData: null,
    unacceptedMemberInvitations: null,
    inviteMembersCount: 0,
    inviteMemberDataLoading: true,
    active_user_count: 0,
    invited_user: [],
};

const membersSettings = createSlice({
    name: "membersSettings",
    initialState: initialState,
    reducers: {
        loadInviteMembers(
            state,
            action: PayloadAction<LoadInviteMembersAction>
        ) {
            return {
                ...state,
                inviteMemberDataLoading: false,
                inviteMembersData: action.payload.data,
                unacceptedMemberInvitations: action.payload.unacceptedMemberInvitations,
                inviteMembersCount: action.payload.result_count,
                active_user_count: action.payload.active_user_count,
                invited_user: action.payload.invited_user,
            };
        },
        removeInvitedUser(state, action: PayloadAction<{ id: number }>) {
            return {
                ...state,
                invited_user: state.invited_user.filter(
                    (item) => item.id !== action.payload.id
                ),
            };
        },
        clearInviteMembers(state, action: PayloadAction) {
            return {
                ...state,
                inviteMemberDataLoading: true,
                inviteMembersData: null,
                inviteMembersCount: 0,
                active_user_count: 0,
                invited_user: [],
            };
        },
    },
});

export default membersSettings;

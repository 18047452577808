import React, { useMemo } from "react";
import { useAppSelector } from "../../hooks/redux-hooks";
import { USDollar, calculateDiscount, formatUSDCurrency, } from "../../../utils/helper";

function TotalSubtotalBillNow({
    isEdit,
    newItemTotalInEditCase,
    newItemTotalTaxInEditCase,
    totalForInvoiceOrCart,
    discountForPromoCodeCoupon,
    taxesForInvoiceOrCart,
    subtotalForInvoiceOrCart,
    discountForInvoiceOrCart,
    promoCodeDiscountAlreadyApplied
}: {
    isEdit?: boolean;
    newItemTotalInEditCase: number;
    newItemTotalTaxInEditCase: number;
    totalForInvoiceOrCart: string | undefined;
    discountForPromoCodeCoupon: number;
    taxesForInvoiceOrCart: string;
    subtotalForInvoiceOrCart: string;
    discountForInvoiceOrCart: string;
    promoCodeDiscountAlreadyApplied: boolean;
}) {

    const is_valid = useAppSelector(state => state.cartSelectBasinCounty.promoCodeDetails.is_valid);
    const cartItemsTotalPerMonth = useAppSelector(state => state.cartSelectBasinCounty.cartItemsTotalPerMonth);
    const cartItemsTotalPerYear = useAppSelector(state => state.cartSelectBasinCounty.cartItemsTotalPerYear);
    const cartItemsTotalTax = useAppSelector(state => state.cartSelectBasinCounty.cartItemsTotalTax);
    const yearly = useAppSelector(state => state.cartSelectBasinCounty.yearly);
    const yearlyDiscountPercent = useAppSelector(state => state.cartSelectBasinCounty.yearlyDiscountPercent);

    const yearlyDiscountFraction = useMemo(() => {
        return (100 - yearlyDiscountPercent) / 100
    }, [yearlyDiscountPercent])



    return (
        <>
            <ul className="subtotal">
                <li>
                    <label>Subtotal </label>
                    <span>
                        {subtotalForInvoiceOrCart}
                    </span>
                    {isEdit && (
                        <span>
                            {USDollar.format(
                                Number((yearly ? newItemTotalInEditCase / yearlyDiscountFraction : newItemTotalInEditCase).toFixed(2))
                            )}
                        </span>
                    )}
                </li>
                <li className={`${yearly ? "" : "d-none"}`}>
                    <label>Discount</label>
                    <span className="red">{discountForInvoiceOrCart}</span>
                </li>
                <li className={`${is_valid === "Valid" && !promoCodeDiscountAlreadyApplied ? "" : "d-none"}`}>
                    <label>Promotion/Coupon discount</label>
                    <span className="red">{formatUSDCurrency(discountForPromoCodeCoupon)}</span>
                </li>
                <li>
                    <label>Taxes </label>
                    <span className='no-wrap'>
                        {taxesForInvoiceOrCart}
                    </span>
                    {isEdit && (
                        <span>
                            {newItemTotalTaxInEditCase
                                ? USDollar.format(
                                    newItemTotalTaxInEditCase
                                )
                                : USDollar.format(0)}
                        </span>
                    )}
                </li>
                {isEdit && (
                    <li className="monthly">
                        <label>
                            {`${yearly ? "Annually" : "Monthly"}`} Recurring Total{" "}
                        </label>
                        <span>
                            {USDollar.format(
                                cartItemsTotalTax - discountForPromoCodeCoupon +
                                (yearly ? cartItemsTotalPerYear : cartItemsTotalPerMonth)
                            )}
                        </span>
                        <span>&nbsp;</span>
                    </li>
                )}
            </ul>
            <div className="total">
                <label>
                    {isEdit
                        ? "Total Due Today"
                        : "Billed now:"}
                </label>
                <span>
                    {isEdit
                        ? USDollar.format(
                            newItemTotalTaxInEditCase +
                            newItemTotalInEditCase
                        )
                        : totalForInvoiceOrCart
                    }
                </span>
            </div>

        </>

    );
}

export default TotalSubtotalBillNow;

import React from 'react';
import { toast } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';
import { logout } from '../components/store/actions/auth-actions';
import { useAppDispatch } from '../components/hooks/redux-hooks';

interface Props {
    children: React.ReactNode;
    onAuthError: () => void; // Callback to handle auth errors (e.g., logout)
}

const ErrorFallback: React.FC<{ error: Error; resetErrorBoundary: () => void }> = ({
    error,
    resetErrorBoundary,
}) => {
    return (
        <div className="auth-error-container">
            <h2>Session Error</h2>
            <p>Please refresh the page or log in again.</p>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    );
};

const isAuthError = (error: Error): boolean => {
    return (
        error.message.includes('unauthorized') ||
        error.message.includes('token') ||
        error.message.includes('401')
    );
};

export const AuthErrorBoundary: React.FC<Props> = ({ children, onAuthError }) => {
    const dispatch = useAppDispatch();

    const handleError = (error: Error) => {
        console.error('Auth Error Boundary caught an error:', error);

        if (isAuthError(error)) {
            toast.error('Your session has expired. Please log in again.');
            dispatch(logout());
            onAuthError();
        }
    };

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={handleError}
            onReset={() => {
                window.location.reload();
            }}
        >
            {children}
        </ErrorBoundary>
    );
};

export default AuthErrorBoundary;
import { WellsAndPermitsObject } from "../models/redux-models";
import * as Sentry from "@sentry/react";

export const getSelectedWell = (wellsDataList: WellsAndPermitsObject[] | null, selectedRowId: string | number) => {
    if (!wellsDataList) {
        return null
    }
    const filteredList = wellsDataList.filter((well: WellsAndPermitsObject) => well.id == selectedRowId)
    if (filteredList.length === 0) {
        return null
    }
    return filteredList[0]

}
export const addHours = (date: Date) => {
    const hoursToAdd = 12 * 60 * 60 * 1000;
    date.setTime(date.getTime() + hoursToAdd);
    return date;
}
export const formatDateMMDDYYYY = (stringDate: string) => {
    if (!stringDate) {
        return ''
    }
    try {
        const jsDate = addHours(new Date(stringDate))
        return `${jsDate.getMonth() + 1}/${jsDate.getDate()}/${jsDate.getFullYear()}`
    } catch (err) {
        Sentry.captureException(err)
        return stringDate
    }

}

export const addCommasToNumber = (number: string | number | null | undefined) => {
    if (!number) {
        return ''
    }
    try {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (err) {
        Sentry.captureException(err)
        return ''
    }
}

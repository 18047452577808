import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import HomeView from "../../components/home/HomeView";
import { HomeProps } from "../../components/models/page-props";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Home: React.FC<HomeProps> = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (sessionStorage.getItem("accountRemoveOrDeactivateMsg")) {
            toast.success(
                sessionStorage.getItem("accountRemoveOrDeactivateMsg")
            );
            sessionStorage.removeItem("accountRemoveOrDeactivateMsg");
        }
        if (sessionStorage.getItem("passwordUpdateMsg")) {
            navigate("/login");
            toast.success(sessionStorage.getItem("passwordUpdateMsg"));
            sessionStorage.removeItem("passwordUpdateMsg");
        }
    }, [navigate]);

    return (
        <React.Fragment>
            <Helmet>
                <title>Energy Domain Data</title>
            </Helmet>
            <HomeView />
        </React.Fragment>
    );
};

export default Home;

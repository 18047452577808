import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { sideNavProps } from "../models/page-props";
import {
    actionType,
    alertsPathname,
    cartSelectBasin,
    navBarMenu,
    saveFiltersPathname,
} from "../../utils/helper";
import { NavLink } from "react-router-dom";
import SideBar from "./SideBar";
import SettingsComponent from "../cartSelectBasin/SettingsComponent";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { toast } from "react-toastify";
import { showCheckOutModal } from "../store/actions/modal-actions";
import { toggleViewAnalytics } from "../store/actions/wells-rigs-action";
import { toggleLeftTab } from "../store/actions/cart-basin-to-county-actions";
import { supportSectionItems } from "../../Constants/SupportSectionItems";
import { Tooltip } from 'react-tooltip';
import Intercom from '@intercom/messenger-js-sdk';
import moment from "moment";
import { handleUnReadAlertCount, logUserAction } from "../store/actions/auth-actions";
import { handleSelectedWellRigOrPermitList } from "../store/actions/wells-rigs-action";

const SideNav = ({ hideSideBar }: sideNavProps) => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const cartListItems = useAppSelector(state => state.cartSelectBasinCounty.cartListItems);
    const leftTab = useAppSelector(state => state.cartBasinToCounty.leftTab);
    const viewAnalytics = useAppSelector(state => state.wellsAndRigs.viewAnalytics);
    const rapidAPIFromInput = useAppSelector(state => state.wellsAndRigs.rapidAPIFromInput);
    const unread_count = useAppSelector(state => state.alerts.alertMsg.unread_count);
    const user_id = useAppSelector(state => state.auth.user.user_id);
    const user_hash = useAppSelector(state => state.auth.user.user_hash);
    const date_joined = useAppSelector(state => state.auth.user.date_joined);
    const first_name = useAppSelector(state => state.auth.user.first_name);
    const last_name = useAppSelector(state => state.auth.user.last_name);
    const email = useAppSelector(state => state.auth.user.email);
    const renewal_date = useAppSelector(state => state.auth.user.renewal_date);
    const subscription_status = useAppSelector(state => state.auth.user.subscription_status);
    const trial_remaining_days = useAppSelector(state => state.auth.user.trial_remaining_days);
    const unread_alert = useAppSelector(state => state.auth.user.unread_alert);
    const profile_pic = useAppSelector(state => state.auth.user.profile_pic);
    const free_trial_period_enabled = useAppSelector(state => state.auth.user.company_configs.free_trial_period_enabled);
    const trial_expired = useAppSelector(state => state.auth.user.company_configs.trial_expired);
    const is_trial_never_end = useAppSelector(state => state.auth.user.company_configs.is_trial_never_end);
    const sync_with_stripe = useAppSelector(state => state.auth.user.company_configs.sync_with_stripe)

    useEffect(() => {
        const initializeIntercomMessengerForLoggedInUser = () => {
            if (user_id && user_hash) {
                let date_joined_in_unix_timestamp_secs = Math.round((new Date(date_joined)).getTime() / 1000);
                Intercom({
                    app_id: `${import.meta.env.VITE_INTERCOM_WORKSPACE_ID}`,
                    user_id: user_id.toString(),
                    name: first_name + " " + last_name,
                    email: email,
                    created_at: date_joined_in_unix_timestamp_secs,
                    user_hash: user_hash,
                    custom_launcher_selector: '#intercom_messenger_link',
                    hide_default_launcher: true
                });
            }
        };

        initializeIntercomMessengerForLoggedInUser();
    }, [user_id]);

    const currentDate = moment().toISOString();
    const isDateCrossed = renewal_date ? moment(renewal_date).isBefore(currentDate) : false;

    return (
        <>
            {free_trial_period_enabled && !trial_expired && location.pathname === cartSelectBasin && subscription_status !== "active" ? <></> : <>
                <div className="cart-menu">
                    {/* <div className="beta">
                        <img src="images/beta.svg" alt="" />
                    </div> */}
                    <div className="logo-icon">
                        <Link
                            to={(free_trial_period_enabled && trial_expired) ? cartSelectBasin : "/"}
                            className="side-menu-logo" onClick={(e) => {
                                if (free_trial_period_enabled && trial_expired) {
                                    e.preventDefault()
                                    cartListItems.length && dispatch(showCheckOutModal());
                                    toast.info("Please subscribe to plan.")
                                }
                            }}>
                            <img
                                className="ed-logo-icon"
                                src="images/ed-logo-icon.svg"
                                alt="Logo"
                            />
                            <img
                                className="ed-logo-text"
                                src="images/ed-logo-text.svg"
                                alt="Logo"
                            />
                        </Link>
                    </div>
                    <ul className={`navbar-menu scrollSection ${free_trial_period_enabled && !is_trial_never_end && trial_remaining_days !== null ? "freeTrialNav" : ""}`}>
                        {navBarMenu.map((item, index) => {
                            return (
                                <React.Fragment key={index + item.label}>
                                    <li id={item.label + '-tooltip-anchor'}
                                        className={`${index === 2 && (unread_count || unread_alert) ? 'unread' : ''} ${(subscription_status === "in-active" && !isDateCrossed && !is_trial_never_end) ? "disabled" : ""}`}>
                                        <NavLink

                                            style={{
                                                pointerEvents: (subscription_status === "in-active" && !isDateCrossed && !is_trial_never_end) ? 'none' : 'auto',
                                            }}
                                            onClick={(e) => {
                                                if (free_trial_period_enabled && trial_expired) {
                                                    e.preventDefault()
                                                    cartListItems.length && dispatch(showCheckOutModal());
                                                    toast.info("Please subscribe to plan.")
                                                }
                                                if (item.pathname === alertsPathname && unread_alert) {
                                                    dispatch(handleUnReadAlertCount(0))
                                                    !rapidAPIFromInput && dispatch(handleSelectedWellRigOrPermitList([]))
                                                }
                                                dispatch(
                                                    logUserAction({
                                                        action_type: actionType["page_view"],
                                                        action_log_detail: `page: ${item.pathname}`,
                                                    })
                                                );
                                                if (location.pathname !== saveFiltersPathname) {
                                                    if (!leftTab) {
                                                        viewAnalytics && dispatch(toggleViewAnalytics());
                                                        dispatch(toggleLeftTab(true))
                                                    } else {
                                                        dispatch(toggleLeftTab(false));
                                                    }
                                                }
                                            }}
                                            to={free_trial_period_enabled && trial_expired ? "" : item.pathname}
                                            className={({
                                                isActive,
                                                isPending
                                            }) =>
                                                isActive && !trial_expired ? "active" : ""
                                            }>
                                            <i className={item.fontAwesomeClass} />
                                        </NavLink>
                                    </li>

                                    <Tooltip
                                        place="right"
                                        anchorSelect={'#' + item.label + '-tooltip-anchor'}
                                        content={item.label}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </ul>
                    <div
                        className={`bottomNav ${location.pathname === cartSelectBasin ? "forProfile" : ""}`}>
                        <ul className="navbar-menu">
                            {(free_trial_period_enabled || (free_trial_period_enabled && trial_expired && (subscription_status !== "active"))) && !is_trial_never_end && trial_remaining_days !== null
                                ?
                                <div className="subscribe">
                                    <p><span
                                        className="number">{trial_remaining_days || 0}</span> days <span
                                            className="text">left in your Trial</span></p>
                                    <Link to={"/subscription"} type="button"
                                        className="btn btn-primary">
                                        <img src="images/cart-icon.svg" alt="Subscription" />
                                        <span>Subscribe</span>
                                    </Link>
                                </div>
                                :
                                <></>
                            }
                            {
                                free_trial_period_enabled && !trial_expired
                                    ?
                                    <></>
                                    :
                                    <>
                                        <li id={'subscriptions-tooltip-anchor'}
                                            className={`subscriptionLi borderNone ${!sync_with_stripe ? 'disabled' : ''}`}>
                                            <Link
                                                to={sync_with_stripe ? "/subscription" : "#"}
                                                onClick={(e) => {
                                                    if (!sync_with_stripe) {
                                                        e.preventDefault();
                                                        toast.info("This feature is not available for your account. Please contact support for assistance.");
                                                    }
                                                }}
                                                className={!sync_with_stripe ? 'disabled-link' : ''}
                                            >
                                                <i className="icon subscription" />
                                            </Link>
                                        </li>
                                        <Tooltip
                                            place="right"
                                            anchorSelect={'#subscriptions-tooltip-anchor'}
                                            content={"Subscriptions"}
                                        />
                                    </>
                            }

                            <li id="settings-popup">
                                <a className={"settingbtn"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}>
                                    {location.pathname === cartSelectBasin
                                        ?
                                        <span className="avatar">
                                            <img src={
                                                profile_pic
                                                    ? `${import.meta.env.VITE_ED_DATA_CDN_API}/profile_pic/${profile_pic}`
                                                    : "images/profile-pic.png"
                                            } alt="profile-pic" />
                                        </span>
                                        :
                                        <i className="icon settings" />
                                    }
                                </a>
                            </li>
                            <Tooltip place="right-start"
                                anchorSelect="#settings-popup" clickable
                                className={"settings-popup"}>
                                <SettingsComponent />
                            </Tooltip>

                            <li id={'support-popup'}>
                                <a onClick={(e) => {
                                    e.preventDefault();
                                }}>
                                    <i className="icon support" />
                                </a>
                            </li>
                            <Tooltip place="right"
                                anchorSelect="#support-popup" clickable
                                className={"support-popup"}>
                                {supportSectionItems.map((item, index) => {
                                    return (
                                        <React.Fragment key={index + item.label}>
                                            {item.name === 'chat' ?
                                                <a
                                                    id={'intercom_messenger_link'}
                                                    className={[1, 4].includes(index) ? "bottom-border" : ""}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                    }}>
                                                    <i className={item.iconClass} />
                                                    <span>{item.label}</span>
                                                </a>
                                                :
                                                <Link to={item.link}
                                                    onClick={() => {
                                                        dispatch(
                                                            logUserAction({
                                                                action_type: actionType[item.name as keyof typeof actionType],
                                                                action_log_detail: `user_id: ${user_id}`,
                                                            })
                                                        );
                                                    }}
                                                    target={"_blank"}
                                                    className={[1, 4].includes(index) ? "bottom-border" : ""}>
                                                    <i className={item.iconClass} />
                                                    <span>{item.label}</span>
                                                </Link>}
                                        </React.Fragment>

                                    )
                                })}
                            </Tooltip>
                        </ul>
                    </div>
                </div>

                {!hideSideBar && <SideBar />}
            </>
            }
        </>
    );
};

export default SideNav;

import React, { useEffect, useRef, useState } from "react";
import { BASINS, COUNTIES } from "./redux/types";

import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import esriConfig from "@arcgis/core/config";
import Color from "@arcgis/core/Color.js";
import config from "@arcgis/core/config";

import { APIKey, AssetPath, IBaseMapConfig, BaseMapConfig } from "./config/BaseMapConfig";
import LayerIds from "./config/layers/LayerIds";

import { SET_BASINS, SET_COUNTIES, setSelectedType } from "./redux/locations";
import { INIT_MAP } from "./redux/esri";
import {
    fetchSubscriptionData,
} from "../store/actions/subscription-settings-actions";

import "../../App.css";
import "../map/css/map.scss";

import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { getBasinCountyDetails } from "../store/actions/cart-select-basin-county-actions";
import { BasinDetailsObject, CountyDetailsObject } from "../models/redux-models";
import { showSiteLoader, hideSiteLoader } from "../store/actions/modal-actions";
import Zoom from "@arcgis/core/widgets/Zoom";
import { debounce, result, throttle } from "lodash";
import { queryLayers, utilityCartCheck } from "./utility/mapUtilities";
import { errToast } from "../../utils/helper";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/react";

esriConfig.apiKey = APIKey;
esriConfig.assetsPath = AssetPath;
let webmap: WebMap;
let mapview: MapView;
let zoom: Zoom;
let countyLayerView: __esri.FeatureLayerView;
let basinLayerView: __esri.FeatureLayerView;
const debounceTime = 15;

interface IHighlight {
    attributes: any,
    remove: any
}

interface ISubscriptionMapData {
    type: number,
    name: string,
    state: string
}

interface IMapProps {
    allowSelection: boolean;
    disableNavigation?: boolean;
    zoomType?: "hidden" | "default" | "large";
    customExtent?: __esri.Extent;
    disablePopup?: boolean;
    disablePopupPointerEvents?: boolean;
    defaultSelectedLayerType?: typeof BASINS | typeof COUNTIES;
    qualityMode?: "low" | "medium" | "high";
}

function Map(props: IMapProps) {
    const { allowSelection, qualityMode = "medium" } = props;
    const dispatch = useAppDispatch();
    const mapDiv = useRef(null);
    const [config, setConfig] = useState({});
    const [mapSet, setMapSet] = useState(false);
    const [basinLayerLoaded, setBasinLayerLoaded] = useState(false);
    const [countyLayerLoaded, setCountyLayerLoaded] = useState(false);
    const [layersLoaded, setLayersLoaded] = useState(false);
    const [screenPoint, setScreenPoint] = useState<any>({});
    const [currentEvent, setCurrentEvent] = useState({});
    const prevScreenPoint = useRef({});
    const [highlights, setHighlights] = useState<IHighlight[]>([])
    const [currentSubscriptions, setCurrentSubscriptions] = useState<ISubscriptionMapData[]>([]);
    const [subscriptionHighlights, setSubscriptionHighlights] = useState<IHighlight[]>([]);
    const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    const {
        basins, counties, selectedType
    } = useAppSelector((state) => state.locations);

    const {
        auth: {
            user: { access_token,
                company_configs: {
                    free_trial_period_enabled,
                    trial_expired
                }
            },
        },
        cartSelectBasinCounty: {
            cartListItems,
            yearly,
            yearlyDiscountPercent
        },
        subscriptionSettings: { loadSubscriptionData, subscriptionData },
    } = useAppSelector((state) => state);

    useEffect(() => {
        if (!subscriptionData) return;
        const cartListItemNames = cartListItems
            .map((cartItem) => 'basin_name' in cartItem ? cartItem.basin_name : '')
            .filter(name => name !== ''); // Remove blank strings

        const currentSubscriptionNames = subscriptionData.details.map((item) => item.line_item_name);

        // Find subscriptions that are not in cart
        const subscriptionsNotInCart = currentSubscriptionNames.filter(
            subName => !cartListItemNames.includes(subName)
        );
        if (free_trial_period_enabled && !trial_expired && subscriptionsNotInCart.length > 0) {
            let tmpSubs: ISubscriptionMapData[] = [];
            const { details } = subscriptionData;
            details.forEach((item) => {
                if (cartListItemNames.includes(item.line_item_name)) {
                    tmpSubs.push({ type: item.line_item_type, name: item.line_item_name, state: item.state_code });
                }
            });
            // Update the subsriptions on the map if you're in a free trial
            // but have removed your subscribed basin from the cart. 
            setCurrentSubscriptions(tmpSubs);
        }
    }, [cartListItems, subscriptionData])

    useEffect(() => {
        if (mapDiv.current) {
            /**
             * Initialize config
             */
            setConfig(new BaseMapConfig());
            if (props.defaultSelectedLayerType === COUNTIES) {
                dispatch(setSelectedType(COUNTIES));
            } else {
                dispatch(setSelectedType(BASINS));
            }
        }
    }, []);

    useEffect(() => {
        if (props.disablePopupPointerEvents && mapDiv.current) {
            //@ts-ignore
            mapDiv.current.classList.add('hide-popup-pointer-events');
        }
    }, [props.disablePopupPointerEvents]);

    useEffect(() => {
        if (access_token && loadSubscriptionData) {
            dispatch(fetchSubscriptionData());
        }
    }, [access_token, loadSubscriptionData])

    useEffect(() => {
        if (!subscriptionData) return;
        let tmpSubs: ISubscriptionMapData[] = [];
        const { details } = subscriptionData;
        details.forEach((item) => {
            tmpSubs.push({ type: item.line_item_type, name: item.line_item_name, state: item.state_code });
        });
        setCurrentSubscriptions(tmpSubs);
    }, [subscriptionData])

    useEffect(() => {
        if (basins.length === 0 && basinLayerView) {
            const newHighlights: IHighlight[] = [];
            highlights.forEach((h) => {
                if (!utilityCartCheck(h, cartListItems) && h.attributes.basin_name) {
                    h.remove.remove()
                } else {
                    newHighlights.push(h);
                }
            });
            setHighlights(newHighlights);
        }
    }, [basins]);

    useEffect(() => {
        if (counties.length === 0 && countyLayerView) {
            const newHighlights: IHighlight[] = [];
            highlights.forEach((h) => {
                if (!utilityCartCheck(h, cartListItems) && h.attributes.county_name) {
                    h.remove.remove()
                } else {
                    newHighlights.push(h);
                }
            });
            setHighlights(newHighlights);
        }
    }, [counties]);

    useEffect(() => {
        if (layersLoaded) {
            switch (selectedType) {
                case BASINS:
                    mapview.layerViews.forEach((lv: { layer: { title: string; }; visible: boolean; }) => {
                        lv.layer.title === LayerIds.BasinStats ? lv.visible = true : lv.visible = false;
                    })
                    break;
                case COUNTIES:
                    mapview.layerViews.forEach((lv: { layer: { title: string; }; visible: boolean; }) => {
                        lv.layer.title === LayerIds.CountyStats ? lv.visible = true : lv.visible = false;
                    })
                    break;
            }
        }
    }, [selectedType, layersLoaded]);

    useEffect(() => {
        if (basinLayerLoaded && countyLayerLoaded) {
            setLayersLoaded(true);
        }
    }, [basinLayerLoaded, countyLayerLoaded]);

    useEffect(() => {
        //@ts-ignore
        if (config !== null && config.hasOwnProperty("mapConfig") && !mapSet && !loadSubscriptionData) {
            setMapSet(true);
            //@ts-ignore
            const { mapConfig } = config;

            // Configure ArcGIS for better performance
            esriConfig.request.timeout = 60000; // Increase timeout for large requests

            // Set quality settings for high-resolution displays
            if (window.devicePixelRatio > 1.5) {
                // Use snapshot quality settings if available in your ArcGIS version
                // If not available, this won't cause errors
                try {
                    // @ts-ignore
                    esriConfig.quality = {
                        snapshot: {
                            quality: 0.7 // Lower quality for snapshots (0-1)
                        },
                        improvement: "performance" // Prioritize performance over quality
                    };
                } catch (e) {
                    errToast(e as AxiosError);
                    Sentry.captureException(e);
                }
            }

            webmap = new WebMap({
                portalItem: {
                    id: mapConfig.webMapId
                }
            });

            mapview = new MapView({
                container: mapDiv.current == null ? undefined : mapDiv.current,
                map: webmap,
                extent: props.customExtent ?? undefined,
                ui: { components: ["attribution"] },
                constraints: {
                    snapToZoom: props.customExtent ? false : true
                },
                // Add performance optimization settings - using properties supported by the API
                highlightOptions: {
                    haloOpacity: 0.5, // Lower opacity for better performance
                    fillOpacity: 0.3
                },
                // Optimize for performance
                navigation: {
                    mouseWheelZoomEnabled: true,
                    browserTouchPanEnabled: true
                }
            });

            // Apply additional performance optimizations
            // @ts-ignore - Apply performance settings that may not be in the type definitions
            if (mapview.environment) {
                // @ts-ignore
                mapview.environment.atmosphere.quality = "low";
            }

            // Disable unnecessary visual effects
            // @ts-ignore
            if (mapview.animation) {
                // @ts-ignore
                mapview.animation.enabled = false;
            }

            // Set viewpoint update interval after creation
            // This is a property that exists on the instance but not in the constructor type
            if (window.devicePixelRatio > 1.5) {
                // @ts-ignore - viewpointUpdateInterval exists but TypeScript doesn't recognize it
                mapview.viewpointUpdateInterval = 150;
            }

            if (props.zoomType !== "hidden") {
                zoom = new Zoom({ view: mapview, visible: false });
                mapview.ui.add(zoom, "top-right");
            }

            const updatePopupStyles = () => {

                const contentFeatureElement = document.querySelector(
                    ".esri-features__content-feature"
                );

                if (!contentFeatureElement || !contentFeatureElement.shadowRoot) {
                    setTimeout(() => updatePopupStyles(), 50);
                    return;
                }



                const headerElement = contentFeatureElement.shadowRoot
                    .querySelector("calcite-panel")
                    ?.shadowRoot?.querySelector("div.header-container");

                if (!headerElement) {
                    setTimeout(() => updatePopupStyles(), 50);
                    return;
                }

                const headerH2 = contentFeatureElement.querySelector("h2") as HTMLElement;

                if (!headerH2) {
                    setTimeout(() => updatePopupStyles(), 50);
                    return;
                }

                headerH2.style.padding = "3px 10px";

                const headerActions = headerElement.querySelectorAll(".header-actions");

                headerActions.forEach((_action) => {
                    const action = _action as HTMLElement;
                    action.style.display = "none";
                });

                const contentContainer = contentFeatureElement.querySelector(".esri-features__container") as HTMLElement;
                contentContainer.style.display = "none";

                //The action bar (immediately beneath the title bar)
                const calciteActionBar =
                    contentFeatureElement.querySelector("calcite-action-bar") as HTMLElement;

                if (!calciteActionBar) {
                    setTimeout(() => updatePopupStyles(), 50);
                    return;
                }

                calciteActionBar.style.display = "none";

                setTimeout(() => { mapview.popup.reposition() }, 200);
            };

            // Optimize event handlers with increased debounce time for high-res displays
            const optimizedDebounceTime = window.devicePixelRatio > 1.5 ? debounceTime * 2 : debounceTime;

            mapview.on("layerview-create", function (event: any) {
                if (event.layer.title === LayerIds.BasinStats) {
                    basinLayerView = event.layerView as __esri.FeatureLayerView;
                    basinLayerView.highlightOptions = {
                        color: new Color("#16A15E"),
                        haloOpacity: 0.7, // Reduced from 0.9
                        fillOpacity: 0.4  // Reduced from 0.6
                    };
                    //@ts-ignore
                    //event.layer.popupTemplate = cartPopupTemplate;
                    setBasinLayerLoaded(true);
                    if (props.disablePopup) {
                        basinLayerView.layer.popupEnabled = false;
                        return;
                    }

                    const debouncedPointerMove = debounce((move_event: any) => {
                        if (mapview.layerViews.find((lv) => lv.layer.title === LayerIds.BasinStats)?.visible) {
                            mapview.hitTest(move_event, { include: basinLayerView.layer }).then((r) => {
                                if (r.results.length === 0) {
                                    //@ts-ignore
                                    if (mapDiv.current) { mapDiv.current.style.cursor = "default"; }
                                } else {
                                    //@ts-ignore
                                    if (mapDiv.current) { mapDiv.current.style.cursor = "pointer"; }
                                }
                                showPopup(r, 'basin');
                                updatePopupStyles();
                                return;
                            });
                        }
                    }, optimizedDebounceTime);

                    // Use event listeners with optimized debounce
                    mapview.on('pointer-move', debouncedPointerMove);
                }
                if (event.layer.title === LayerIds.CountyStats) {
                    countyLayerView = event.layerView as __esri.FeatureLayerView;
                    countyLayerView.highlightOptions = {
                        color: new Color("#16A15E"),
                        haloOpacity: 0.7, // Reduced from 0.9
                        fillOpacity: 0.4  // Reduced from 0.6
                    };
                    //@ts-ignore
                    //event.layer.popupTemplate = cartPopupTemplate;
                    setCountyLayerLoaded(true);
                    if (props.disablePopup) {
                        countyLayerView.layer.popupEnabled = false;
                        return;
                    }

                    const debouncedPointerMove = debounce((move_event: any) => {
                        if (mapview.layerViews.find((lv) => lv.layer.title === LayerIds.CountyStats)?.visible) {
                            mapview.hitTest(move_event, { include: countyLayerView.layer }).then((r) => {
                                if (r.results.length === 0) {
                                    //@ts-ignore
                                    if (mapDiv.current) { mapDiv.current.style.cursor = "default"; }
                                } else {
                                    //@ts-ignore
                                    if (mapDiv.current) { mapDiv.current.style.cursor = "pointer"; }
                                }
                                showPopup(r, 'county');
                                updatePopupStyles();
                                return;
                            });
                        }
                    }, optimizedDebounceTime);

                    // Use event listeners with optimized debounce
                    mapview.on('pointer-move', debouncedPointerMove);
                }
            });

            if (props.disableNavigation) {
                function stopEvtPropagation(e: any) {
                    e.stopPropagation();
                }
                mapview.on("mouse-wheel", stopEvtPropagation);
                mapview.on("double-click", stopEvtPropagation);
                mapview.on("double-click", ["Control"], stopEvtPropagation);
                mapview.on("drag", stopEvtPropagation);
                mapview.on("drag", ["Shift"], stopEvtPropagation);
                mapview.on("drag", ["Shift", "Control"], stopEvtPropagation);
                mapview.on("key-down", (e) => {
                    const prohibitedKeys = ["+", "-", "Shift", "_", "=", "ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft"];
                    const keyPressed = e.key;
                    if (prohibitedKeys.indexOf(keyPressed) !== -1) {
                        e.stopPropagation();
                    }
                });
            } else {
                mapview.on("click", (event: any) => {
                    setScreenPoint(event);
                });
            }

            webmap.when(() => {
                
                dispatch(INIT_MAP({ map: webmap, mapview }));

                // Optimize feature layers
                webmap.layers
                    .filter(layer => { return layer.type === 'feature' })
                    .map(layer => {
                        let featLayer: FeatureLayer = layer as FeatureLayer;
                        featLayer.outFields = ['*'];

                        // Optimize feature layer for performance
                        featLayer.maxScale = 0; // No maximum scale
                        featLayer.minScale = 0; // No minimum scale

                        // Reduce the level of detail for better performance
                        // @ts-ignore
                        if (featLayer.renderer) {
                            // @ts-ignore
                            featLayer.renderer.maxThreshold = 2000;
                        }

                        // Disable labeling for better performance
                        if (featLayer.labelingInfo) {
                            featLayer.labelsVisible = false;
                        }

                        // Set feature reduction for better performance on high-res displays
                        if (window.devicePixelRatio > 1.5 || screenSize.width > 2560) {
                            try {
                                // @ts-ignore - Feature reduction might not be available in all versions
                                featLayer.featureReduction = {
                                    type: "selection"
                                };
                            } catch (e) {
                                errToast(e as AxiosError);
                                Sentry.captureException(e);
                            }
                        }

                        return featLayer;
                    });
                if (zoom) {
                    zoom.visible = true;
                }

                const updateZoomStyles = () => {
                    const zoomButtons = document.querySelectorAll(".esri-zoom .esri-widget--button");
                    if (zoomButtons.length === 0) {
                        setTimeout(() => updateZoomStyles(), 50);
                        return;
                    }
                    zoomButtons.forEach((el) => {
                        el.classList.add("esri-widget--button-large");
                        el.shadowRoot
                            ?.querySelector("calcite-icon")
                            ?.setAttribute("scale", "m");
                    });
                }
                if (props.zoomType === "large") {
                    updateZoomStyles();
                }
            });
        }
    }, [config, loadSubscriptionData, screenSize]);

    useEffect(() => {
        if (screenPoint !== prevScreenPoint.current && mapSet)
            handleMapClick(screenPoint);
        prevScreenPoint.current = screenPoint;
    }, [screenPoint]);

    useEffect(() => {
        const tmpHighlights: IHighlight[] = [];
        let reset = false;
        //match highlights and remove items that have been removed from the cart
        highlights.forEach((item) => {
            if (!item.attributes.county_name) {
                const highlightIdx = cartListItems.findIndex((h) => {
                    const basinItem: BasinDetailsObject = h as BasinDetailsObject;
                    return basinItem.basin_name && basinItem.basin_name === item.attributes.basin_name;
                });
                if (highlightIdx === -1) {
                    item.remove.remove();
                    reset = true;
                } else {
                    tmpHighlights.push(item);
                }
            } else {
                const highlightIdx = cartListItems.findIndex((h) => {
                    const countyItem: CountyDetailsObject = h as CountyDetailsObject;
                    return countyItem.county_name && countyItem.county_name === item.attributes.county_name &&
                        countyItem.state_abbr && countyItem.state_abbr === item.attributes.state_abbr;
                });
                if (highlightIdx === -1) {
                    item.remove.remove();
                    reset = true;
                } else {
                    tmpHighlights.push(item);
                }
            }
        });
        const isNational = cartListItems.find((c: any) => c.line_item_type && c.line_item_type === 3);
        //query for and add items that have been added to the cart to highlight them
        if (isNational) {
            if (layersLoaded) {
                // Use type assertion to avoid type errors
                const blayer = webmap.layers.find((l: any) => l.title === LayerIds.BasinStats);
                const layer = webmap.layers.find((l: any) => l.title === LayerIds.CountyStats);
                if (layer && blayer) {
                    queryFeatures([blayer, layer], null, "1=1", tmpHighlights);
                }
            }
        } else {
            const addedBasinItems = cartListItems.filter((c: any) => {
                const highlightIdx = highlights.findIndex((h) => {
                    const basinItem: BasinDetailsObject = c as BasinDetailsObject;
                    return basinItem.basin_name && basinItem.basin_name === h.attributes.basin_name;
                });
                return highlightIdx === -1 && !c.county_name;
            })
            if (addedBasinItems.length > 0) {
                if (layersLoaded) {
                    const layer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.BasinStats);
                    if (layer) {
                        const clause = (addedBasinItems.map((ab: any) => ab.basin_name)).join("','");
                        queryFeatures([layer], null, "basin_name in ('" + clause + "')", tmpHighlights);
                        return;
                    }
                }
            }
            const addedCountyItems = cartListItems.filter((c: any) => {
                const highlightIdx = highlights.findIndex((h) => {
                    const CountyItem: CountyDetailsObject = c as CountyDetailsObject;
                    return h.attributes.county_name && CountyItem.county_name
                        && CountyItem.county_name === h.attributes.County_name
                        && CountyItem.state_abbr === h.attributes.State_abbr;
                });
                return highlightIdx === -1 && !c.basin_name;
            })
            if (addedCountyItems.length > 0) {
                if (layersLoaded) {
                    const layer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.CountyStats);
                    if (layer !== null) {
                        const countyClause = (addedCountyItems.map((ab: any) =>
                            "(county_name = '" + ab.county_name + "' AND state_abbr = '" + ab.state_abbr + "')")).join(" OR ");
                        // const states = (addedCountyItems.map((ab: any) => ab.state_abbr)).join("','");
                        queryFeatures([layer], null, countyClause, tmpHighlights);
                        return;
                    }
                }
            }
        }
        //Update highlights
        if (reset) {
            setHighlights(tmpHighlights);
        }
    }, [cartListItems, layersLoaded]);

    useEffect(() => {
        if (layersLoaded && currentSubscriptions && !loadSubscriptionData) {
            subscriptionHighlights.forEach((h) => h.remove());
            const national = currentSubscriptions.filter((cs) => cs.type === 3);
            const cartPopupTemplate = {
                actions: [],
                content: "",
                title: (event: any) => {
                    const price = event.graphic.attributes.price;
                    const discountedPrice = price * (1 - yearlyDiscountPercent / 100);
                    let monthlyPriceNum = Math.trunc(price * 100) / 100;
                    let yearlyPriceNum = Math.trunc(discountedPrice * 12 * 100) / 100;
                    let monthlyPrice = monthlyPriceNum.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    let yearlyPrice = yearlyPriceNum.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    let formatted_price = monthlyPrice + " / month";
                    if (yearly) {
                        formatted_price = yearlyPrice + " / year";
                    }

                    const subscribed = currentSubscriptions.find((cs) => (cs.name === event.graphic.attributes.basin_name) || (national && national.length > 0)) ?
                        "<br /><div className='subscribed'>Subscribed</div>" : "$" + formatted_price;
                    if (event.graphic.attributes.county_name) {
                        return "{county_name} - " + subscribed;
                    } else {
                        return "{basin_name} - " + subscribed;
                    }
                }
            }
            //@ts-ignore
            countyLayerView.layer.popupTemplate = cartPopupTemplate
            //@ts-ignore
            basinLayerView.layer.popupTemplate = cartPopupTemplate
            const query =
            {
                //query object
                where: "",
                returnGeometry: false,
                outFields: ["*"],
            }
            const basins = currentSubscriptions.filter((cs) => cs.type === 1);
            const bLayer: any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.BasinStats);
            const basinsClause = basins.length > 0 ? (basins.map((ab: ISubscriptionMapData) =>
                "basin_name = '" + ab.name + "'")).join(" OR ") : "1=0";
            query.where = (national && national.length > 0) ? "1=1" : basinsClause;
            dispatch(showSiteLoader());
            bLayer.queryFeatures(query)
                .then((featureSet: { features: any[]; }) => {
                    if (featureSet.features.length > 0) {
                        const tmpSubscriptionHighlights: any[] = [];
                        tmpSubscriptionHighlights.push(basinLayerView.highlight(featureSet.features));

                        const counties = currentSubscriptions.filter((cs) => cs.type === 2);
                        const countyClause = counties.length > 0
                            ? (counties.map((ab: ISubscriptionMapData) =>
                                "(county_name = '" + ab.name + "' AND state_abbr = '" + ab.state + "')")).join(" OR ")
                            : "1=0";
                        const cLayer: any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.CountyStats);

                        query.where = (national && national.length > 0) ? "1=1" : "(" + countyClause + ") OR (" + basinsClause + ")";
                        queryLayers([cLayer], null, query).then((results) => {
                            results.forEach((featureSet: { features: any[] }) => {
                                if (featureSet.features.length > 0) {
                                    tmpSubscriptionHighlights.push(countyLayerView.highlight(featureSet.features));
                                }
                                setSubscriptionHighlights(tmpSubscriptionHighlights);
                            })
                            dispatch(hideSiteLoader());
                        })
                            .catch((e: any) => {
                                Sentry.captureException(e);
                                setSubscriptionHighlights(tmpSubscriptionHighlights);
                                dispatch(hideSiteLoader());
                            });
                    }
                })
                .catch((e: any) => {
                    errToast(e as AxiosError);
                    Sentry.captureException(e);
                })
                .finally(() => {
                    dispatch(hideSiteLoader());
                });
        }
    }, [currentSubscriptions, layersLoaded, yearly])

    // Detect screen resolution and set appropriate quality settings
    useEffect(() => {
        const handleResize = () => {
            setScreenSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Apply quality settings based on screen resolution or user preference
    useEffect(() => {
        if (mapview) {
            // Determine if we're on a high-resolution display
            const isHighResolution = window.devicePixelRatio > 1.5 ||
                screenSize.width > 2560 ||
                screenSize.height > 1440;

            // Apply quality settings based on profile or auto-detection
            let qualitySetting = qualityMode;
            if (qualitySetting === "medium" && isHighResolution) {
                qualitySetting = "low";
            }

            // Apply quality settings
            switch (qualitySetting) {
                case "low":
                    // Lower quality for better performance
                    // Reduce update rate for smoother experience
                    // @ts-ignore
                    mapview.viewpointUpdateInterval = 150; // ms between view updates
                    mapview.constraints.snapToZoom = false;
                    mapview.constraints.rotationEnabled = false;

                    // Reduce rendering quality during interactions
                    setupInteractionOptimizations(mapview, true);
                    break;
                case "medium":
                    // @ts-ignore
                    mapview.viewpointUpdateInterval = 100;
                    mapview.constraints.snapToZoom = props.customExtent ? false : true;

                    // Apply moderate interaction optimizations
                    setupInteractionOptimizations(mapview, false);
                    break;
                case "high":
                    // @ts-ignore
                    mapview.viewpointUpdateInterval = 0; // Default (most responsive)
                    mapview.constraints.snapToZoom = props.customExtent ? false : true;
                    break;
            }
        }
    }, [mapview, qualityMode, screenSize, props.customExtent]);

    // Function to set up optimizations during map interactions
    const setupInteractionOptimizations = (view: MapView, aggressive: boolean) => {
        if (!view) return;

        // Track when the view is updating (zooming/panning)
        let updating = false;
        let interactionTimeout: any = null;

        // Use a simpler approach with standard events
        view.on("drag", () => {
            if (!updating) {
                updating = true;
                if (aggressive) {
                    toggleFeatureLayers(false);
                } else {
                    reduceLayerQuality(true);
                }
            }
        });

        // Use pointer-up instead of drag-end
        view.on("pointer-up", () => {
            if (updating) {
                updating = false;
                if (interactionTimeout) {
                    clearTimeout(interactionTimeout);
                }

                interactionTimeout = setTimeout(() => {
                    if (aggressive) {
                        toggleFeatureLayers(true);
                    } else {
                        reduceLayerQuality(false);
                    }
                }, 250);
            }
        });

        view.on("mouse-wheel", () => {
            if (!updating) {
                updating = true;
                if (aggressive) {
                    toggleFeatureLayers(false);
                } else {
                    reduceLayerQuality(true);
                }
            }

            if (interactionTimeout) {
                clearTimeout(interactionTimeout);
            }

            interactionTimeout = setTimeout(() => {
                updating = false;
                if (aggressive) {
                    toggleFeatureLayers(true);
                } else {
                    reduceLayerQuality(false);
                }
            }, 250);
        });

        // Enhanced throttling for rendering during navigation
        const originalRender = view.renderNow;
        // @ts-ignore
        if (originalRender) {
            // More aggressive throttling during interactions
            view.renderNow = throttle(originalRender.bind(view), aggressive ? 100 : 50);
        }

        // Add throttling for hitTest operations
        const originalHitTest = view.hitTest;
        // @ts-ignore
        if (originalHitTest) {
            // @ts-ignore
            view.hitTest = throttle(originalHitTest.bind(view), aggressive ? 150 : 80);
        }
    };

    // Toggle visibility of feature layers
    const toggleFeatureLayers = (visible: boolean) => {
        if (!webmap) return;

        webmap.layers.forEach(layer => {
            if (layer.type === "feature") {
                // Skip the main basin/county layers to keep them visible
                if (layer.title === LayerIds.BasinStats || layer.title === LayerIds.CountyStats) {
                    return;
                }

                // @ts-ignore
                layer.visible = visible;
            }
        });
    };

    // Reduce quality of layers during interaction
    const reduceLayerQuality = (reduce: boolean) => {
        if (!webmap) return;

        webmap.layers.forEach(layer => {
            if (layer.type === "feature") {
                const featureLayer = layer as FeatureLayer;

                if (reduce) {
                    // Store original settings if needed
                    // @ts-ignore
                    featureLayer._originalMaxScale = featureLayer.maxScale;
                    // @ts-ignore
                    featureLayer._originalMinScale = featureLayer.minScale;
                    // @ts-ignore
                    featureLayer._originalLabelsVisible = featureLayer.labelsVisible;

                    // Reduce detail during interaction
                    featureLayer.maxScale = 0;
                    featureLayer.minScale = 0;

                    // Disable labels during interaction for better performance
                    if (featureLayer.labelingInfo) {
                        featureLayer.labelsVisible = false;
                    }

                    // Simplify geometries during interaction
                    // @ts-ignore
                    if (featureLayer.renderer) {
                        // @ts-ignore
                        featureLayer._originalMaxThreshold = featureLayer.renderer.maxThreshold;
                        // @ts-ignore
                        featureLayer.renderer.maxThreshold = 0;
                    }

                    // Reduce feature reduction threshold during interaction
                    // @ts-ignore
                    if (featureLayer.featureReduction) {
                        // @ts-ignore
                        featureLayer._originalFeatureReduction = featureLayer.featureReduction;
                        // @ts-ignore
                        featureLayer.featureReduction = {
                            type: "selection",
                            threshold: 10 // More aggressive reduction during interaction
                        };
                    }
                } else {
                    // Restore original settings
                    // @ts-ignore
                    if (featureLayer._originalMaxScale !== undefined) {
                        // @ts-ignore
                        featureLayer.maxScale = featureLayer._originalMaxScale;
                    }
                    // @ts-ignore
                    if (featureLayer._originalMinScale !== undefined) {
                        // @ts-ignore
                        featureLayer.minScale = featureLayer._originalMinScale;
                    }
                    // @ts-ignore
                    if (featureLayer._originalLabelsVisible !== undefined) {
                        // @ts-ignore
                        featureLayer.labelsVisible = featureLayer._originalLabelsVisible;
                    }

                    // Restore renderer settings
                    // @ts-ignore
                    if (featureLayer.renderer && featureLayer._originalMaxThreshold !== undefined) {
                        // @ts-ignore
                        featureLayer.renderer.maxThreshold = featureLayer._originalMaxThreshold;
                    }

                    // Restore feature reduction settings
                    // @ts-ignore
                    if (featureLayer._originalFeatureReduction !== undefined) {
                        // @ts-ignore
                        featureLayer.featureReduction = featureLayer._originalFeatureReduction;
                    }
                }
            }
        });

        // Also adjust map view quality during interaction
        if (mapview) {
            // @ts-ignore - Adjust snapshot quality during interactions
            if (mapview.qualityProfile) {
                // @ts-ignore
                mapview.qualityProfile = reduce ? "low" : "high";
            }
        }
    };

    // Add this useEffect for cleanup
    useEffect(() => {
        // Cleanup function that runs when component unmounts
        return () => {
            // Clear all highlights to release memory
            if (highlights.length > 0) {
                highlights.forEach(highlight => {
                    if (highlight.remove) {
                        highlight.remove.remove();
                    }
                });
            }

            if (subscriptionHighlights.length > 0) {
                subscriptionHighlights.forEach(highlight => {
                    if (highlight.remove) {
                        highlight.remove.remove();
                    }
                });
            }

            // Destroy map view and web map to release resources
            if (mapview) {
                // Remove all event listeners
                mapview.allLayerViews.forEach(layerView => {
                    // @ts-ignore
                    if (layerView.destroy) {
                        // @ts-ignore
                        layerView.destroy();
                    }
                });

                // Destroy widgets
                if (zoom) {
                    zoom.destroy();
                }

                // Destroy the view
                mapview.container = null;
                mapview.destroy();
            }

            if (webmap) {
                // Destroy all layers
                webmap.layers.forEach(layer => {
                    // @ts-ignore
                    if (layer.destroy) {
                        // @ts-ignore
                        layer.destroy();
                    }
                });

                // Destroy the map
                webmap.destroy();
            }
        };
    }, []);

    if (config === null) {
        return <div className="mapDiv" ref={mapDiv}></div>;
    }

    const handleMapClick: any = (event: { preventDefault: () => void; }) => {
        if (allowSelection) {
            const compareType = selectedType === BASINS ? LayerIds.BasinStats : LayerIds.CountyStats;
            // Use type assertion to avoid type errors
            const layer = webmap.layers.find((l: any) => l.title === compareType);
            queryFeatures([layer], event, "", [...highlights]);
        }
        event.preventDefault();
    }

    const showPopup: any = (r: any, popupType: string) => {
        if (r.results.length > 0) {
            const graphicHits = r.results?.filter(
                (hitResult: any) => hitResult.type === "graphic"
            );

            if (graphicHits.length === 0) {
                if (mapview.popup) {
                    mapview.popup.visible = false;
                }
                return;
            }

            const graphic = graphicHits[0].graphic;

            if (!mapview.popup?.features ||
                mapview.popup.features.length === 0 ||
                mapview.popup.features[0].attributes.OBJECTID !== graphic.attributes.OBJECTID) {

                mapview.openPopup({
                    location: graphic.geometry.centroid ? graphic.geometry.centroid : r.results[0].mapPoint,
                    features: [graphic]
                });
            } else if (mapview.popup) {
                mapview.popup.visible = true;
            }
        } else if (mapview.popup) {
            mapview.popup.visible = false;
        }
    }

    const queryFeatures = (layers: any[], screenPoint: any, clause: string = "", tmpHighlights: IHighlight[]) => {
        // Skip query if no layers provided
        if (!layers || layers.length === 0) {
            dispatch(hideSiteLoader());
            return;
        }

        const point = screenPoint !== null ? mapview.toMap(screenPoint) : null;
        const query = (clause.length === 0) ?
            {
                //query object
                geometry: point,
                spatialRelationship: "intersects",
                returnGeometry: false,
                outFields: ["*"],
                // Add a small buffer for better performance
                distance: 5,
                units: "meters"
            } :
            {
                //query object
                where: clause,
                returnGeometry: false,
                outFields: ["*"],
            }

        dispatch(showSiteLoader());
        queryLayers(layers, point, query).then((results) => {
            results.forEach((featureSet: { features: any[]; }, i: number) => {
                // set graphic location to mouse pointer and add to mapview
                const features = featureSet.features.map((f: { attributes: any; }) => { return f.attributes });
                if (features.length === 0) {
                    dispatch(hideSiteLoader())
                    return;
                }
                const featureAttributes = featureSet.features[0].attributes;
                if (layers[i].title === LayerIds.BasinStats) {
                    const basin = highlights.find((h) => h.attributes.basin_name && h.attributes.OBJECTID === featureAttributes.OBJECTID);
                    if (basin) return;

                    let tmpFeatures: any[] = [];

                    if (currentSubscriptions) {
                        features.forEach((f) => {
                            const found = currentSubscriptions.find((cs) => {
                                return (f.basin_name === cs.name || cs.type === 3);
                            });
                            if (!found) tmpFeatures.push(f);
                        });
                    }
                    if (screenPoint) {
                        dispatch(SET_BASINS(tmpFeatures));
                    }
                    const h = basinLayerView.highlight(featureSet.features.filter((f) => tmpFeatures.find((tmp) => tmp.OBJECTID === f.OBJECTID) !== null));
                    let tmpH = { attributes: featureAttributes, remove: h };
                    if (clause.length === 0) {
                        
                        dispatch(
                            getBasinCountyDetails(
                                {
                                    category:
                                        "basin",
                                    search: `${features[0].basin_name}`,
                                },
                            )
                        );
                    }
                    tmpHighlights.push(tmpH);
                }
                if (layers[i].title === LayerIds.CountyStats) {
                    const county = highlights.find((h) => h.attributes.county_name && h.attributes.OBJECTID === featureAttributes.OBJECTID);
                    if (county) return;
                    let tmpFeatures: any[] = [];

                    if (currentSubscriptions) {
                        features.forEach((f) => {
                            const found = currentSubscriptions.find((cs) => {
                                return ((f.county_name === cs.name && f.state_abbr === cs.state) || cs.type === 3)
                            });
                            if (!found) tmpFeatures.push(f);
                        });
                    }
                    const h = countyLayerView.highlight(featureSet.features.filter((f) => tmpFeatures.find((tmp) => tmp.OBJECTID === f.OBJECTID) !== null));
                    if (screenPoint) {
                        dispatch(SET_COUNTIES(tmpFeatures));
                    }
                    let tmpH = { attributes: featureAttributes, remove: h };
                    if (clause.length === 0) {
                        
                        dispatch(
                            getBasinCountyDetails(
                                {
                                    category:
                                        "county",
                                    search: `${features[0].county_name}`,
                                    state: `${features[0].state_abbr}`,
                                },
                            )
                        );
                    }
                    tmpHighlights.push(tmpH);
                }

                setHighlights(tmpHighlights);
            })
        })
    }

    const tmpConfig: IBaseMapConfig = config as IBaseMapConfig;
    return (<div className="mapDiv" ref={mapDiv}>
    </div>);
}

export default Map;

import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import { AnyAction } from "redux";
import mySettingSlice from "../reducers/my-setting-slice";
import {
    FetchProfilePicReturnType,
    ProfilePicFormData,
    RemoveProfilePicReturnType,
    ReturnMsgAndStatus,
    UpdateProfilePicReturnType,
    UploadProfilePicReturnType,
} from "../../models/submit-form";
import { hideSiteLoader, showSiteLoader } from "./modal-actions";
import axiosAuth from "../../../utils/axios/axios";
import { MysettingbasicInfo } from "../../models/page-props";
import { config, errToast } from "../../../utils/helper";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

export const mySettingActions = mySettingSlice.actions;

// FetchProfileDetail
export const fetchProfileDetail =
    (): ThunkAction<
        Promise<FetchProfilePicReturnType>,
        RootState,
        unknown,
        AnyAction
    > =>
        async (dispatch) => {
            dispatch(showSiteLoader());
            try {
                let response = await axiosAuth.get("/api-user/update-profile", config);
                let {
                    data,
                    agreement_file_name,
                    dir,
                    agreement_id,
                    agreement_type,
                } = response.data;
                if (data) {
                    dispatch(
                        mySettingActions.fetchProfileSettingDetail({
                            ...data,
                            agreement_file_name,
                            dir,
                            agreement_id,
                            agreement_type,
                        })
                    );
                }
                dispatch(hideSiteLoader());
                return response.data;
            } catch (err) {
                errToast(err as AxiosError);
                dispatch(hideSiteLoader());
            }
        };

// UpdateProfileDetail
export const updateProfileDetail =
    (
        payload: MysettingbasicInfo
    ): ThunkAction<
        Promise<UpdateProfilePicReturnType>,
        RootState,
        unknown,
        AnyAction
    > =>
        async (dispatch) => {
            dispatch(showSiteLoader());
            try {
                let response = await axiosAuth.put(
                    "/api-user/update-profile",
                    payload,
                    config
                );
                dispatch(hideSiteLoader());
                return response.data;
            } catch (err) {
                errToast(err as AxiosError);
                dispatch(hideSiteLoader());
            }
        };

//uploadProfilePic
export const uploadProfilePic = (
    formData: ProfilePicFormData
): ThunkAction<
    Promise<UploadProfilePicReturnType>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        try {
            const res = await axiosAuth.put(
                "/api-user/update-profile-pic",
                formData,
                config
            );
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            // dispatch(hideSiteLoader());
        }
    };
};

//removeProfilePic
export const removeProfilePic = (): ThunkAction<
    Promise<RemoveProfilePicReturnType>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        try {
            const res = await axiosAuth.delete(
                "/api-user/update-profile-pic",
                config
            );
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//log download user agreement 
export const logDownloadUserAgreement = (
    formData: {
        agreement_id: number;
        agreement_type: string;
    }
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        try {
            const response = await axiosAuth.post(
                "/api-user/agreement-download-log",
                formData,
                config
            );
            const { status, msg } = response.data;
            if (status !== 200) {
                toast.error(msg);
            }
            return response.data;
        } catch (err) {
            errToast(err as AxiosError);
        }
    };
};

import React from "react";
import { InviteMembersRowDataType } from "../../models/page-props";
import { Link } from "react-router-dom";
import moment from "moment";
import {
    ADMIN,
    ADMIN_CONSTANT,
    MEMBERS,
    MEMBER_CONSTANT,
} from "../../../utils/helper";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { activateOrRoleChangeUsers } from "../../store/actions/members-setting-actions";
import { toast } from "react-toastify";
import { membersSettingsActions } from "../../store/actions/members-setting-actions";
import { InviteUserData } from "../../models/redux-models";

function useColInviteMembers(
    signin_as: number,
    user_id: number,
    showModal: (label: string, userType: string, selectedUserId: number) => void,
    onTrashIconClick?: (id: number) => void
) {
    const {
        membersSettings: { inviteMembersData },
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();
    return [
        {
            title: "Name",
            render: (rowData: InviteMembersRowDataType) => {
                return (
                    <div className="profile">
                        <figure>
                            <img
                                src={
                                    rowData.profile_pic
                                        ? `${import.meta.env.VITE_ED_DATA_CDN_API}/profile_pic/${rowData.profile_pic}`
                                        : "images/profile-pic.png"
                                }
                                alt="profile-pic"
                            />
                        </figure>
                        <figcaption>{rowData.name}</figcaption>
                    </div>
                );
            },
        },
        {
            title: "Email",
            render: (rowData: InviteMembersRowDataType) => {
                return (
                    <Link
                        to={`mailto:${rowData.email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {rowData.email}
                    </Link>
                );
            },
        },
        {
            title: "Created",
            render: (rowData: InviteMembersRowDataType | InviteUserData) => {
                return (
                    <span>
                        {(rowData as InviteMembersRowDataType).date_joined || (rowData as InviteUserData).date_invited
                            ? moment(((rowData as InviteMembersRowDataType).date_joined) || ((rowData as InviteUserData).date_invited)).format("MMM-DD-YYYY")
                            : "NA"}
                    </span>
                );
            },
        },
        {
            title: "Last Login",
            render: (rowData: InviteMembersRowDataType | InviteUserData) => {
                return (
                    <span>
                        {"invite_status" in rowData && (rowData as InviteUserData).invite_status
                            ?
                            (rowData as InviteUserData).invite_status
                            : (rowData as InviteMembersRowDataType).last_login
                                ? moment((rowData as InviteMembersRowDataType).last_login).format("MMM-DD-YYYY")
                                : "NA"}
                        {"invite_status" in rowData && (rowData as InviteUserData).invite_status
                            ?
                            <a href="void:(0)" onClick={(e) => {
                                e.preventDefault()
                                onTrashIconClick && onTrashIconClick(rowData.id)
                            }}><i className="fa fa-trash"></i></a>
                            :
                            <></>}
                    </span>
                );
            },
        },
        {
            title: "Access Level",
            render: (rowData: InviteMembersRowDataType) => {
                return (
                    <div className="droplink">
                        {rowData.signin_role} &nbsp;
                        <i className="fa-solid fa-angle-down"></i>
                        <div className="dropdown">
                            {(rowData.signin_role === ADMIN_CONSTANT ||
                                (signin_as === ADMIN && rowData.is_active)) && (
                                    <>
                                        <h2
                                            className={
                                                rowData.signin_role ===
                                                    ADMIN_CONSTANT
                                                    ? ""
                                                    : "cursor"
                                            }
                                            onClick={() => {
                                                if (
                                                    rowData.signin_role !==
                                                    ADMIN_CONSTANT
                                                ) {
                                                    dispatch(
                                                        activateOrRoleChangeUsers(
                                                            {
                                                                user_id: rowData.id,
                                                                role_id:
                                                                    Number(ADMIN),
                                                                type: "role",
                                                            }
                                                        )
                                                    ).then((result) => {
                                                        const { msg, status } =
                                                            result || {};
                                                        if (status === 200) {
                                                            toast.success(msg);
                                                            dispatch(
                                                                membersSettingsActions.clearInviteMembers()
                                                            );
                                                        } else {
                                                            toast.error(msg);
                                                        }
                                                    });
                                                }
                                            }}
                                        >
                                            Admin
                                        </h2>
                                        <p
                                            className={
                                                rowData.signin_role ===
                                                    ADMIN_CONSTANT
                                                    ? "mb-4 tick"
                                                    : "mb-4"
                                            }
                                        >
                                            Full account access, including billing,
                                            plan information, and member management.{" "}
                                            {rowData.signin_role ===
                                                ADMIN_CONSTANT && (
                                                    <i className="fa-solid fa-check"></i>
                                                )}
                                        </p>
                                    </>
                                )}
                            {(rowData.signin_role === MEMBER_CONSTANT ||
                                (signin_as === ADMIN && rowData.is_active)) && (
                                    <>
                                        <h2
                                            className={
                                                rowData.signin_role ===
                                                    MEMBER_CONSTANT
                                                    ? ""
                                                    : "cursor"
                                            }
                                            onClick={() => {
                                                if (
                                                    rowData.signin_role ===
                                                    ADMIN_CONSTANT &&
                                                    Array.isArray(
                                                        inviteMembersData
                                                    ) &&
                                                    inviteMembersData.filter(
                                                        (item) =>
                                                            item.signin_role ===
                                                            ADMIN_CONSTANT
                                                    ).length === 1
                                                ) {
                                                    toast.info(
                                                        `Please assign the admin role to another member before changing your role from admin to member.`
                                                    );
                                                    return;
                                                }
                                                if (
                                                    rowData.signin_role !==
                                                    MEMBER_CONSTANT
                                                ) {
                                                    dispatch(
                                                        activateOrRoleChangeUsers(
                                                            {
                                                                user_id: rowData.id,
                                                                type: "role",
                                                                role_id:
                                                                    Number(MEMBERS),
                                                            }
                                                        )
                                                    ).then((result) => {
                                                        const { msg, status } =
                                                            result || {};
                                                        if (status === 200) {
                                                            toast.success(msg);
                                                            dispatch(
                                                                membersSettingsActions.clearInviteMembers()
                                                            );
                                                        } else {
                                                            toast.error(msg);
                                                        }
                                                    });
                                                }
                                            }}
                                        >
                                            Member
                                        </h2>
                                        <p
                                            className={
                                                rowData.signin_role ===
                                                    MEMBER_CONSTANT
                                                    ? "mb-4 tick"
                                                    : "mb-4"
                                            }
                                        >
                                            Full access to Energy Domain Data. No
                                            access to billing, plan information, or
                                            team management.
                                            {rowData.signin_role ===
                                                MEMBER_CONSTANT && (
                                                    <i className="fa-solid fa-check"></i>
                                                )}
                                        </p>
                                    </>
                                )}
                            <div
                                className={
                                    signin_as === ADMIN ||
                                        user_id === rowData.id
                                        ? ""
                                        : "d-none"
                                }
                            >
                                <div className={!rowData.is_active ? "d-none" : ''}>
                                    <h2
                                        className="remove-member"
                                        onClick={() => {
                                            if (
                                                rowData.signin_role ===
                                                ADMIN_CONSTANT &&
                                                rowData.is_active &&
                                                Array.isArray(
                                                    inviteMembersData
                                                ) &&
                                                inviteMembersData.filter(
                                                    (item) =>
                                                        item.signin_role ===
                                                        ADMIN_CONSTANT &&
                                                        item.is_active
                                                ).length === 1
                                            ) {
                                                toast.info(
                                                    `Please assign the admin role to another member before removing your account.`
                                                );
                                                return;
                                            }
                                            showModal(
                                                "Remove",
                                                rowData.signin_role ===
                                                    ADMIN_CONSTANT
                                                    ? ADMIN_CONSTANT
                                                    : MEMBER_CONSTANT,
                                                rowData.id
                                            );
                                        }}
                                    >
                                        Remove{" "}
                                        {`${rowData.signin_role ===
                                            ADMIN_CONSTANT
                                            ? ADMIN_CONSTANT
                                            : MEMBER_CONSTANT
                                            }`}
                                    </h2>
                                    <p>
                                        Member will be permanently removed from your account and all associated data will be lost. Member seat may be reassigned after removal of existing member.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            },
        },
    ];
}

export default useColInviteMembers;

import React from "react";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Flip, ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import store from "./components/store";
import ClickTracker from "./components/common/ClickTracker";
import * as Sentry from "@sentry/react";
import { productionDomain } from "./utils/helper";
import './assets/scss/main.scss';

const environment = import.meta.env.VITE_ENVIRONMENT;

let tracesSampleRate = 1.0;
if (environment?.includes('local')) {
    tracesSampleRate = 0;
}

Sentry.init({
    dsn: `${import.meta.env.VITE_SENTRY_DNS}`,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: tracesSampleRate, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [productionDomain],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Dynamically set environment
    environment: environment,
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <ToastContainer
            theme="colored"
            position="top-right"
            transition={Flip}
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
        />
        <Provider store={store}>
            <App />
            <ClickTracker />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Type definitions for Appcues
type UserAttributes = {
    first_name?: string;
    last_name?: string;
    email?: string;
    user_id?: number;
};

// Ensure the Appcues array is initialized
if (!(window as any).appcues) {
    (window as any).appcues = [];
}

/**
 * Initializes Appcues with user information.
 * @param userId - The unique identifier for the user.
 * @param userAttributes - Additional user attributes to send to Appcues.
 */
export const initializeAppcues = (
    userId: number | null,
    userAttributes: UserAttributes = {}
) => {
    if (!userId) {
        // Now we can safely push
        try {
            (window as any).Appcues.reset();
        } catch (error) {
            console.error("Error stopping Appcues:", error);
        }
        return;
    }

    // Try to identify user, with retries
    const maxRetries = 5;
    let retryCount = 0;

    const tryIdentify = () => {
        if ((window as any).Appcues?.identify) {
            // Appcues is loaded and ready
            (window as any).Appcues.identify(userId, userAttributes);
        } else if (retryCount < maxRetries) {
            // Retry after a short delay
            retryCount++;
            setTimeout(tryIdentify, 1000);
        } else {
            // Queue the identify call for when Appcues loads
            (window as any).appcues.push(['identify', userId, userAttributes]);
        }

    };

    tryIdentify();
}

/**
 * Shows a specific Appcues flow.
 * @param flowId - The ID of the flow to display.
 */
export const showAppcuesFlow = (flowId: string) => {
    (window as any).Appcues.show(flowId);
};

/**
 * Stops any active Appcues flows.
 */
export const stopAppcues = () => {
    (window as any).Appcues.reset();
};

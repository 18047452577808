import React from "react";
import { selectOptionProps } from "../models/page-props";

export const SelectInput = ({
    options,
    register,
    name,
    errorMsg,
    className,
    placeholder,
    defaultValue,
    onChange,
    // onBlur,
    ...rest
}: selectOptionProps) => {
    return (
        <>
            <select
                {...register ? register(name) : ''}
                className={`form-control ${className}`}
                defaultValue={defaultValue}
                onChange={onChange}
                // onBlur={onBlur}
                {...rest}
            >
                {placeholder && <option value="">{placeholder}</option>}
                {options.map((item, index) => (
                    <option value={item.value} key={index} >
                        {item.label}
                    </option>
                ))}

            </select>
            {name === "wellMatch" ? <img src="images/white-down-arrow.svg" alt="white-down-arrow" className="wellMatchingOption" /> : ''}
            {errorMsg && <span className={`error`}>{errorMsg}</span>}
        </>
    );
};

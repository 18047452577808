import getNewAuthToken from "./getAuthToken";
import setAuthToken from "./setAuthToken";
import logTokenState from "./logTokenState";
import { axiosAuth } from "./axios";

interface TokenData {
    access_token: string;
    refresh_token: string;
    expiresAt: number;
}

class TokenManager {
    private static instance: TokenManager;
    private tokenData: TokenData | null = null;
    private refreshPromise: Promise<string> | null = null;

    private constructor() {
        // Initialize from localStorage if available
        this.loadTokenFromStorage();
    }

    static getInstance(): TokenManager {
        if (!TokenManager.instance) {
            TokenManager.instance = new TokenManager();
        }
        return TokenManager.instance;
    }

    private loadTokenFromStorage() {
        const accessToken = localStorage.getItem('access_token');
        const refreshToken = localStorage.getItem('refresh_token');

        if (accessToken && refreshToken) {
            this.tokenData = {
                access_token: accessToken,
                refresh_token: refreshToken,
                expiresAt: Date.now() + (5 * 60 * 1000) // 5 minutes expiry
            };
            logTokenState('Loaded token from storage', this.tokenData?.access_token);
        }
    }

    private saveTokenToStorage() {
        if (this.tokenData) {
            localStorage.setItem('access_token', this.tokenData.access_token);
            localStorage.setItem('refresh_token', this.tokenData.refresh_token);
        } else {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
        }
    }

    async getValidToken(): Promise<string> {
        // If we're already refreshing, wait for that to complete
        if (this.refreshPromise) {
            return this.refreshPromise;
        }

        // Check if we have a token and if it's expired or will expire in the next 30 seconds
        const isExpiredOrExpiringSoon = !this.tokenData ||
            this.tokenData.expiresAt <= Date.now() + 30000; // 30 second buffer

        if (isExpiredOrExpiringSoon && this.tokenData?.refresh_token) {
            // Need to refresh
            this.refreshPromise = this.refreshToken();
            try {
                const newToken = await this.refreshPromise;
                return newToken;
            } finally {
                this.refreshPromise = null;
            }
        }

        // Return current token if it's still valid
        if (this.tokenData?.access_token) {
            return this.tokenData.access_token;
        }

        throw new Error('No valid token available');
    }

    getRefreshToken(): string {
        return this.tokenData?.refresh_token || '';
    }

    setTokens(tokenData: TokenData) {
        if (!tokenData.access_token || !tokenData.refresh_token) {
            throw new Error('Invalid token data provided');
        }

        this.tokenData = {
            access_token: tokenData.access_token,
            refresh_token: tokenData.refresh_token,
            expiresAt: tokenData.expiresAt || (Date.now() + (5 * 60 * 1000)) // 5 minutes expiry
        };

        this.saveTokenToStorage();
        setAuthToken(tokenData.access_token);
        logTokenState('New tokens set', tokenData.access_token);
    }

    public async refreshToken(): Promise<string> {
        try {
            if (!this.tokenData?.refresh_token) {
                logTokenState('Refresh failed - No refresh token', undefined);
                throw new Error('No refresh token available');
            }

            const newToken = await getNewAuthToken(this.tokenData.refresh_token);
            this.setTokens({
                access_token: newToken,
                refresh_token: this.tokenData.refresh_token,
                expiresAt: Date.now() + (5 * 60 * 1000) // 5 minutes expiry
            });

            return newToken;
        } catch (error) {
            this.clearTokens();
            throw error;
        }
    }

    public getTokenData(): TokenData | null {
        if (this.tokenData) {
            return { ...this.tokenData };
        }
        return null;
    }

    clearTokens(silent: boolean = false) {
        this.tokenData = null;
        this.saveTokenToStorage();
        setAuthToken('');
        // Clear storage
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");

        // Clear auth header
        delete axiosAuth.defaults.headers.common["Authorization"];

        if (!silent) {
            logTokenState('Tokens cleared', '');
            // Redirect to home page and reload
            window.location.href = '/';
        }
    }
}

export default TokenManager;
import React, { useEffect, useState } from "react";
import GlobalTable from "../GlobalTable";
import useWellsAndRigsCol from "./useWellsAndRigsCol";
import { GlobalTableProps } from "../../models/page-props";
import { Scrollbars } from "react-custom-scrollbars";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {
    clearPermitData,
    clearProductionData,
    clearRigsData,
    clearWellsData,
    handlePageChange,
    setDontUpdateSelectedWellsRigs,
} from "../../store/actions/wells-rigs-action";
import Spinner from "../Spinner";
import LazyLoad from "react-lazy-load";
import { LINE_CHART_MAX_ITEM_ALLOWED, wells } from "../../../utils/helper";

// onlyCheckedData is used to
function TabCont({ onlyCheckedData }: { onlyCheckedData?: boolean }) {
    // const wellsAndRigs = useAppSelector(state => state.wellsAndRigs);
    const wellsPage = useAppSelector(state => state.wellsAndRigs.wellsPage);
    const rigsPage = useAppSelector(state => state.wellsAndRigs.rigsPage);
    const permitsPage = useAppSelector(state => state.wellsAndRigs.permitsPage);
    const productionPage = useAppSelector(state => state.wellsAndRigs.productionPage);
    const selectedWellRigOrPermitList = useAppSelector(state => state.wellsAndRigs.selectedWellRigOrPermitList);
    const fullScreen = useAppSelector(state => state.wellsAndRigs.fullScreen);
    const resizableHeight = useAppSelector(state => state.wellsAndRigs.resizableHeight);
    const fullScrnAnalytics = useAppSelector(state => state.wellsAndRigs.fullScrnAnalytics);
    const selectedRowId = useAppSelector(state => state.wellsAndRigs.selectedRowId);
    const viewAnalytics = useAppSelector(state => state.wellsAndRigs.viewAnalytics);
    const tabIndex = useAppSelector(state => state.wellsAndRigs.tabIndex);

    // const productionData = useAppSelector(state => state.wellsAndRigs.productionData);
    const productionData = useAppSelector(state => state.wellsAndRigs.productionData.data);
    const productionTotalCount = useAppSelector(state => state.wellsAndRigs.productionData.total_count);
    const productionPageSize = useAppSelector(state => state.wellsAndRigs.productionData.page_size);

    const permitsData = useAppSelector(state => state.wellsAndRigs.permitsData.data);
    const permitsTotalCount = useAppSelector(state => state.wellsAndRigs.permitsData.total_count);
    const permitsPageSize = useAppSelector(state => state.wellsAndRigs.permitsData.page_size);

    const rigsData = useAppSelector(state => state.wellsAndRigs.rigsData.data);
    const rigsTotalCount = useAppSelector(state => state.wellsAndRigs.rigsData.total_count);
    const rigsPageSize = useAppSelector(state => state.wellsAndRigs.rigsData.page_size);

    const wellsData = useAppSelector(state => state.wellsAndRigs.wellsData.data);
    const wellsTotalCount = useAppSelector(state => state.wellsAndRigs.wellsData.total_count);
    const wellsPageSize = useAppSelector(state => state.wellsAndRigs.wellsData.page_size);



    const dispatch = useAppDispatch();
    const [state, setState] = useState<{
        totalPage: number;
        nextPageDataLoading: boolean;
        page: number;
    }>({
        totalPage: 1,
        nextPageDataLoading: false,
        page: 1,
    });
    const { totalPage, nextPageDataLoading, page } = state;
    const onPageChange = () => {
        switch (tabIndex) {
            case 0:
                dispatch(setDontUpdateSelectedWellsRigs(true))
                dispatch(handlePageChange(wellsPage + 1));
                dispatch(clearWellsData());
                break;
            case 1:
                dispatch(handlePageChange(rigsPage + 1));
                dispatch(clearRigsData());
                break;
            case 2:
                dispatch(handlePageChange(permitsPage + 1));
                dispatch(clearPermitData());
                break;
            case 3:
                dispatch(handlePageChange(productionPage + 1));
                dispatch(clearProductionData());
                break;
            default:
                dispatch(handlePageChange(wellsPage + 1));
                dispatch(clearWellsData());
        }
    };
    useEffect(() => {
        if (
            (Array.isArray(wellsData) && wellsData.length > 0) ||
            (Array.isArray(rigsData) && rigsData.length > 0) ||
            (Array.isArray(permitsData) && permitsData.length > 0) ||
            (Array.isArray(productionData) && productionData.length > 0)
        ) {
            setState((prev) => ({
                ...prev,
                totalPage:
                    tabIndex === 0
                        ? Math.floor(wellsTotalCount / wellsPageSize) +
                        (wellsTotalCount % wellsPageSize > 0 ? 1 : 0)
                        : tabIndex === 1
                            ? Math.floor(rigsTotalCount / rigsPageSize) +
                            (rigsTotalCount % rigsPageSize > 0 ? 1 : 0)
                            : tabIndex === 2 ? Math.floor(permitsTotalCount / permitsPageSize) +
                                (permitsTotalCount % permitsPageSize > 0 ? 1 : 0) : Math.floor(productionTotalCount / productionPageSize) +
                            (productionTotalCount % productionPageSize > 0 ? 1 : 0),
                nextPageDataLoading: false,
                page:
                    tabIndex === 0
                        ? wellsPage
                        : tabIndex === 1
                            ? rigsPage
                            : tabIndex === 2 ? permitsPage : productionPage,
            }));
        } else {
            setState((prev) => ({
                ...prev,
                totalPage: 1,
                nextPageDataLoading: false,
                page: 1,
            }));
        }
        // eslint-disable-next-line
    }, [wellsData, rigsData, permitsData, tabIndex, productionData]);

    let data = onlyCheckedData ? selectedWellRigOrPermitList.length > LINE_CHART_MAX_ITEM_ALLOWED ? [...selectedWellRigOrPermitList].slice(0, LINE_CHART_MAX_ITEM_ALLOWED) : selectedWellRigOrPermitList : Array.isArray(wellsData) ||
        Array.isArray(rigsData) ||
        Array.isArray(permitsData) || Array.isArray(productionData)
        ? tabIndex === 0
            ?
            wellsData
            :
            tabIndex === 1
                ?
                rigsData
                :
                tabIndex === 2 ? permitsData : productionData
        : (null as any)
    return (
        <div className={`searchList ${fullScrnAnalytics && data?.length > 1 ? "" : "scrollSection"
            } `}>
            <LazyLoad>
                <>
                    <Scrollbars
                        className={`${nextPageDataLoading
                            ? "customTable lead-scroll no-z-index spinnerdiv "
                            : ""
                            } customTable lead - scroll no - z - index`}
                        style={{ width: "100%" }}
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={`${fullScreen
                            ? `calc(100vh - "8rem")`
                            : fullScrnAnalytics && data?.length > 1 ? "10rem" : resizableHeight
                            } `}
                        // autoHeightMax="10rem"

                        renderThumbVertical={(props) => (
                            <div {...props} className="thumb-vertical" />
                        )}
                        renderTrackVertical={(props) => (
                            <div {...props} className="track-vertical" />
                        )}
                        renderThumbHorizontal={(props) => (
                            <div {...props} className="thumb-horizontal" />
                        )}
                        onScrollFrame={(event) => {
                            const {
                                top,
                                scrollTop,
                                clientHeight,
                                scrollHeight,
                            } = event;
                            if (
                                top &&
                                Math.round(scrollTop + clientHeight) >=
                                Math.round((scrollHeight * 50) / 100) &&
                                !nextPageDataLoading &&
                                page < totalPage && !fullScrnAnalytics
                            ) {
                                setState((prev) => ({
                                    ...prev,
                                    nextPageDataLoading: true,
                                }));
                                onPageChange();
                            }
                        }}
                    >
                        <GlobalTable
                            tableStyle={{
                                border: 0,
                                cellPadding: 0,
                                cellSpacing: 0,
                            }}
                            cols={
                                useWellsAndRigsCol() as GlobalTableProps["cols"]
                            }
                            loadingMsg={"Loading..."}
                            rowId={tabIndex === 0 ? (selectedRowId as string) : (selectedRowId as number)}
                            data={
                                data
                            }
                            graySelected={viewAnalytics}
                            showColGroup={true}
                        />
                        {/* {nextPageDataLoading && <Spinner />} */}
                    </Scrollbars>
                    {nextPageDataLoading && <Spinner />}
                </>
            </LazyLoad>
        </div>
    );
}

export default TabCont;

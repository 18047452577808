import React, { useEffect, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {
    clearFilterSearch,
    clearPermitData,
    clearProductionData,
    clearRigsData,
    clearWellsData,
    handleSelectedWellRigOrPermitUIDList,
    handleResizableWidth,
    // handlePageChange,
    handleShowAndHideSegmentDropDown,
    handleShowAndHideTable,
    // setFilterSearch,
    showHideAdvFilter,
    showHideColProperties,
    showHideCsvDownOpt,
    showHideFullScreen,
    toggleChooseColExportToCsvModal,
    toggleViewAnalytics,
    handleAnalyticsTabIndex,
    deleteSavedColumnProperties,
    setColumnProperties,
    updateColumnPropertiesName,
    updateTableColName,
    updateWellsOrRigsTableCol,
} from "../../store/actions/wells-rigs-action";
import ColFilterOption from "./ColFilterOption";
// import ExportToCsvOpt from "./ExportToCsvOpt";
import { handleClearAllFilter, toggleLeftTab } from "../../store/actions/cart-basin-to-county-actions";
import {
    ANALYTICS_DEFAULT_WIDTH,
    convertToDisplayFormatShortCurrencyFourDigit,
    modifyString,
} from "../../../utils/helper";
// import { ProductionDataObj, RigsDataObj, WellsAndPermitsObject } from "../../models/redux-models";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../Spinner";
import { fetchSegmentsList, fetchSelectedSegmentDetails } from "../../store/actions/segments-actions";
import { FilterDataType } from "../../segments/Content";
import { toast } from "react-toastify";
import FreeTrialDownAlertMsgModal from "../Modal/FreeTrialDownAlertMsgModal";
import { handleFreeTrialDownAlertMsgModal } from "../../store/actions/modal-actions";
import { isInstanceOfWellsAndPermitsObject } from "../Map/helper";
import { ProductionDataObj, RigsDataObj, tableColObje, WellsAndPermitsObject } from "../../models/redux-models";
import Scrollbars from 'react-custom-scrollbars';
import {
    handleSavedFilterColumnProperties,
} from '../../store/actions/cart-basin-to-county-actions';


function Filter({ hideAdvFilterBtn, hideTableFilters }: { hideAdvFilterBtn?: boolean, hideTableFilters?: boolean }) {
    const searchRef = React.createRef<HTMLInputElement>();
    const dispatch = useAppDispatch();

    const cartBasinToCounty = useAppSelector(state => state.cartBasinToCounty)
    const user = useAppSelector(state => state.auth.user)
    const modal = useAppSelector(state => state.modal)
    const segments = useAppSelector(state => state.segments)
    const activeSegmentList = useAppSelector(state => state.segments.activeSegmentList)
    const wellsAndRigs = useAppSelector(state => state.wellsAndRigs)

    const [filterName, setFilterName] = useState("")

    const clearSearch = () => {
        searchRef.current &&
            (searchRef.current.value = "");
        dispatch(clearFilterSearch());
        // note:- if clearAllFilter is true do not call reset data
        if (!cartBasinToCounty.clearAllFilter) {
            if (wellsAndRigs.tabIndex === 0) {
                dispatch(clearWellsData());
            } else if (wellsAndRigs.tabIndex === 1) {
                dispatch(clearRigsData());
            } else if (wellsAndRigs.tabIndex === 2) {
                dispatch(clearPermitData());
            } else {
                dispatch(clearProductionData())
            }
        }

    }
    const tableColumnFiltered = useMemo(() => {
        let tempTableColumns: tableColObje[] = []
        if (wellsAndRigs.tabIndex === 0 || wellsAndRigs.tabIndex === 2) {
            tempTableColumns = wellsAndRigs.tableColFullList
        } else if (wellsAndRigs.tabIndex === 1) {
            tempTableColumns = wellsAndRigs.rigsTableColFullList
        } else {
            tempTableColumns = wellsAndRigs.productionCol
        }
        return tempTableColumns

    }, [wellsAndRigs.tabIndex, wellsAndRigs.tableColFullList, wellsAndRigs.rigsTableColFullList, wellsAndRigs.productionCol])
    /** The reason that we need both of these (with and with default) is
     * that we don't want the user to be able to edit or delete the default 
     * columns.  They can always click 'Set as Default' to save the current properties as 
     * default.  However, we want to see if there are columns with the name 'default' to 
     * see if we need to make the api call to get the table info
     */

    const tableColumnFilteredWithoutDefault = useMemo(() => {
        return tableColumnFiltered.filter((tableCol: tableColObje) => tableCol.tableColName != 'default' && typeof tableCol.tableColName != 'undefined')
    }, [tableColumnFiltered])

    useEffect(() => {
        if (cartBasinToCounty.clearAllFilter) {
            clearSearch();
            dispatch(handleClearAllFilter(false))
        }
        // eslint-disable-next-line
    }, [cartBasinToCounty.clearAllFilter])


    const fetchData = () => {
        dispatch(
            fetchSegmentsList({
                type: "active",
                page: segments.page + 1,
            })
        );
    };

    const [state, setState] = useState({
        hasMore: false,
        segmentName: "",
        segmentId: 0
    })
    const { hasMore, segmentName, segmentId } = state
    useEffect(() => {
        if (wellsAndRigs.showSegmentDropDown && activeSegmentList.total_record) {
            let pages =
                Math.floor(activeSegmentList.total_record / activeSegmentList.page_size) +
                (activeSegmentList.total_record % activeSegmentList.page_size > 0 ? 1 : 0);
            setState((prev) => ({
                ...prev,
                hasMore: segments.page < pages ? true : false,
            }));
        }

        // eslint-disable-next-line
    }, [activeSegmentList.total_record, segments.page]);

    useEffect(() => {
        if (activeSegmentList.dataLoading && wellsAndRigs.showSegmentDropDown) {
            dispatch(fetchSegmentsList({ type: "active", page: segments.page }, true));
        }
        // eslint-disable-next-line
    }, [wellsAndRigs.showSegmentDropDown])

    useEffect(() => {
        if (segments.selectedSegmentData.length > 0) {
            wellsAndRigs.advFilter && dispatch(showHideAdvFilter());

            let tempData = [...segments.selectedSegmentData];
            tempData[0] = { ...tempData[0], group_cond: "4" };
            let tempTransformData: FilterDataType[] = [];
            tempData.forEach((item) => {
                if (item.group_cond) {
                    tempTransformData.push({
                        condition: item.option_choice,
                        groupCondition: item.group_cond,
                        filter: [
                            {
                                id: item.id,
                                segment_id: item.segment_id,
                                dataPoint: item.data_point,
                                fields: item.data_point_field,
                                operator: item.operator_choice,
                                //this value key will be over written by the below condition this is just done to overcome the typescript error
                                value: item.filter_value as any,
                                ...((item.data_point_field === "16" ||
                                    item.data_point_field === "17" ||
                                    item.data_point_field === "18") &&
                                    item.operator_choice === "14"
                                    ? {
                                        value: new Date(
                                            JSON.parse(
                                                modifyString(
                                                    item.filter_value as string
                                                )
                                            ).start
                                        ),
                                        endDate: new Date(
                                            JSON.parse(
                                                modifyString(
                                                    item.filter_value as string
                                                )
                                            ).end
                                        ),
                                    }
                                    : item.data_point_field === "7"
                                        ? {
                                            ...(item.operator_choice === "4" && {
                                                value: (
                                                    item.filter_value as string
                                                ).split("_")[0],
                                                upperValue: (
                                                    item.filter_value as string
                                                ).split("_")[1],
                                            }),
                                            ...(item.operator_choice !== "4" && {
                                                value: item.filter_value,
                                            }),
                                        }
                                        : item.data_point_field === "9"
                                            ? {
                                                value: (
                                                    item.filter_value as string
                                                ).split("_")[0],
                                                //   upperValue: `${
                                                //       (
                                                //           item.filter_value as string
                                                //       ).split("_")[1]
                                                //   } ( ${
                                                //       (
                                                //           item.filter_value as string
                                                //       ).split("_")[2]
                                                //   } )`,
                                                upperValue: {
                                                    label: (
                                                        item.filter_value as string
                                                    ).split("_")[1],
                                                    value: (
                                                        item.filter_value as string
                                                    ).split("_")[2],
                                                },
                                            }
                                            : { value: item.filter_value }),
                            },
                        ],
                    });
                } else {
                    let index = tempTransformData.length - 1;
                    tempTransformData[index] = {
                        ...tempTransformData[index],
                        filter: [
                            ...tempTransformData[index]?.filter,
                            {
                                id: item.id,
                                segment_id: item.segment_id,
                                dataPoint: item.data_point,
                                fields: item.data_point_field,
                                operator: item.operator_choice,
                                //this value key will be over written by the below condition this is just done to overcome the typescript error
                                value: item.filter_value as any,
                                ...((item.data_point_field === "16" ||
                                    item.data_point_field === "17" ||
                                    item.data_point_field === "18") &&
                                    item.operator_choice === "14"
                                    ? {
                                        value: new Date(
                                            JSON.parse(
                                                modifyString(
                                                    item.filter_value as string
                                                )
                                            ).start
                                        ),
                                        endDate: new Date(
                                            JSON.parse(
                                                modifyString(
                                                    item.filter_value as string
                                                )
                                            ).end
                                        ),
                                    }
                                    : item.data_point_field === "7"
                                        ? {
                                            ...(item.operator_choice === "4" && {
                                                value: (
                                                    item.filter_value as string
                                                ).split("_")[0],
                                                upperValue: (
                                                    item.filter_value as string
                                                ).split("_")[1],
                                            }),
                                            ...(item.operator_choice !== "4" && {
                                                value: item.filter_value,
                                            }),
                                        }
                                        : item.data_point_field === "9"
                                            ? {
                                                value: (
                                                    item.filter_value as string
                                                ).split("_")[0],
                                                //   upperValue: (
                                                //       item.filter_value as string
                                                //   ).split("_")[1],
                                                upperValue: {
                                                    label: (
                                                        item.filter_value as string
                                                    ).split("_")[2],
                                                    value: (
                                                        item.filter_value as string
                                                    ).split("_")[1],
                                                },
                                            }
                                            : { value: item.filter_value }),
                            },
                        ],
                    };
                }
            });
            localStorage.setItem(
                "advFilData",
                JSON.stringify({
                    data: tempTransformData,
                    segmentName,
                    segmentId
                })
            );
            dispatch(showHideAdvFilter());
        }
        // eslint-disable-next-line
    }, [JSON.stringify(segments.selectedSegmentData)])

    const getAnalyticsWellCardText = useMemo(() => {
        if (wellsAndRigs.viewAnalytics && wellsAndRigs.analyticsTabIndex === 0) {
            return 'Well Card'
        }
        return 'Analytics'
    }, [wellsAndRigs.analyticsTabIndex, wellsAndRigs.viewAnalytics, wellsAndRigs.selectedWellRigOrPermitUIDList])

    const onlyWellsNotRigsList = useMemo(() => {
        return wellsAndRigs.selectedWellRigOrPermitList.filter((wellRigOrProd: WellsAndPermitsObject | RigsDataObj | ProductionDataObj) => isInstanceOfWellsAndPermitsObject(wellRigOrProd))
    }, [wellsAndRigs.selectedWellRigOrPermitList])

    return (
        <>
            <div className="search-bottom">
                <div className={hideAdvFilterBtn ? "d-none" : "search-bottom-left"}>
                    <div
                        className="ad-filter"
                        onClick={() => {
                            wellsAndRigs.fullScreen && dispatch(showHideFullScreen());
                            dispatch(showHideAdvFilter());
                        }}
                    >
                        <a
                            href="void:(0)"
                            onClick={(e) => e.preventDefault()}
                            className="btn"
                        >
                            <img src="images/ad-filter.svg" alt="" />
                            Advanced Filters
                            <span onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                dispatch(handleShowAndHideSegmentDropDown(!wellsAndRigs.showSegmentDropDown));
                            }} className="down-arrow">&nbsp;</span>
                        </a>
                        {/*add open to open dropdown  */}
                        <div className={`dropdownMenu segmentsSection-scroll scrollSection ${wellsAndRigs.showSegmentDropDown ? "open" : ""}`}
                            id={'segmentList'}
                            style={{
                                minHeight: 0,
                                // maxHeight: "calc(100vh - 50rem)",
                                maxHeight: "16rem"
                            }}
                        >
                            <InfiniteScroll
                                dataLength={activeSegmentList.data.length}
                                next={fetchData}
                                hasMore={hasMore}
                                scrollThreshold={0.8}
                                loader={<Spinner />}
                                style={{ overflow: "hidden" }}
                                scrollableTarget={"segmentList"}
                            >
                                <h2>Saved Segments</h2>
                                <ul>
                                    {activeSegmentList.dataLoading ? <li><a href="void:(0)" onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>Loading...</a></li> :
                                        activeSegmentList.data.map((item, index) => {
                                            return <li key={index}><a href="void:(0)" onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (
                                                    item.id !==
                                                    segments.selectedRowId && wellsAndRigs.tabIndex === 1
                                                ) {
                                                    wellsAndRigs.advFilter && dispatch(showHideAdvFilter())
                                                    setState((prev) => ({ ...prev, segmentId: item.id, segmentName: item.segment_name }))
                                                    dispatch(
                                                        handleSelectedWellRigOrPermitUIDList(
                                                            [item.id.toString()]
                                                        )
                                                    );
                                                    dispatch(
                                                        fetchSelectedSegmentDetails(
                                                            item.id
                                                        )
                                                    );
                                                } else {
                                                    dispatch(
                                                        handleSelectedWellRigOrPermitUIDList(
                                                            []
                                                        )
                                                    );
                                                    dispatch(showHideAdvFilter())
                                                }
                                            }}>{item.segment_name}{item.id === segments.selectedRowId ? <span style={{ color: "#fff", background: "#16A15E", borderRadius: "100%", padding: "1px", fontSize: "10px", width: "16px", height: "16px", textAlign: "center" }}><i className="fa-solid fa-check"></i></span> : <></>}</a></li>
                                        })
                                    }
                                </ul>
                            </InfiniteScroll>

                        </div>
                    </div>
                </div>
                <div className={hideTableFilters ? "d-none" : "search-menu"}>
                    <ul>

                        <li className="download"
                            title={"Export Data"}
                        >
                            <a
                                href="void:(0)"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    wellsAndRigs.fullScreen && dispatch(showHideFullScreen())
                                    wellsAndRigs.chooseColExportToCsvModal &&
                                        dispatch(toggleChooseColExportToCsvModal());
                                    dispatch(showHideColProperties(false));
                                    if (!user.company_configs.download_enabled && user.company_configs.free_trial_period_enabled) {
                                        dispatch(handleFreeTrialDownAlertMsgModal(true));
                                        return
                                    }
                                    dispatch(showHideCsvDownOpt());
                                }}
                            >
                                <img src="images/search-icon3.svg" alt="" />
                                Download
                            </a>
                            {/* {wellsAndRigs.selectedWellRigOrPermitList?.length && !wellsAndRigs.csvDownOpt ? <span className="badge">{wellsAndRigs.selectedWellRigOrPermitList.length}</span> : <></>} */}
                            {onlyWellsNotRigsList?.length ? <span className="badge">{wellsAndRigs.tableAllRowSelected ? convertToDisplayFormatShortCurrencyFourDigit(wellsAndRigs.wellsData.total_count) : onlyWellsNotRigsList.length}</span> : <></>}
                            {/* <ExportToCsvOpt selectedRowData={(wellsAndRigs.selectedWellRigOrPermitList || []) as (WellsAndPermitsObject[] | RigsDataObj[] | ProductionDataObj[])} /> */}
                        </li>
                        <li
                            onClick={() => {
                                if (wellsAndRigs.selectedWellRigOrPermitList.length) {
                                    cartBasinToCounty.leftTab && dispatch(toggleLeftTab(false));
                                    /** If the right card is open, and 1 well is selected, then this button should toggle
                                     * between Analyics and Well Card
                                     */
                                    // CASE 1: If the sidecard tab is not 0, meaning that the sidecard would show the
                                    // well card (wellsAndRigs.analyticsTabIndex == 1).  The expected behavior is that the card showing Well Attributes
                                    // would close (if not already).  Then we set the tab to 0 (Analytics), then we open 
                                    // the card back up. 
                                    if (wellsAndRigs.analyticsTabIndex !== 0) {
                                        dispatch(toggleViewAnalytics(false));
                                        dispatch(handleAnalyticsTabIndex(0));
                                    } else if (wellsAndRigs.selectedWellRigOrPermitList.length === 1) {
                                        dispatch(toggleViewAnalytics(false));
                                        dispatch(handleAnalyticsTabIndex(1));
                                    }
                                    dispatch(toggleViewAnalytics(true));

                                } else {
                                    toast.info("Please select at least one well.")
                                }
                            }}
                        >
                            <a
                                className={getAnalyticsWellCardText === "Well Card" && wellsAndRigs.selectedWellRigOrPermitUIDList.length !== 1 ? "view-analytics disabled" : "view-analytics"}
                                onClick={(e) => e.preventDefault()}
                                href="void:(0)"
                            >
                                <img src={getAnalyticsWellCardText === "Analytics" ? "images/search-icon4.svg" : "images/pumpjack.svg"} alt="" className={getAnalyticsWellCardText === "Well Card" ? "make-svg-white well-card" : ''} />{" "}
                                {getAnalyticsWellCardText}
                            </a>
                        </li>
                    </ul>
                    <ul className="actionMenuEnd">
                        <li
                            title="Choose Columns"
                        >
                            <a
                                href="void:(0)"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    dispatch(showHideColProperties());
                                    wellsAndRigs.csvDownOpt &&
                                        dispatch(showHideCsvDownOpt());
                                    cartBasinToCounty.savedFilterColumnProperties.openSavedFilterColumnProperties && dispatch(handleSavedFilterColumnProperties({ openSavedFilter: false }))
                                }}
                            >
                                <img src="images/search-icon1.svg" alt="" />
                            </a>
                            <div
                                className={
                                    wellsAndRigs.colProperties
                                        ? "column-properties-dropdown open"
                                        : "d-none"
                                }
                            >

                                <h2>
                                    <span>Column properties</span>
                                    <button className="btn" onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        dispatch(handleSavedFilterColumnProperties({ openSavedFilter: !cartBasinToCounty.savedFilterColumnProperties.openSavedFilterColumnProperties }))
                                    }}><img src="images/save-column-properties.svg" alt="" className="margin-bottom-04" /></button>

                                </h2>
                                <div className={`save-options-modal ${cartBasinToCounty.savedFilterColumnProperties.openSavedFilterColumnProperties ? 'expanded' : 'collapsed'}`}>
                                    <div className="title-h2" >
                                        <a href="void:(0)" onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (cartBasinToCounty.savedFilterColumnProperties.saveFiltersDataLoadingColumnProperties) {
                                                return
                                            }
                                            if (tableColumnFilteredWithoutDefault && tableColumnFilteredWithoutDefault?.length === 10) {
                                                toast.info(`Maximum 10 filter configurations allowed per user.`);
                                                return
                                            }
                                            // saveSearchFiltersFn();
                                            dispatch(setColumnProperties(null, true))

                                        }}>Save column properties</a>
                                    </div>
                                    <div className="save-table-columns">
                                        <h3>Saved properties</h3>
                                        <Scrollbars
                                            autoHeight
                                            autoHeightMin={0}
                                            renderThumbVertical={(props) => (
                                                <div {...props} className="thumb-vertical" />
                                            )}
                                            renderTrackVertical={(props) => (
                                                <div {...props} className="track-vertical" />
                                            )}
                                        >
                                            <ul onClick={(e) => e.stopPropagation()} className="flex-column">
                                                {cartBasinToCounty.savedFilterColumnProperties.saveFiltersDataLoadingColumnProperties ?
                                                    <div onClick={(e) => e.stopPropagation()} className="table-column-loading">
                                                        Loading...
                                                    </div>
                                                    : Array.isArray(tableColumnFilteredWithoutDefault) && tableColumnFilteredWithoutDefault?.length === 0 ? <div className='table-column-loading' onClick={(e) => e.stopPropagation()}>
                                                        No filters are saved
                                                    </div> : <>
                                                        {
                                                            tableColumnFilteredWithoutDefault?.filter((item, index, self) =>
                                                                index === self.findIndex((t) => t.tableColName === item.tableColName)
                                                            ).map((item, index) => {
                                                                return <li key={`${index}`} className='cursor'>
                                                                    <div className={`editCase ${cartBasinToCounty.savedFilterColumnProperties.filterIDColumnProperties === item['id'] ? "open" : "isHide"}`}>
                                                                        <div className="inputFld">
                                                                            <input type={'text'}
                                                                                name={`filterName${index}`}
                                                                                className="form-control"
                                                                                defaultValue={item["tableColName"]}
                                                                                onChange={(e) => {
                                                                                    setFilterName(e.target.value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="action">
                                                                            <a href="void:(0)"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation();
                                                                                    if (filterName.trim() === item["tableColName"]?.trim()) {
                                                                                        dispatch(handleSavedFilterColumnProperties({ filterID: '-1' }))
                                                                                        return;
                                                                                    }

                                                                                    if (filterName.trim() === "") {
                                                                                        toast.error("Please enter the filter name.");
                                                                                    } else {
                                                                                        dispatch(updateColumnPropertiesName({ saved_filter_name: filterName.trim(), id: item['id'] || '-1' }))
                                                                                    }
                                                                                }}
                                                                                className="green"><i className="fa-solid fa-check"></i></a>

                                                                            <a href="void:(0)" onClick={(e) => {
                                                                                e.preventDefault()
                                                                                e.stopPropagation()
                                                                                dispatch(handleSavedFilterColumnProperties({ filterID: '-1' }))
                                                                            }
                                                                            } className="red"><i className="fa-solid fa-xmark"></i></a>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`normalCase ${cartBasinToCounty.savedFilterColumnProperties.filterIDColumnProperties === item['id'] ? "isHide" : "open"}`} onClick={(e) => {
                                                                        dispatch(updateTableColName(item["tableColName"] || ''))
                                                                        dispatch(updateWellsOrRigsTableCol(tableColumnFiltered, wellsAndRigs.tabIndex, item["tableColName"]))
                                                                        dispatch(handleSavedFilterColumnProperties({ openSavedFilter: false }))
                                                                    }}>
                                                                        {item['tableColName']}
                                                                        <div className="action">
                                                                            <a href="void:(0)" onClick={(e) => {
                                                                                e.preventDefault()
                                                                                e.stopPropagation()
                                                                                setFilterName(item["tableColName"] || '')
                                                                                dispatch(handleSavedFilterColumnProperties({ filterID: item['id'] }))
                                                                            }
                                                                            }><i className="fa-solid fa-pen-to-square"></i></a>
                                                                            <a href="void:(0)" onClick={(e) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                dispatch(deleteSavedColumnProperties({ id: item['id'] || '-1' }))
                                                                            }}><i className="fa-solid fa-trash-can"></i></a>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            })
                                                        }
                                                    </>
                                                }

                                            </ul>
                                        </Scrollbars>
                                    </div>
                                </div>


                                <ColFilterOption />

                            </div>
                        </li>
                        <li
                            title={wellsAndRigs.fullScreen ? "Minimize" : "Maximize"}
                        >
                            <a
                                href="void:(0)"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    !wellsAndRigs.showTable && !wellsAndRigs.fullScreen && dispatch(handleShowAndHideTable(!wellsAndRigs.showTable))
                                    wellsAndRigs.csvDownOpt && dispatch(showHideCsvDownOpt());
                                    !wellsAndRigs.fullScreen &&
                                        wellsAndRigs.advFilter &&
                                        dispatch(showHideAdvFilter());
                                    dispatch(showHideFullScreen());
                                }}
                            >
                                <img src="images/search-icon2.svg" alt="" />
                            </a>
                        </li>
                        <li>
                            <div className={`showhidebtn ${wellsAndRigs.fullScrnAnalytics ? "disabled" : ""}`} onClick={() => {
                                !wellsAndRigs.fullScrnAnalytics && dispatch(handleShowAndHideTable(!wellsAndRigs.showTable))
                                if (wellsAndRigs.resizableWidth !== ANALYTICS_DEFAULT_WIDTH && wellsAndRigs.viewAnalytics) {
                                    dispatch(toggleViewAnalytics(true));
                                    dispatch(handleResizableWidth(ANALYTICS_DEFAULT_WIDTH));
                                }

                            }}>
                                <button className="btn"> {wellsAndRigs.showTable ? <img src="images/down-angle.svg" alt="" /> : <img src="images/up-arrow2.svg" alt="" />}</button>
                            </div>
                        </li>
                    </ul >
                </div >
            </div >
            {modal.freeTrialDownAlertMsgModal && <FreeTrialDownAlertMsgModal />
            }
        </>
    );
}

export default Filter;

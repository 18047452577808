import React from "react";
import { actionType, settingsPopupNavMenuItems } from "../../utils/helper";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import Scrollbars from "react-custom-scrollbars";
import { logUserAction } from "../store/actions/auth-actions";
import { toast } from 'react-toastify';

function SideBar() {
    const navigate = useNavigate();

    const first_name = useAppSelector(state => state.auth.user.first_name);
    const last_name = useAppSelector(state => state.auth.user.last_name);
    const company_data = useAppSelector(state => state.auth.user.company_data);
    const signin_as = useAppSelector(state => state.auth.user.signin_as);
    const sync_with_stripe = useAppSelector(state => state.auth.user.company_configs.sync_with_stripe)

    const dispatch = useAppDispatch();
    const { company_name } = company_data || {};

    return (
        <>
            {/* Start Settings Sidebar  */}
            <div className="settingSidebar">
                <div className="back-header">
                    <a
                        href="void:(0)"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(-1);
                        }}
                    >
                        <i className="fa-solid fa-arrow-left" />{" "}
                        <span>Back</span>
                    </a>
                </div>
                <div className="setting-inner">
                    <Scrollbars
                        className='settinginner-scroll'
                        autoHeightMin={0}
                        autoHeight={false}
                        autoHeightMax={"100%"}
                        renderThumbVertical={props => < div {...props} className="thumb-vertical" />}
                        renderTrackVertical={props => < div {...props} className="track-vertical" />}
                    >
                        <div className="setting-inner-scroll">
                            <div className="heading">Settings</div>
                            {company_name && (
                                <div className="companyName">{company_name}</div>
                            )}
                            <div className="sidebarNavs">
                                <ul>
                                    {settingsPopupNavMenuItems.map(
                                        (item, index) => {
                                            return (
                                                item.signin_as === signin_as && (
                                                    <li
                                                        key={index}
                                                        className={!sync_with_stripe ? 'disabled' : ''}
                                                        onClick={() => {
                                                            if (!sync_with_stripe) {
                                                                toast.info("This feature is not available for your account. Please contact support for assistance.");
                                                                return;
                                                            }
                                                            if (item.label === "Settings") {
                                                                dispatch(
                                                                    logUserAction({
                                                                        action_type: actionType["view_settings"],
                                                                        action_log_detail: "visited Settings page.",
                                                                    })
                                                                );
                                                            }
                                                            dispatch(
                                                                logUserAction({
                                                                    action_type: actionType["page_view"],
                                                                    action_log_detail: `page: ${item.pathname}`,
                                                                })
                                                            )
                                                        }}>
                                                        <NavLink
                                                            to={!sync_with_stripe ? '#' : item.pathname}
                                                            className={({ isActive, isPending }) =>
                                                                `${isActive && (sync_with_stripe || item.pathname === "/company-settings") ? "active" : ""} ${!sync_with_stripe ? 'disabled-link-settings' : ''}`
                                                            }
                                                            onClick={(e) => {
                                                                if (!sync_with_stripe) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        >
                                                            {item.label}
                                                        </NavLink>
                                                    </li>
                                                )
                                            );
                                        }
                                    )}
                                </ul>
                            </div>
                            <div className="line">&nbsp;</div>
                            <div className="companyName">{`${first_name} ${last_name}`}</div>
                            <div className="sidebarNavs">
                                <ul>
                                    <li>
                                        <NavLink
                                            to={"/my-settings"}
                                            className={({ isActive, isPending }) =>
                                                isActive ? "active" : ""
                                            }
                                        >
                                            My Settings
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        </>
    );
}

export default SideBar;

import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/redux-hooks";
import { setBillingSetttingsTabIndex } from "../../store/actions/billing-settings-actions";
import { showUpdateCreditCardModal } from "../../store/actions/modal-actions";

interface RedirectToBillingProps {
    show: boolean;
    onClose: Function;
}

function RedirectToBilling({ show, onClose }: RedirectToBillingProps) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    return (
        <Modal
            className="commonModal"
            show={show}
            centered
            contentClassName="redirect-to-billing-modal"
        >
            <div className="d-flex">
                <button
                    type="button"
                    className="btn-close ms-auto"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => onClose((prev: any) => ({ ...prev, showRedirectToBillingModal: false }))}
                />
            </div>

            <Modal.Body>
                <div className="text-center mb-4">
                    Your payment method failed during renewal. Please update it to unlock your data subscription.
                </div>
                <div className="text-center">
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            dispatch(setBillingSetttingsTabIndex(2));
                            dispatch(showUpdateCreditCardModal())
                            navigate("/billing")
                        }}
                    >
                        Take me to billing
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default RedirectToBilling; 
import React from "react";
import { getSingleWellData, setDontUpdateSelectedWellsRigs, setRapidAPIFromInput, clearWellsData } from "../store/actions/wells-rigs-action"
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { toast } from "react-toastify";
import { isInstanceOfWellsAndPermitsObject } from "../common/Map/helper"
import { logUserAction } from "../store/actions/auth-actions";
import { actionType } from "../../utils/helper";
import { FieldValues, UseFormGetValues } from "react-hook-form";
import { OptionType } from "../models/submit-form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { handleClearAllFilter } from "../store/actions/cart-basin-to-county-actions";

const RapidAPIFilter = ({ children, value, resetFilter }: { children: React.ReactNode; value: string, resetFilter: (rapid_api?: string) => void }) => {
    const dispatch = useAppDispatch();

    const handleSearch = async () => {
        if (!value) {
            toast.info("Please select a value to proceed")
            return
        }
        dispatch(handleClearAllFilter(true))
        dispatch(setDontUpdateSelectedWellsRigs(true));
        await dispatch(getSingleWellData(value.trim(), true))

        resetFilter(value.trim())
        dispatch(setRapidAPIFromInput(value.trim()));
        dispatch(clearWellsData());

        dispatch(
            logUserAction({
                action_type: actionType["rapid_api"],
                action_log_detail: `api: ${value.trim()}`,
            })
        );

    }
    const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {

        if (e.code === 'Enter' ||
            e.code === 'NumpadEnter' ||
            e.key === 'Enter' ||
            e.key === 'NumpadEnter' ||
            e.which === 13 ||
            e.keyCode === 13) {
            e.preventDefault()
            handleSearch();
        }
    };

    return (
        <div>

            <div className="left-row left-padding">
                <img src="images/green-lighting.svg" alt="lighting" className="margin-bottom-point-5" />
                <label htmlFor="">
                    Rapid API Filter
                </label>
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip" className="rapid-tooltip">
                        Enter an API to quickly filter, select, and zoom to any well in your subscription. This action will change all other filters to the default state.
                    </Tooltip>}
                ><span>

                        <i>
                            <img src="images/info-tooltip.svg" alt="tooltip" className="margin-bottom-point-5" />
                        </i>
                    </span>
                </OverlayTrigger>

            </div>
            <div className="rapid-API-filter">
                <div onKeyDown={(e: any) => onKeyDownHandler(e)}>
                    {/* <div className={errorText ? "center-row red-outline" : "center-row"}> */}
                    <div className={"center-row"}>

                        {/* <input
                            className="form-control"
                            placeholder="Enter a single API"
                            name="rapid_api"
                            id="rapid_api"
                            onChange={handleRapidAPIChange}
                            defaultValue={wellsAndRigs.rapidAPIFromInput || ''}
                            onFocus={() => dispatch(setRapidAPIOnBlur(true))}
                            onBlur={() => dispatch(setRapidAPIOnBlur(false))}
                        /> */}
                        {children}
                        <button onClick={(e) => {
                            e.preventDefault()
                            handleSearch()
                        }} className="btn btn-primary btn-input-height">
                            <img src="images/search.svg" className="search-input-icon" />
                        </button>
                    </div>
                </div>
                {/* <span className={errorText ? "rapid-API-error-helper-text" : "rapid-API-error-helper-text-hidden"}>{errorText || "hidden"}</span> */}
            </div >
        </div >

    );
};

export default RapidAPIFilter;

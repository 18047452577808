import jwt from "jsonwebtoken";
import store from "../../components/store";
import authSlice from "../../components/store/reducers/auth-slice";
import { axiosUnauth } from "./axios";
export const authActions = authSlice.actions;

// Add this outside the function to track ongoing refresh
let refreshPromise: Promise<string> | null = null;

const getNewAuthToken = async (providedRefreshToken?: string): Promise<string> => {
    // If there's already a refresh in progress, return that promise
    if (refreshPromise) {
        return refreshPromise;
    }

    const dispatch = store.dispatch;
    try {
        // Create new promise and store it
        refreshPromise = (async () => {
            // Clear existing access token
            localStorage.removeItem("access_token");
            sessionStorage.removeItem("access_token");

            // Use provided refresh token or get from storage
            const refreshToken = providedRefreshToken ||
                localStorage.getItem("refresh_token") ||
                sessionStorage.getItem("refresh_token");

            if (!refreshToken) {
                throw new Error('No refresh token available');
            }

            const res = await axiosUnauth.post("/api/token/refresh/", {
                refresh: refreshToken,
            });

            const { access } = res.data;
            dispatch(
                authActions.loadUserToken({
                    access_token: access,
                    refresh_token: refreshToken,
                })
            );

            return access;
        })();

        const result = await refreshPromise;
        return result;
    } catch (err) {
        console.error('Failed to get new auth token:', err);
        throw err;
    } finally {
        // Clear the promise so future calls can try again
        refreshPromise = null;
    }
};

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default getNewAuthToken;

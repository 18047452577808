import React from 'react'
import GlobalModal from '../GlobalModal'
import { Button } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'
import { handleFreeTrialEndModal, showCheckOutModal } from '../../store/actions/modal-actions';
import { useNavigate } from 'react-router-dom';
import { endFreeTrialSubscription, handleUpgradeSubscription } from '../../store/actions/subscription-settings-actions';
import { cartSelectBasin } from '../../../utils/helper';

function FreeTrialEndModal() {
    const modal = useAppSelector(state => state.modal);
    const company_configs = useAppSelector(state => state.auth.user.company_configs);
    const trial_remaining_days = useAppSelector(state => state.auth.user.trial_remaining_days);



    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const trialExpired = modal.freeTrialEndModal && company_configs.trial_expired ? true : false;

    const handleClose = () => {
        sessionStorage.setItem("hideFreeTrialModal", "true")
        dispatch(handleFreeTrialEndModal(false));
    }

    const handleSubscribeNowBtnClick = () => {
        if (trialExpired) {
            dispatch(endFreeTrialSubscription()).then(res => {
                const { status } = res;
                if (status === 200) {
                    dispatch(handleUpgradeSubscription(true))
                    dispatch(handleFreeTrialEndModal(false));
                    navigate(cartSelectBasin);
                }
            })
            return
        }
        dispatch(handleFreeTrialEndModal(false));
        navigate("/subscription");
    }

    if (!modal.freeTrialEndModal) {
        return <></>
    }



    return (
        <GlobalModal
            show={modal.freeTrialEndModal}
            enableFooter={true}
            center={true}
            onHide={trialExpired ? () => { } : handleClose}
            contentClass={"subscribeModal"}
            footerClass={"action-footer justify"}
            footRender={
                <>
                    <Button variant="btn btn-green" onClick={handleSubscribeNowBtnClick}>
                        {trialExpired ? "Edit Subscription" : "Subscribe Now"}
                    </Button>
                </>
            }
        >
            {!trialExpired ?
                <div className="seggestionModalContent">
                    <figure className='subsImage'><img src="images/subs-img.svg" alt="" /></figure>
                    <h3 className='text-center'>Your free trial expires soon</h3>
                    <p className='free-trialtext'>Your Free Trial Ends in {trial_remaining_days} Days</p>

                    <ul>
                        <li>Comprehensive well, production, operator, rig, and permit data</li>
                        <li>Advanced search and map tools</li>
                        <li>Customizable filters and alerts</li>
                        <li>Detailed analytics and forecasting</li>
                    </ul>
                    <div className="ready">Ready to unlock unlimited access?</div>

                </div> :
                <div className="seggestionModalContent editSubscription">
                    <figure className='subsImage'><img src="images/subs-img.svg" alt="" /></figure>
                    <h3 className='text-center'>Your {company_configs.no_of_free_days_allowed}-day trial has ended</h3>
                    <p className='freetrial'>Your free trial has expired, but your journey towards data -driven decisions is just beginning.</p>
                    <h4>Stay Ahead with Full Access:</h4>

                    <ul>
                        <li><strong>Comprehensive Data Coverage:</strong> Access well header data, rig data, and production data across every producing state. </li>
                        <li><strong>Allocated Production Insights:</strong> Exclusive production allocation data for Texas and Louisiana. </li>
                        <li><strong>Advanced Mapping Tools:</strong> Generate Areas of Interest (AOIs) and seamlessly import/export shapefiles.</li>
                        <li><strong>Forecasting and Analytics:</strong> Utilize cutting-edge forecasting and Type Curve generation to predict future trends. </li>
                        <li><strong>Customized Data Exports:</strong> Tailor your data exports to fit your specific needs and criteria. </li>
                        <li><strong>Flexible Subscriptions:</strong> Subscribe to the specific basins or counties you need, ensuring you only pay for the data you use.</li>
                    </ul>


                </div>
            }
        </GlobalModal>
    )
}

export default FreeTrialEndModal
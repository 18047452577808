import { Navigate, Outlet, useLocation, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../components/hooks/redux-hooks";
import React, { useLayoutEffect, useState } from "react";
import Spinner2 from "../components/common/Spinner2";
import AuthErrorBoundary from "./AuthErrorBoundary";
import { alertsPathname, aoiPathname, cartSelectBasin, filesPathname, searchPathname } from "../utils/helper";
import moment from "moment";

const AuthRouteContent = () => {
    const auth = useAppSelector((state) => state.auth);
    const subscriptionSettings = useAppSelector((state) => state.subscriptionSettings);
    const [searchParams] = useSearchParams();
    const uid = searchParams.get('uid');
    const rig_id = searchParams.get('rig_id');
    const [state, setState] = useState<{
        isAuthenticated: boolean | null;
    }>({
        isAuthenticated: null,
    });

    const { isAuthenticated } = state;

    const location = useLocation()
    const go_national_plan = subscriptionSettings.subscriptionData && (subscriptionSettings.subscriptionData['details'] || []).find((item) => item.line_item_type === 3) ? true : false

    useLayoutEffect(() => {
        setState((prev) => ({
            ...prev,
            isAuthenticated: auth.isAuthenticated,
        }));
        // eslint-disable-next-line
    }, [auth]);

    if (isAuthenticated === null) {
        return <Spinner2 />;
    }
    const currentDate = moment().toISOString();
    const isDateCrossed = auth?.user?.renewal_date ? moment(auth?.user?.renewal_date).isBefore(currentDate) : false
    return isAuthenticated !== null && isAuthenticated !== false ? (
        <>
            {(
                (auth?.user?.company_configs?.free_trial_period_enabled && auth?.user.company_configs?.trial_expired && location?.pathname !== cartSelectBasin)
                ||
                // (auth?.user?.subscription_status === "in-active" && !isDateCrossed && [searchPathname, aoiPathname, alertsPathname, filesPathname].includes(location.pathname) && !auth.user.company_configs?.is_trial_never_end))
                (auth?.user?.subscription_status === "in-active" && !isDateCrossed && [searchPathname, aoiPathname, alertsPathname, filesPathname].includes(location.pathname)))
                ?
                <Navigate to={cartSelectBasin} />
                :
                //this condition is once a subscription is purchased so user is not allow to navigate to cart page directly to update subscription to purchase new subscription. He should navigate through update subscription button only
                auth?.user?.company_configs?.free_trial_period_enabled && !auth?.user.company_configs?.trial_expired && location?.pathname === cartSelectBasin && subscriptionSettings.subscriptionData !== null && !subscriptionSettings.upgrade_subscription
                    ?
                    < Navigate to={"/search"} />
                    :
                    go_national_plan && location.pathname === cartSelectBasin ?
                        <Navigate to={"/search"} />
                        :
                        <Outlet />}
        </>
    ) : (
        location.pathname === alertsPathname ? <Navigate to={`/sign-in?alerts=${true}`} /> : (uid || rig_id) ? <Navigate to={`/sign-in?${uid ? "uid" : "rig_id"}=${uid ? uid : rig_id}`} /> : <Navigate to="/" />
    );
};

const AuthRoute = () => {
    const handleAuthError = () => {
        // Clear any auth state/tokens
        localStorage.clear(); // Or use your logout function
        // Force a reload to clear any cached state
        window.location.href = '/';
    };

    return (
        <AuthErrorBoundary onAuthError={handleAuthError}>
            <AuthRouteContent />
        </AuthErrorBoundary>
    );
};

export default AuthRoute;

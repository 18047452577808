import React, { useEffect, useState } from "react";
import GlobalModal from "../../common/GlobalModal";
import InputComponent from "../../common/InputComponent";
import CardNumberInput from "../../common/CardNumberInput";
import CardExpiryInput from "../../common/CardExpiryInput";
import CardCvcInput from "../../common/CardCvcInput";
import LocationSearchInput from "../../common/LocationSearchInput";
import { setFormValues } from "../../../utils/helper";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {
    BankAccountUpdateModalSubmitForm,
    CreditCardUpdateModalSubmitForm,
    UpdateBillingAddressModalSubmitForm,
    UpdateLinkedAccountsReturnType
} from "../../models/submit-form";
import {
    BankAccountUpdateModalValidationSchema,
    CreditCardUpdateModalValidationSchema,
    UpdateBillingAddressModalValidationSchema,
} from "../../../Helper/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { geocodeByAddress } from "react-places-autocomplete";
import {
    CardNumberElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import {
    hideSiteLoader,
    showSiteLoader,
    hideUpdateCreditCardModal,
} from "../../store/actions/modal-actions";
import { toast } from "react-toastify";
import {
    clearPaymentMethodsData,
    updateBillingDetail,
    updateCardDetails,
} from "../../store/actions/billing-settings-actions";
import {
    updateLinkedAccounts,
} from "../../store/actions/cart-select-basin-county-actions";
import { fetchClientSecret, fetchPaymentHistoryData } from "../../store/actions/billing-settings-actions";
import {
    logUserAction,
    loadUser
} from "../../store/actions/auth-actions";
import {
    actionType
} from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { clearSubscriptionData } from "../../store/actions/subscription-settings-actions";
import { fetchSubscriptionData } from "../../store/actions/subscription-settings-actions";
import {
    clearProductionData,
    clearRigsData,
    clearWellsData,
    handlePageChange,
} from "../../store/actions/wells-rigs-action";
import {
    handleSubscribingToStripeLoading
} from "../../store/actions/cart-select-basin-county-actions";
import { setRecentUnpaidInvoiceId } from "../../store/actions/subscription-settings-actions";
import { confirmMandateCreditCard } from "../../store/actions/cart-select-basin-county-actions";
import { Tooltip } from "react-tooltip";
import * as Sentry from "@sentry/react";

function UpdateCreditCardModal({
    show,
    handleClose,
    updateBillingAddress,
}: {
    show: boolean;
    handleClose: () => void;
    updateBillingAddress: boolean;
}) {
    const [stateData, setStateData] = useState({
        address: "",
        creditCardTabActive: true
    });
    const { address, creditCardTabActive } = stateData;
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const {
        billingSettings: { paymentMethodsData },
    } = useAppSelector((state) => state);
    const first_name = useAppSelector(state => state.auth.user.first_name);
    const last_name = useAppSelector(state => state.auth.user.last_name);
    const email = useAppSelector(state => state.auth.user.email);
    const recentUnpaidInvoiceId = useAppSelector((state) => state.subscriptionSettings.recent_unpaid_invoice_id)
    const subscriptionData = useAppSelector((state) => state.subscriptionSettings.subscriptionData)
    const access_token = useAppSelector(state => state.auth.user.access_token)

    const {
        register,
        handleSubmit,
        reset,
        control,
        setValue,
        trigger,
        formState: { errors },
    } = useForm<
        CreditCardUpdateModalSubmitForm | UpdateBillingAddressModalSubmitForm | BankAccountUpdateModalSubmitForm
    >({
        ...(!updateBillingAddress && {
            resolver: yupResolver(creditCardTabActive ? CreditCardUpdateModalValidationSchema : BankAccountUpdateModalValidationSchema),
        }),
        ...(updateBillingAddress && {
            resolver: yupResolver(UpdateBillingAddressModalValidationSchema),
        }),
        mode: "all",
    });

    const addressOnChange = (address: string) => {
        setStateData((prev) => ({ ...prev, address }));
    };

    const addressOnSelect = (address: string) => {
        let tempValue = address.split(",");
        let tempFirstAddress = [];
        for (let i = 1; i <= tempValue.length; i++) {
            switch (i) {
                case 1:
                    break;
                case 2:
                    setValue("state", tempValue[tempValue.length - i]?.trim());
                    trigger("state")
                    break;
                case 3:
                    setValue("city", tempValue[tempValue.length - i]?.trim());
                    trigger("city")
                    break;
                default:
                    tempFirstAddress.push(
                        tempValue[tempValue.length - i].trim()
                    );
            }
        }
        let formatedAddress = tempFirstAddress.reverse().join(",");
        setValue("first_address", formatedAddress);
        setStateData((prev) => ({
            ...prev,
            address: formatedAddress,
        }));
        geocodeByAddress(address)
            .then((results) => {
                const { address_components } = results[0];
                address_components.forEach((item) => {
                    if (item.types.includes("postal_code")) {
                        setValue("zip_code", item.long_name?.trim());
                        trigger("zip_code")
                    }
                });
            })
            .catch((error) => console.error("Error", error));
    };

    const stripe = useStripe();
    const elements = useElements();

    const respondToUpdatedInvoice = async (updateLinkedAccountsResponse: UpdateLinkedAccountsReturnType) => {
        dispatch(showSiteLoader())
        const { hasInvoice, invoiceError, invoiceId } = updateLinkedAccountsResponse || {}
        if (hasInvoice && !invoiceError) {
            let action_log_detail = "";
            dispatch(
                logUserAction({
                    action_type: actionType['new_subscription'],
                    action_log_detail
                })
            );

            toast.success(
                "Success! Check your email for the invoice."
            );
            //get the updated data of user after subscription   
            await dispatch(loadUser())

            // dispatch(hideSiteLoader());
            //Clear the existing table data to allow for a fresh reload. This will ensure that the new data, corresponding to the updated subscription, is properly loaded.
            // only when we have some subscription data
            if (subscriptionData !== null) {
                dispatch(clearSubscriptionData())
                dispatch(handlePageChange(1));
                dispatch(clearProductionData());
                dispatch(clearWellsData());
                dispatch(clearRigsData());
            }
            dispatch(fetchPaymentHistoryData({ page: 1, recent: 1 }))
            handleClose();
            navigate("/search");
            // get the new subscription data
            dispatch(fetchSubscriptionData())
        } else if (hasInvoice && invoiceError) {
            toast.error(invoiceError)
            dispatch(hideSiteLoader());
            dispatch(handleSubscribingToStripeLoading(false))
        }
        // clear the recent unpaid invoice id
        dispatch(setRecentUnpaidInvoiceId(''))
    }
    const checkUpdatedCreditCard = async (client_secret: string) => {
        const confirmMandateCreditCardResponse = await dispatch(confirmMandateCreditCard(client_secret, stripe))
        if (confirmMandateCreditCardResponse) {
            respondToUpdatedInvoice(confirmMandateCreditCardResponse)
        }
    }


    const updateAndLinkBankAccount = async () => {
        const response = await dispatch(fetchClientSecret())
        const { client_secret, status, msg } = response || {};
        if (status === 200 && client_secret) {
            setStateData((prev) => ({ ...prev, clientSecret: client_secret }))
            // Confirm payment with bank account details
            stripe?.collectBankAccountForSetup({
                clientSecret: client_secret,
                params: {
                    payment_method_type: 'us_bank_account',
                    payment_method_data: {
                        billing_details: {
                            name: `${first_name} ${last_name}`,
                            email: email,
                        },
                    },
                },
                expand: ['payment_method'],
            }).then(async (result: any) => {
                if (result.error) {
                    console.error(result.error.message);
                    toast.error('Your bank accounts could not be linked.');
                } else if (result.status != "requires_payment_method") {
                    /**When the customer updates their bank account, we need to check if they were redirected here
                     * because they had an unpaid invoice and their subscription is expired. 
                     * If so, we need to immediately pay the invoice and update their subscription.
                     */
                    const updateLinkedAccountsResponse = await dispatch(updateLinkedAccounts(client_secret, stripe))
                    if (recentUnpaidInvoiceId && updateLinkedAccountsResponse) {
                        respondToUpdatedInvoice(updateLinkedAccountsResponse)
                    }
                    dispatch(hideUpdateCreditCardModal())
                    return
                }
            }).catch((err) => {
                toast.error('Your bank accounts could not be linked');
                Sentry.captureException(err);
                dispatch(hideSiteLoader());
                return
            })
        } else {
            toast.error('Your ACH Details could not be confirmed.');
            dispatch(hideSiteLoader());
            return
        }
    }

    const onSubmit = async (
        data:
            | CreditCardUpdateModalSubmitForm
            | UpdateBillingAddressModalSubmitForm | BankAccountUpdateModalSubmitForm
    ) => {

        dispatch(showSiteLoader());
        if (!updateBillingAddress && "full_name" in data && creditCardTabActive) {
            const res = await stripe?.createPaymentMethod({
                type: "card",
                card: elements?.getElement(CardNumberElement)!,
                billing_details: {
                    name: data.full_name,
                    email: paymentMethodsData?.billing_email,
                    address: {
                        line1: data.first_address,
                        line2: data.second_address,
                        city: data.city,
                        state: data.state,
                        postal_code: data.zip_code,
                        country: "US",
                    },
                },
            });

            if (res) {
                const { paymentMethod, error } = res;

                if (error) {
                    const { message } = error;
                    toast.error(message);
                    dispatch(hideSiteLoader());
                    return;
                }

                if (paymentMethod) {
                    if (access_token) {
                        dispatch(
                            updateCardDetails({
                                last4: `${paymentMethod?.card?.last4}`,
                                payment_id: `${paymentMethod?.id}`,
                                exp_month: `${paymentMethod?.card?.exp_month}`,
                                exp_year: `${paymentMethod?.card?.exp_year}`,
                                first_address: data.first_address,
                                city: data.city,
                                state: data.state,
                                zip_code: data.zip_code,
                                country: "US",
                                name_on_card: data.full_name,
                                brand: `${paymentMethod?.card?.brand}`,
                                type: "Credit Card"
                            })
                        ).then(async (result) => {
                            if (result) {
                                const { status, msg } = result;
                                if (status === 200 && recentUnpaidInvoiceId) {
                                    const response = await dispatch(fetchClientSecret({ invoice_id: recentUnpaidInvoiceId, payment_method_id: paymentMethod?.id }))
                                    const { client_secret, status, msg } = response || {};
                                    checkUpdatedCreditCard(client_secret)

                                } else if (status === 200) {
                                    toast.success(msg);
                                    dispatch(hideSiteLoader());
                                    dispatch(clearPaymentMethodsData());
                                    handleClose();
                                } else {
                                    toast.error(msg);
                                    dispatch(hideSiteLoader());
                                }
                            }
                        });
                    }
                }
            }
        } else {
            if (access_token) {
                dispatch(
                    updateBillingDetail({
                        ...data,
                        country: "US",
                        billing_email: paymentMethodsData?.billing_email,
                    } as UpdateBillingAddressModalSubmitForm)
                ).then((result) => {
                    if (result) {
                        const { status, msg } = result;
                        if (status === 200) {
                            toast.success(msg);
                            dispatch(hideSiteLoader());
                            dispatch(clearPaymentMethodsData());
                            handleClose();
                        } else {
                            dispatch(hideSiteLoader());
                            toast.error(msg);
                        }
                    }
                }).catch((err) => {
                    dispatch(hideSiteLoader());
                })
            }
        }
    };

    useEffect(() => {
        reset();
        if (paymentMethodsData) {
            setFormValues(
                {
                    first_address: paymentMethodsData.first_address,
                    second_address: paymentMethodsData.second_address,
                    city: paymentMethodsData.city,
                    state: paymentMethodsData.state,
                    zip_code: paymentMethodsData.zip_code,
                    country: paymentMethodsData.country,
                    ...(updateBillingAddress && {
                        billing_email: paymentMethodsData.billing_email,
                    }),
                },
                setValue
            );
            setStateData((prev) => ({
                ...prev,
                address: paymentMethodsData.first_address,
            }));
        }
        // eslint-disable-next-line
    }, [paymentMethodsData]);

    return (
        <GlobalModal
            show={show}
            onHide={handleClose}
            titleClass="modal-title"
            headerClass="modal-header"
            contentClass="commonModal creditcardModal"
            title={
                <>
                    <i className="fa-regular fa-credit-card"></i> Update your
                    payment information
                </>
            }
        >
            <form
                className="form-block"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                autoCapitalize="off"
            >
                <div className="formScroll scrollSection">
                    {!updateBillingAddress && (
                        <>
                            {/* <h3>Credit Card</h3> */}
                            <div className="paymentType">
                                <ul>
                                    <li className={`${!creditCardTabActive ? "active" : ""}`}>
                                        <a href="javascript:void(0)" onClick={(e) => {
                                            e.preventDefault();
                                            creditCardTabActive && setStateData((prev) => ({ ...prev, creditCardTabActive: !prev.creditCardTabActive }))
                                        }}>
                                            <i className="fa-solid fa-building-columns"></i>
                                            <span>ACH / US bank account</span>
                                        </a>
                                    </li>
                                    <li className={`${creditCardTabActive ? "active" : ""}`}>
                                        <a href="javascript:void(0)" onClick={(e) => {
                                            e.preventDefault();
                                            !creditCardTabActive && setStateData((prev) => ({ ...prev, creditCardTabActive: !prev.creditCardTabActive }))
                                        }}>
                                            <i className="fa-regular fa-credit-card"></i>{" "}
                                            <span>Credit card</span>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                            {creditCardTabActive ? (<div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <InputComponent
                                            label="Full Name"
                                            name="full_name"
                                            placeholder={!creditCardTabActive ? "Account holder name" : "Enter name on card"
                                            } register={register}
                                            errorMsg={
                                                "full_name" in errors
                                                    ? errors?.full_name?.message
                                                    : ""
                                            }
                                        />
                                    </div>
                                </div>
                            </div>) : ''}

                            {creditCardTabActive ?
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label>Card Number</label>
                                            <div className="cardnumber">
                                                <CardNumberInput
                                                    control={control}
                                                    name={"cardNumber"}
                                                    errorMsg={`${"cardNumber" in errors
                                                        ? errors?.cardNumber
                                                            ?.message
                                                        : ""
                                                        }`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group mb-4">
                                            <label>Expiration</label>
                                            <CardExpiryInput
                                                control={control}
                                                name="cardExpiry"
                                                errorMsg={`${"cardExpiry" in errors
                                                    ? errors?.cardExpiry?.message
                                                    : ""
                                                    }`}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group mb-4">
                                            <label>CVC</label>
                                            <CardCvcInput
                                                name="cardCvc"
                                                errorMsg={`${"cardCvc" in errors
                                                    ? errors?.cardCvc?.message
                                                    : ""
                                                    }`}
                                                control={control}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                <>
                                </>
                            }






                        </>
                    )}
                    <div className="paymentType">
                        {!creditCardTabActive ?
                            <button
                                type="button"
                                className="btn btn-green"
                                onClick={() => updateAndLinkBankAccount()}
                            >
                                Link your bank account
                            </button>
                            : ''}</div>

                    <h3 className="normalLineHeight">Billing Address</h3>
                    <div className="row mb-4">
                        {updateBillingAddress && (
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <InputComponent
                                        label="Billing Email"
                                        placeholder="Enter billing email"
                                        name="billing_email"
                                        register={register}
                                        errorMsg={
                                            "billing_email" in errors
                                                ? errors?.billing_email?.message
                                                : ""
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        <div className="col-md-12">
                            <div className="form-group mb-4">
                                <label>Address Line 1</label>
                                <LocationSearchInput
                                    name={`first_address`}
                                    valueLoc={address}
                                    errorMsg={errors?.first_address?.message}
                                    onChangeLoc={addressOnChange}
                                    onSelect={addressOnSelect}
                                    control={control}
                                    debounce={500}
                                    searchOptions={{
                                        componentRestrictions: {
                                            country: ["usa", "us"],
                                        },
                                        types: [],
                                    }}
                                    placeholder="Enter address line 1"
                                    shouldFetchSuggestions={address?.length >= 3}
                                    title={address}
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group mb-4">
                                <InputComponent
                                    label="Address Line 2"
                                    placeholder="Enter address line 2"
                                    name="second_address"
                                    register={register}
                                    errorMsg={errors.second_address?.message}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group mb-4">
                                <InputComponent
                                    label="City"
                                    placeholder="City"
                                    name="city"
                                    register={register}
                                    errorMsg={errors?.city?.message}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group mb-4">
                                <InputComponent
                                    label="State"
                                    placeholder="State"
                                    name="state"
                                    register={register}
                                    errorMsg={errors?.state?.message}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group mb-4">
                                <InputComponent
                                    label="Zip"
                                    placeholder="Zip Code"
                                    name="zip_code"
                                    register={register}
                                    errorMsg={errors?.zip_code?.message}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <button type="submit" className="btn btn-green">
                    {!updateBillingAddress && creditCardTabActive
                        ? "Update payment information"
                        : "Update billing information"}
                </button>
                <Tooltip id="ACH-tooltip-1" />
            </form>
        </GlobalModal>
    );
}

export default UpdateCreditCardModal;

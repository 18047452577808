// Create a debug logger that can be easily enabled/disabled
const DEBUG = import.meta.env.VITE_DATA_PLATFORM_WEB_URL !== 'https://data.energydomain.com/';

export const logTokenState = (message: string, token?: string, error?: any) => {
    if (!DEBUG) return;

    const tokenPreview = token ? `${token.slice(0, 10)}...${token.slice(-10)}` : 'no token';
    console.group('Token State Update');
    console.log(`Time: ${new Date().toISOString()}`);
    console.log(`Event: ${message}`);
    console.log(`Token Preview: ${tokenPreview}`);
    if (error) console.error('Error:', error);
    console.groupEnd();
};

export default logTokenState;
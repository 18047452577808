import authSlice from "../reducers/auth-slice";
import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import axiosAuth, { axiosUnauth } from "../../../utils/axios/axios";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";

import {
    ActivateNewUserFormData,
    ActivateUserReturnType,
    ForgotPasswordReturnType,
    ForgotPasswordSubmitForm,
    LoginUserReturnType,
    RegisterUserReturnType,
    ReturnMsgAndStatus,
    SignInSubmitForm,
    SignUpFormData,
    SubmitFormUserActionLog,
    UpdatePasswordReturnType,
    UpdatePasswordSubmitFormData,
    verifyResetPasswordTokenReturnType,
} from "../../models/submit-form";
import setAuthToken from "../../../utils/axios/setAuthToken";
import { toast } from "react-toastify";
import {
    handleFreeTrialEndModal,
    hideSiteLoader,
    showSiteLoader,
} from "./modal-actions";
import {
    actionType,
    config,
    errToast,
    freeTrialRemainingDays,
    productionDomain,
} from "../../../utils/helper";
import { AxiosError } from "axios";
import { AuthModel, CombinedItem } from "../../models/redux-models";
import CryptoJS from "crypto-js";
import { handleYearlyAndMonthlyTab } from "./cart-select-basin-county-actions";
import { handleDownloadCol } from "./wells-rigs-action";
import TokenManager from "../../../utils/axios/tokenManager";

export const authActions = authSlice.actions;
export const fetchToken = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        try {
            const tokenManager = TokenManager.getInstance();
            const validToken = await tokenManager.getValidToken();

            if (validToken) {
                // If we have a valid token, load the user
                await dispatch(loadUser());

                // Update Redux store with current tokens
                const { access_token, refresh_token } =
                    tokenManager.getTokenData() || {};
                dispatch(
                    authActions.loadUserToken({
                        access_token: access_token || "",
                        refresh_token: refresh_token || "",
                    })
                );
            } else {
                // No valid token available, logout
                dispatch(authActions.loadToken());
                dispatch(authActions.logout());
            }
        } catch (error) {
            // Token refresh failed or other error
            dispatch(authActions.loadToken());
            dispatch(authActions.logout());
        }
    };
};

export const registerUser = (
    formData: SignUpFormData
): ThunkAction<
    Promise<RegisterUserReturnType>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        try {
            const res = await axiosUnauth.post(
                "/api-user/new-user",
                formData,
                config
            );
            const { msg, status } = res.data;
            if (status === 200) {
                toast.success(
                    `Your account has been created, but isn't quite ready yet! Please check your email inbox for instructions on how to confirm and activate your account`
                );
            } else toast.error(msg);
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

// Confirm -- activate user

export const activateNewUser = (
    formData: ActivateNewUserFormData
): ThunkAction<
    Promise<ActivateUserReturnType>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        try {
            const res = await axiosUnauth.put(
                "/api-user/new-user",
                formData,
                config
            );
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

export const login = (
    formData: SignInSubmitForm
): ThunkAction<Promise<LoginUserReturnType>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());

        const rememberMe = formData?.rememberMe;
        const device_session_id = localStorage.getItem("device_session_id");

        if (device_session_id) {
            axiosAuth.defaults.headers.common["X-DEVICE-SESSION-ID"] =
                Number(device_session_id);
            axiosUnauth.defaults.headers.common["X-DEVICE-SESSION-ID"] =
                Number(device_session_id);
        }

        try {
            delete formData["rememberMe"];
            const res = await axiosUnauth.post(
                "/api-user/login",
                {
                    ...formData,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
                config
            );
            const {
                data: { data, status },
            } = res;

            if (status === 200) {
                if (
                    "pass_change_required" in data &&
                    data.pass_change_required
                ) {
                    toast.success(`Please update your password.`);
                    dispatch(
                        handlePassChangeReq({
                            pass_change_required: true,
                            base_user_id: btoa(data.user_id),
                        })
                    );
                } else if (data) {
                    // Initialize TokenManager with the new tokens
                    const tokenManager = TokenManager.getInstance();
                    tokenManager.setTokens({
                        access_token: data.access_token,
                        refresh_token: data.refresh_token,
                        expiresAt: Date.now() + 5 * 60 * 1000, // 1 hour expiry
                    });

                    dispatch(authActions.loadUser(data));

                    if (data.device_session_id) {
                        localStorage.setItem(
                            "device_session_id",
                            data.device_session_id
                        );
                    }

                    // Handle remember me functionality
                    if (rememberMe) {
                        // Store encrypted credentials in cookies
                        Cookies.remove("email");
                        Cookies.remove("password");

                        Cookies.set(
                            "email",
                            CryptoJS.AES.encrypt(
                                `${formData.email}`,
                                `${import.meta.env.VITE_ENCRYPT_DECRYPT_KEY}`
                            ).toString(),
                            { expires: 90 }
                        );

                        Cookies.set(
                            "password",
                            CryptoJS.AES.encrypt(
                                `${formData.password}`,
                                `${import.meta.env.VITE_ENCRYPT_DECRYPT_KEY}`
                            ).toString(),
                            { expires: 90 }
                        );
                    }
                }
            }

            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//forgot password
export const forgotPassword = (
    formData: ForgotPasswordSubmitForm
): ThunkAction<
    Promise<ForgotPasswordReturnType>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        try {
            const res = await axiosUnauth.post(
                "/api-user/forgot-password",
                { user_email: formData?.email },
                config
            );
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//verifyResetPassword Link
export const verifyResetPasswordToken = (
    tkn: string
): ThunkAction<
    Promise<verifyResetPasswordTokenReturnType>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        try {
            const res = await axiosUnauth.get(
                `/api-user/forgot-password?token=${tkn}`,
                config
            );
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//forgot password
export const updatePassword = (
    formData: UpdatePasswordSubmitFormData
): ThunkAction<
    Promise<UpdatePasswordReturnType>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        try {
            const res = await axiosUnauth.put(
                "/api-user/update-password",
                formData,
                config
            );
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

export const logout = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(showSiteLoader());

        try {
            // Log the user action
            await dispatch(
                logUserAction({
                    action_type: actionType["logout"],
                    action_log_detail: JSON.stringify({
                        session_id: state.auth.user.device_session_id,
                    }),
                })
            );

            // Call the logout endpoint
            const res = await axiosAuth.get("/api-user/logout", config);
            console.log("res in logout", res);
            // Clear tokens first to prevent any refresh attempts
            const tokenManager = TokenManager.getInstance();
            tokenManager.clearTokens(true);

            // Clear additional session data
            sessionStorage.removeItem("doNotShowShapeFileInfoModal");
            sessionStorage.removeItem("oilMaxMsg");
            sessionStorage.removeItem("gasMaxMsg");
            sessionStorage.removeItem("hideFreeTrialModal");

            // Update Redux state
            dispatch(authActions.logout());
            if (window.location.hostname === productionDomain) {
                Sentry.setUser(null);
            }
            if ("cioanalytics" in window && (window as any).cioanalytics) {
                (window as any).cioanalytics.reset();
            }
        } catch (err) {
            errToast(err as AxiosError);
            Sentry.captureException(err);
        } finally {
            dispatch(hideSiteLoader());
            setTimeout(() => {
                window.location.reload();
            }, 10);
        }
    };
};

export const loadUser = (): ThunkAction<
    Promise<void>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        try {
            const tokenManager = TokenManager.getInstance();
            const validToken = await tokenManager.getValidToken();

            if (!validToken) {
                throw new Error("No valid token available");
            }

            setAuthToken(validToken);
            const res = await axiosAuth.get("/api-user/exchange-user", config);
            const { status, data, msg } = res.data;

            if (status === 200 && data) {
                if ("cioanalytics" in window && (window as any).cioanalytics) {
                    (window as any).cioanalytics.identify({
                        id: data.email,
                    });
                }
                if (window.location.hostname === productionDomain) {
                    Sentry.setUser({
                        user_id: data.user_id,
                        ...(data?.company_data?.company_id && {
                            company_id: data.company_data.company_id,
                        }),
                    });
                }
                const { access_token, refresh_token } =
                    tokenManager.getTokenData() || {};
                dispatch(
                    authActions.loadUser({
                        ...data,
                        access_token,
                        refresh_token,
                        ...(localStorage.getItem("device_session_id") && {
                            device_session_id:
                                localStorage.getItem("device_session_id"),
                        }),
                    })
                );

                // Handle free trial logic
                if (
                    data.company_configs.free_trial_period_enabled &&
                    !data.company_configs.trial_expired
                ) {
                    dispatch(handleYearlyAndMonthlyTab(false));
                }

                // Handle free trial modal
                if (
                    ((data.company_configs.free_trial_period_enabled &&
                        data.company_configs.trial_expired) ||
                        (data.trial_remaining_days &&
                            data.trial_remaining_days <=
                            freeTrialRemainingDays &&
                            data.trial_remaining_days > 0)) &&
                    !data.company_configs.is_trial_never_end &&
                    !sessionStorage.getItem("hideFreeTrialModal")
                ) {
                    dispatch(handleFreeTrialEndModal(true));
                }
            } else {
                toast.error(msg);
            }
        } catch (err) {
            errToast(err as AxiosError);
            throw err; // Propagate error to be caught by error boundary
        }
    };
};

//handle password change required
export const handlePassChangeReq = (val: {
    pass_change_required: AuthModel["pass_change_required"];
    base_user_id: AuthModel["base_user_id"];
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(authActions.handlePassChangeReq(val));
    };
};

export const handleSystemRelatedDetails = (
    val: AuthModel["deviceInfo"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(authActions.handleSystemRelatedDetails(val));
    };
};

// log user action
export const logUserAction = (
    formData: SubmitFormUserActionLog
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const {
            auth: { deviceInfo },
            wellsAndRigs: { downloadCol, download_without_api_call },
        } = getState();

        const tempData = {
            action_type: formData.action_type,
            remote_addr: deviceInfo.ipAddress,
            device_type: deviceInfo.deviceType,
            user_agent: deviceInfo.userAgent,
            device_os: deviceInfo.operatingSystem,
            action_log_detail: formData.action_log_detail || "-",
        };

        if (formData.action_type === actionType["click"]) {
            // Clear the session storage after logging the total clicks
            sessionStorage.removeItem("clickCount");
        }

        axiosAuth
            .post("/api-user/log-user-action", tempData, config)
            .then((res) => {
                const { status, msg } = res.data;
                if (status === 200) {
                } else {
                    status !== 200 && toast.error(msg);
                }
            })
            .catch((err) => {
                errToast(err as AxiosError);
                dispatch(hideSiteLoader());
            })
            .finally(() => {
                download_without_api_call &&
                    dispatch(
                        handleDownloadCol({
                            downloadCol: 0,
                            allCol: 0,
                            excel_format: false,
                            download_without_api_call: false,
                        })
                    );
            });
    };
};
//sign up terms and condition modal
export const fetchTermsAndCondition = (): ThunkAction<
    Promise<
        ReturnMsgAndStatus & {
            data: {
                id: number;

                name: string;

                description: string;

                status: boolean;
            };
        }
    >,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        try {
            const res = await axiosUnauth.get(
                "/api-user/get-service-agreement",
                config
            );
            const { data } = res;
            if (data) {
                const { status, msg } = data;
                if (status === 200) {
                } else {
                    toast.error(msg);
                }
            }
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};
// terms accepted
export const handleTermsAccepted = (
    val: AuthModel["termsAccepted"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(authActions.handleTermsAccepted(val));
    };
};

//handleTermsAndCondition
export const handleTermsAndCondition = (
    val: AuthModel["termsAndCondition"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(authActions.handleTermsAndCondition(val));
    };
};

//handle onboarding Data
export const handleOnboardingData = (
    val: AuthModel["onboardingData"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(authActions.handleOnboardingData(val));
    };
};

//fetch onboarding questions
export const fetchOnBoardingQuestion = (): ThunkAction<
    Promise<ReturnMsgAndStatus>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        try {
            const res = await axiosAuth.get(
                "/api-user/survey-question",
                config
            );
            const { status, msg, data } = res.data;
            if (status === 200) {
                dispatch(handleOnboardingData(data));
            } else {
                toast.error(msg);
            }
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//handle onboarding step
export const handleOnboardingStep = (
    val: AuthModel["onboardingStep"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(authActions.handleOnboardingStep(val));
    };
};

//submit survey answer
export const submitSurveyAnswer = (formData: {
    user_answer: CombinedItem[];
}): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        try {
            const res = await axiosAuth.post(
                `/api-user/survey-question`,
                formData
            );
            const { status, msg } = res.data;
            if (status !== 200) toast.error(msg);
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//handle privacy policy
export const handlePrivacyPolicy = (
    val: AuthModel["privacyPolicy"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(authActions.handlePrivacyPolicy(val));
    };
};

//fetch privacy policy
export const fetchPrivacyPolicy = (): ThunkAction<
    Promise<ReturnMsgAndStatus>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        try {
            const res = await axiosUnauth.get("/api-setting/privacy", config);
            const { status, msg, data } = res.data;
            if (status === 200) {
                dispatch(handlePrivacyPolicy(data.page_content));
            } else {
                toast.error(msg);
            }
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//handle alert unread count
export const handleUnReadAlertCount = (
    val: AuthModel["user"]["unread_alert"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(authActions.handleUnReadAlertCount(val));
    };
};

import React, { useEffect, useState } from "react";
import TabContent from "./TabContent";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { fetchApiFileList } from "../store/actions/files-actions";


function ApiTabcontent() {
    const dispatch = useAppDispatch();
    const apiList = useAppSelector(state => state.files.apiList);
    const apiPage = useAppSelector(state => state.files.apiPage);
    const apiPageSize = useAppSelector(state => state.files.apiPageSize);
    const apiTotalRecord = useAppSelector(state => state.files.apiTotalRecord);
    const apiListLoading = useAppSelector(state => state.files.apiListLoading);
    const filesTabIndex = useAppSelector(state => state.files.filesTabIndex);

    const [state, setState] = useState({
        hasMore: false,
    })
    const { hasMore } = state



    const fetchData = () => {
        dispatch(fetchApiFileList(apiPage + 1));
    }
    useEffect(() => {
        if (apiListLoading && filesTabIndex === 1) {
            dispatch(fetchApiFileList(apiPage));
        }
        // eslint-disable-next-line
    }, [apiListLoading, filesTabIndex])

    useEffect(() => {
        if (apiTotalRecord && apiList) {
            let pages =
                Math.floor(apiTotalRecord / apiPageSize) +
                (apiTotalRecord % apiPageSize > 0 ? 1 : 0);
            setState((prev) => ({
                ...prev,
                pages,
                hasMore: apiPage < pages ? true : false,
            }));
        }
        // eslint-disable-next-line
    }, [apiTotalRecord, apiPage, apiList]);

    return (
        <div
            className="tab-pane fade"
            id="api"
            role="tabpanel"
            aria-labelledby="api-tab"
        >
            <TabContent data={apiList} id={"apiFileContent"} hasMore={hasMore} fetchData={fetchData} type={"csv"}filesTabIndex={filesTabIndex} />
        </div>
    );
}

export default ApiTabcontent;

import React, { lazy, Suspense, useEffect, useLayoutEffect, useState, useRef } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./components/hooks/redux-hooks";
import { fetchToken, handleSystemRelatedDetails } from "./components/store/actions/auth-actions";
import ScrollToTop from "./Routes/ScrollToTop";
import RoutesCom from "./Routes/Routes";
import SiteLoader from "./components/common/SiteLoader";
import Spinner2 from "./components/common/Spinner2";
import FreeTrialEndModal from "./components/common/Modal/FreeTrialEndModal";
import useDeviceInfo from "./components/hooks/useSystemRelateInfo";
// import { requestForToken } from './firebase';
// import Notification from "./components/common/notification/Notification";
import { detectIncognito } from "detectincognitojs";
import { axiosAuth, axiosUnauth } from "./utils/axios/axios";
import { HelmetProvider } from 'react-helmet-async';

const OnboardingModal = lazy(() =>
    import("./components/common/Modal/OnboardingModal"));

// Add this before your App component
const originalConsoleError = console.error;
console.error = (...args) => {
    if (args && args.length > 0 && args[0]?.includes('UNSAFE_componentWillMount')) {
        return;
    }
    originalConsoleError.apply(console, args);
};

// Extend the Window interface to include our callback
declare global {
    interface Window {
        initGoogleMaps: () => void;
    }
}

const App: React.FC = () => {
    const dispatch = useAppDispatch();
    const [tokenVerified, setTokenVerified] = useState<null | boolean>(null);
    const ipAddress = useAppSelector((state) => state.auth.deviceInfo.ipAddress);
    //if we already called the api for ip address then ignore use of hook.
    const deviceInfo = useDeviceInfo(ipAddress === null ? true : false);
    const [mapsLoaded, setMapsLoaded] = useState(false);
    const loadedGoogleMaps = useRef(false);

    useLayoutEffect(() => {
        detectIncognito().then((result) => {
            axiosAuth.defaults.headers.common["X-Private-Mode"] =
                `${result.isPrivate}`
            axiosUnauth.defaults.headers.common["X-Private-Mode"] =
                `${result.isPrivate}`
        });
    }, [])

    useLayoutEffect(() => {
        Promise.all([dispatch(fetchToken())]).then((res) => {
            setTokenVerified(true);
        });
    }, [dispatch]);
    // Commenting the firebase token comment for now
    // useEffect(() => {
    //     requestForToken();
    // }, [])
    const loadGoogleMapsScript = () => {
        if (loadedGoogleMaps.current || window.google?.maps?.places) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            // Check if script is already loading
            const existingScript = document.querySelector(`script[src*="maps.googleapis.com"]`);
            if (existingScript) {
                loadedGoogleMaps.current = true;
                resolve(true);
                return;
            }

            // Create a callback function
            window.initGoogleMaps = () => {
                if (window.google && window.google.maps && window.google.maps.places) {
                    loadedGoogleMaps.current = true;
                    setMapsLoaded(true);
                    resolve(true);
                } else {
                    reject(new Error('Google Maps Places library failed to load'));
                }
            };

            // Create and append the script
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_MAP_API_KEY}&libraries=places&callback=initGoogleMaps&loading=async`;
            script.async = true;
            script.defer = true;

            script.onerror = () => {
                reject(new Error('Failed to load Google Maps script'));
            };

            document.head.appendChild(script);
        });
    };
    useEffect(() => {
        if (!mapsLoaded) {
            loadGoogleMapsScript()
        }
    }, [mapsLoaded]);
    useEffect(() => {
        if (deviceInfo.ipAddress && !ipAddress) {
            dispatch(handleSystemRelatedDetails(deviceInfo))
        }
    }, [deviceInfo, dispatch, ipAddress])

    if (tokenVerified === null) {
        return <Spinner2 />;
    }

    return (
        <HelmetProvider>
            <React.Fragment>
                <Router>
                    <ScrollToTop>
                        <RoutesCom />
                        <SiteLoader />
                        {/* <Notification /> */}
                        <FreeTrialEndModal />
                        <Suspense fallback={null}>
                            {OnboardingModal ? <OnboardingModal /> : null}
                        </Suspense>
                    </ScrollToTop>
                </Router>
            </React.Fragment>
        </HelmetProvider>
    );
}

export default App;

import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";

interface CacheItem<T> {
  data: T;
  timestamp: number;
  expiresAt: number;
}

interface AttributeQueryCacheKey {
  type: 'attribute';
  clause: string;
}

interface SpatialQueryCacheKey {
  type: 'spatial';
  x: number;
  y: number;
  spatialReference: {
    wkid: number;
  };
  distance?: number;
}

type QueryCacheKey = AttributeQueryCacheKey | SpatialQueryCacheKey;

class QueryCache {
  private cache: Map<string, CacheItem<any>> = new Map();
  private readonly CACHE_EXPIRATION_MS = 5 * 60 * 1000; // 5 minutes
  private readonly SPATIAL_TOLERANCE = 0.000001; // Small tolerance for spatial queries

  /**
   * Generate a cache key for the given query
   */
  private generateCacheKey(query: any): string {
    let cacheKey: QueryCacheKey;

    // Attribute-based query
    if (query.where && query.where.length > 0) {
      cacheKey = {
        type: 'attribute',
        clause: query.where
      };
    } 
    // Spatial query with a point geometry
    else if (query.geometry && query.geometry.type === 'point') {
      cacheKey = {
        type: 'spatial',
        x: query.geometry.x,
        y: query.geometry.y,
        spatialReference: query.geometry.spatialReference,
        distance: query.distance
      };
    } else {
      // For other types of queries, return a unique string based on the query
      return JSON.stringify(query);
    }

    return JSON.stringify(cacheKey);
  }

  /**
   * Get data from cache if it exists and is not expired
   */
  get<T>(query: any): T | null {
    const key = this.generateCacheKey(query);
    const item = this.cache.get(key);
    
    if (!item) {
      return null;
    }

    const now = Date.now();
    if (now > item.expiresAt) {
      this.cache.delete(key);
      return null;
    }

    return item.data as T;
  }

  /**
   * Store data in cache with expiration time
   */
  set<T>(query: any, data: T): void {
    const key = this.generateCacheKey(query);
    const now = Date.now();
    
    this.cache.set(key, {
      data,
      timestamp: now,
      expiresAt: now + this.CACHE_EXPIRATION_MS
    });
    
  }

  /**
   * Check if there's a similar spatial query in the cache
   * This allows for small variations in click positions
   */
  getSimilarSpatialQuery<T>(query: any): T | null {
    // Only for spatial queries
    if (!query.geometry || query.geometry.type !== 'point') {
      return null;
    }

    const queryPoint = query.geometry;
    
    for (const [key, item] of this.cache.entries()) {
      try {
        const cacheKey = JSON.parse(key) as QueryCacheKey;
        
        // Skip if not a spatial query or if expired
        if (cacheKey.type !== 'spatial' || Date.now() > item.expiresAt) {
          continue;
        }
        
        // Check if spatial references match
        if (cacheKey.spatialReference.wkid !== queryPoint.spatialReference.wkid) {
          continue;
        }
        
        // Check if the distance parameter matches
        if (cacheKey.distance !== query.distance) {
          continue;
        }
        
        // Check if the points are close enough
        const dx = cacheKey.x - queryPoint.x;
        const dy = cacheKey.y - queryPoint.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        
        if (distance < this.SPATIAL_TOLERANCE) {
          return item.data as T;
        }
      } catch (e) {
        // Skip any errors in parsing or comparison
        continue;
      }
    }
    
    return null;
  }

  /**
   * Clear all cached queries
   */
  clear(): void {
    const count = this.cache.size;
    this.cache.clear();
  }

  /**
   * Clear expired cache entries
   */
  clearExpired(): void {
    const now = Date.now();
    let expiredCount = 0;
    
    for (const [key, item] of this.cache.entries()) {
      if (now > item.expiresAt) {
        this.cache.delete(key);
        expiredCount++;
      }
    }
    
  }

  /**
   * Get cache statistics
   */
  getStats(): { size: number, cacheKeys: string[] } {
    return {
      size: this.cache.size,
      cacheKeys: Array.from(this.cache.keys())
    };
  }

  /**
   * Clear a specific cache entry by key
   */
  clearKey(cacheKey: string): void {
    if (this.cache.has(cacheKey)) {
      this.cache.delete(cacheKey);
    }
  }

  /**
   * Add a simple debugging utility to log cache information
   * Only works when the browser developer console is open
   */
  logCacheInfo(): void {
    if (typeof window !== 'undefined' && window.console) {
      const stats = this.getStats();
      
      console.group('Map Query Cache Information');
      
      // Count by type
      let attributeQueries = 0;
      let spatialQueries = 0;
      let otherQueries = 0;
      
      stats.cacheKeys.forEach(key => {
        try {
          const cacheKey = JSON.parse(key);
          if (cacheKey.type === 'attribute') {
            attributeQueries++;
          } else if (cacheKey.type === 'spatial') {
            spatialQueries++;
          } else {
            otherQueries++;
          }
        } catch (e) {
          otherQueries++;
        }
      });
      
      stats.cacheKeys.slice(0, 5).forEach(key => {
        const item = this.cache.get(key);
        const age = item ? Math.round((Date.now() - item.timestamp) / 1000) : 0;
        const expiresIn = item ? Math.round((item.expiresAt - Date.now()) / 1000) : 0;
      });
      
      console.groupEnd();
    }
  }
}

// Create a singleton instance of the cache
const queryCache = new QueryCache();

export default queryCache; 